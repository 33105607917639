import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';

import { useAppsBootstrapHooks } from '@/shared/api/apps-bootstrap/apps-bootstrap.hooks.ts';
import { loginFormSchema } from '@/shared/schemas/validate-schemas';
import { cognitoUserPoolService } from '@/shared/services/cognito-user-pool.service';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { userStorageService } from '@/shared/services/user-storage.service';
import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';
import { QUERY_KEY } from '@/shared/utils/constants';

import { LoginInputsProps } from '@/modules/Auth/types/types';
import { StatusEnum } from '@/shared/types/global/enums.ts';

export const useLoginUser = () => {
  const navigate = useNavigate();

  const localStorageUsername = userStorageService.getUserName();
  const localStorageAccountName = userStorageService.getAccountName();
  const termsOfUse = userStorageService.getTermsOfUse();

  const remembered = !!localStorageUsername && !!localStorageAccountName;

  const form = useForm<LoginInputsProps>({
    defaultValues: {
      accountName: localStorageAccountName ?? '',
      password: '',
      rememberMe: remembered,
      site: '',
      username: localStorageUsername ?? '',
    },
    mode: 'onChange',
    resolver: zodResolver(loginFormSchema),
  });

  const { getUserPool } = useAppsBootstrapHooks.getUserPool();

  const setStatus = useGlobalStore(store => store.setStatus);
  const setSitesList = useUIStore(store => store.setSitesList);
  const setSite = useUIStore(store => store.setSite);

  const setCurrentCognitoUser = useGlobalStore(store => store.setCurrentCognitoUser);

  const clearSiteData = () => {
    setSitesList([]);
    setSite('');
  };

  const { mutateAsync } = useMutation({
    mutationFn: async (formData: LoginInputsProps) => {
      setStatus(StatusEnum.LOADING);

      const accountName = encodeURI(formData.accountName.trim());

      await getUserPool({ accountName });

      // if (result.status === 'error') {
      //   setStatus(StatusEnum.IDLE);
      //   notifyService.error('No Cognito info found for account');
      //   return null;
      // }

      const cognitoUser = cognitoUserPoolService.initCognitoUser({
        password: formData.password,
        rememberMe: formData.rememberMe,
        username: formData.username,
      });
      setCurrentCognitoUser(cognitoUser);

      return await cognitoUserPoolService.login(
        {
          accountName,
          password: formData.password,
          rememberMe: formData.rememberMe,
          username: formData.username,
        },
        setStatus,
        navigate,
        termsOfUse,
        cognitoUser,
      );
    },

    mutationKey: [QUERY_KEY.LOGIN, navigate, termsOfUse],
    onError: () => {
      setStatus(StatusEnum.IDLE);
      notifyService.error('No Cognito info found for account');
    },

    retry: false,
  });

  return {
    ...form,
    clearSiteData,
    mutateAsync,
    navigate,
    remembered,
  };
};
