import { FC, useEffect } from 'react';

import { subDays } from 'date-fns';

import { useActionsHook } from '@/shared/api/actions/actions.hook';

const archivedStartTimestamp = subDays(new Date(), 7).getTime();
const archivedEndTimestamp = new Date().getTime();

export const ActionsHooks: FC = () => {
  useActionsHook.getAlerts();

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = useActionsHook.getArchivedAlerts(
    archivedStartTimestamp,
    archivedEndTimestamp,
  );

  // useActionsHook.getActionsMetrics();

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) void fetchNextPage();
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return null;
};
