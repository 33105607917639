import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CognitoUser } from 'amazon-cognito-identity-js';

import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { cognitoUserPoolService } from '@/shared/services/cognito-user-pool.service';
import { userStorageService } from '@/shared/services/user-storage.service';
import { useGlobalStore } from '@/shared/store/global';

import { StatusEnum } from '@/shared/types/global/enums.ts';

export const useMFALogin = () => {
  const navigate = useNavigate();
  const [showVerificationForm, setShowVerificationForm] = useState(false);

  const termsOfUse = userStorageService.getTermsOfUse();
  const { user } = useFetchUserData();

  const setStatus = useGlobalStore(store => store.setStatus);

  const handleVerifyMFACode = (
    code: string,
    accountName: string,
    rememberMe: boolean,
    cognitoUser: CognitoUser,
    isMFAEnabled: boolean,
  ) => {
    setStatus(StatusEnum.LOADING);
    cognitoUserPoolService.verifyMFATotpCode(
      code,
      accountName,
      user?.username ?? '',
      rememberMe,
      setStatus,
      navigate,
      termsOfUse,
      cognitoUser,
      isMFAEnabled,
    );
  };

  return {
    handleVerifyMFACode,
    navigate,
    setShowVerificationForm,
    showVerificationForm,
  };
};
