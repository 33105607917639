import { useEffect, useState } from 'react';

import { MapViewType } from '@/modules/Home/types/MapModel.types';

type Props = {
  mapView: MapViewType;
  isGridVisible: boolean;
};

type Position = [x: number, y: number, z: number];

export const useMapGrid = ({ isGridVisible, mapView }: Props) => {
  const [currentPosition, setCurrentPosition] = useState<Position>([0, -1, 0]);
  const [currentRotation, setCurrentRotation] = useState<Position>([Math.PI / 3.2, 0, 0]);

  useEffect(() => {
    switch (mapView) {
      case 'full':
        setCurrentPosition([0, -1, 0]);
        setCurrentRotation([Math.PI / 3.2, 0, 0]);
        break;

      case 'side':
        setCurrentPosition([0, -1.6, 0]);
        setCurrentRotation([Math.PI / 16, 0, 0]);
        break;

      case 'top':
        setCurrentPosition([0, 0, 0.5]);
        setCurrentRotation([Math.PI / 2.024, 0, 0]);
        break;
    }
  }, [mapView]);

  const gridConfig = {
    cellColor: '#CBD5E1',
    cellSize: 0.9,
    cellThickness: 1,
    fadeDistance: 70,
    fadeStrength: 1,
    followCamera: false,
    infiniteGrid: true,
    position: currentPosition,
    rotation: currentRotation,
    sectionColor: '#CBD5E1',
    sectionSize: 3.6,
    sectionThickness: 1.5,
    visible: isGridVisible,
  };

  return {
    gridConfig,
  };
};
