export type MapFilterType = {
  searchKey: string;
  firm: string[];
  group: string[];
  tag: string[];
  workerRole: string[];
  equipmentType: string[];
  equipmentSubType: string[];
  equipmentReservedBy: string[];
  zoneType: string[];
  zoneFloor: string[];
  zoneItem: string[];
  assetItem: string[];
  resName: string[];
};

export enum MapFilterPropEnum {
  ASSET_ITEM = 'assetItem',
  EQUIPMENT_RESERVED_BY = 'equipmentReservedBy',
  EQUIPMENT_SUB_TYPE = 'equipmentSubType',
  EQUIPMENT_TYPE = 'equipmentType',
  FIRM = 'firm',
  GROUP = 'group',
  RES_NAME = 'resName',
  TAG = 'tag',
  WORKER_ROLE = 'workerRole',
  ZONE_FLOOR = 'zoneFloor',
  ZONE_ITEM = 'zoneItem',
  ZONE_TYPE = 'zoneType',
}
