import { useRef } from 'react';

import { Confirm } from '@/shared/components/ui/Modal/Confirm';
import { ConfirmAssetDelete } from '@/shared/components/ui/Modal/ConfirmAssetDelete';
import { ConfirmReservation } from '@/shared/components/ui/Modal/ConfirmReservation';
import {
  CreateEquipment,
  DeleteEquipment,
  EditEquipment,
} from '@/shared/components/ui/Modal/Equipment';
import { CreateReservation } from '@/shared/components/ui/Modal/Equipment/extensions/CreateReservationForm.tsx';
import { CreateGroup, DeleteGroup, EditGroup } from '@/shared/components/ui/Modal/Groups';
import { MapFilter } from '@/shared/components/ui/Modal/MapFilter';
import {
  EditSubcontractorsFirm,
  EditSubcontractorsWorker,
  InviteSubcontractor,
} from '@/shared/components/ui/Modal/Subcontractor';
import { Terms } from '@/shared/components/ui/Modal/Terms';

import { PopupTypeEnum } from '@/shared/types/global/enums.ts';

export const useRenderModalContent = (type: PopupTypeEnum) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const renderModalContent = () => {
    switch (type) {
      case PopupTypeEnum.CONFIRM_DELETE:
        return <Confirm {...{ type: 'delete' }} />;
      case PopupTypeEnum.CONFIRM_DELETE_FIRM:
        return <ConfirmAssetDelete type="firmEdit" />;
      case PopupTypeEnum.CONFIRM_DELETE_WORKER:
        return <ConfirmAssetDelete type="workerEdit" />;
      case PopupTypeEnum.CONFIRM_SAVE:
        return <Confirm type="save" />;
      case PopupTypeEnum.EQUIPMENT_CREATE:
        return <CreateEquipment />;
      case PopupTypeEnum.EQUIPMENT_DELETE:
        return <DeleteEquipment />;
      case PopupTypeEnum.EQUIPMENT_EDIT:
        return <EditEquipment />;
      case PopupTypeEnum.EQUIPMENT_RESERVE:
        return <CreateReservation />;
      case PopupTypeEnum.GROUPS_CREATE:
        return <CreateGroup />;
      case PopupTypeEnum.GROUPS_DELETE:
        return <DeleteGroup />;
      case PopupTypeEnum.GROUPS_EDIT:
        return <EditGroup />;
      case PopupTypeEnum.MAP_FILTER:
        return <MapFilter />;
      case PopupTypeEnum.RESERVATIONS_CONFIRM_APPROVE:
        return <ConfirmReservation type="approve" />;
      case PopupTypeEnum.RESERVATIONS_CONFIRM_DELETE:
        return <ConfirmReservation {...{ type: 'delete' }} />;
      case PopupTypeEnum.RESERVATIONS_CONFIRM_REJECT:
        return <ConfirmReservation type="reject" />;
      case PopupTypeEnum.RESERVATIONS_CONFIRM_SUBMIT:
        return <ConfirmReservation type="submit" />;
      case PopupTypeEnum.SUBCONTRACTORS_FIRM_EDIT:
        return <EditSubcontractorsFirm />;
      case PopupTypeEnum.SUBCONTRACTORS_INVITE:
        return <InviteSubcontractor />;
      case PopupTypeEnum.SUBCONTRACTORS_WORKER_EDIT:
        return <EditSubcontractorsWorker />;
      case PopupTypeEnum.TERMS_OF_USE:
        return <Terms ref={scrollRef} />;
    }
  };

  return { renderModalContent };
};
