import { useModalStore } from '@/shared/store/modal';

import { PopupTypeEnum } from '@/shared/types/global/enums.ts';

export const useToggleModal = () => {
  const setIsOpen = useModalStore(state => state.setIsOpen);

  const handler = <T>(window: PopupTypeEnum, content?: T) => {
    setIsOpen(window, content);
  };

  return { handler };
};
