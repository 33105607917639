import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

import { translate } from '@/i18n';
import { useAppsBootstrapHooks } from '@/shared/api/apps-bootstrap/apps-bootstrap.hooks.ts';
import { Fade } from '@/shared/components/animation/Fade';
import { Loader } from '@/shared/components/ui/Loader';
import { useTermsOfUse } from '@/shared/hooks/observer/useTermsOfUse.ts';
import { cognitoUserPoolService } from '@/shared/services/cognito-user-pool.service.ts';
import { useUIStore } from '@/shared/store/ui';
import { URL_PARAMS } from '@/shared/utils/constants.ts';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

interface MobileTokenData {
  accessToken: string;
  accountName: string;
  alertAssetResName: string;
  alertResName: string;
  idToken: string;
  refreshToken: string;
  siteName: string;
  siteResName: string;
  username: string;
}

export const MobileAuth: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenString = searchParams.get(URL_PARAMS.TOKEN);

  const setSite = useUIStore(store => store.setSite);
  const setMapFilter = useUIStore(store => store.setMapFilter);
  const { handleUpdateTermsOfUse } = useTermsOfUse();
  const { getUserPool } = useAppsBootstrapHooks.getUserPool();

  const authenticate = async (tokenString: string) => {
    const tokenDataString = atob(tokenString);
    const tokenData = JSON.parse(tokenDataString) as MobileTokenData;

    await getUserPool({ accountName: tokenData.accountName });
    setMapFilter({ resName: [tokenData.alertAssetResName] });
    handleUpdateTermsOfUse('done');
    setSite(tokenData.siteResName);

    const session = new CognitoUserSession({
      AccessToken: new CognitoAccessToken({ AccessToken: tokenData.accessToken }),
      IdToken: new CognitoIdToken({ IdToken: tokenData.idToken }),
      RefreshToken: new CognitoRefreshToken({ RefreshToken: tokenData.refreshToken }),
    });

    cognitoUserPoolService.success(
      session,
      tokenData.accountName,
      tokenData.username,
      false,
      () => {},
      () => {
        navigate(RoutesEnum.HOME, { replace: true });
      },
      'done',
    );
  };

  useEffect(() => {
    if (tokenString) {
      void authenticate(tokenString);
    } else navigate(RoutesEnum.LOGIN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fade className="fullHeightWithoutFooter">
      <div className="grid h-full w-full place-items-center">
        <Loader appearance="section" loaderText={translate('confirm.loading')} />
      </div>
    </Fade>
  );
};
