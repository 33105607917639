import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAssetHooks } from '@/shared/api/assets/assets.hooks.ts';
import { useSetAssetsGeofenceOccupants } from '@/shared/hooks/assets/useSetAssetsGeofenceOccupants';
import { useGlobalStore } from '@/shared/store/global';
import { useModalStore } from '@/shared/store/modal';
import { URL_PARAMS } from '@/shared/utils/constants.ts';
import { getUserRole } from '@/shared/utils/roles';

import { ExtraPropertyKeys } from '@/shared/components/ui/Modal/Subcontractor/types.ts';
import { RolesEnum } from '@/shared/types/global/enums';
import { FirmsDatasource, WorkersDatasource } from '@/shared/types/users/subcontractors.types';

const ROLES = Object.values(RolesEnum).filter(role => {
  if (getUserRole() === RolesEnum.KOTO) {
    return role === RolesEnum.KOTO;
  }
  return true;
});

export const usePeopleAsset = () => {
  const [searchParameters, setSearchParams] = useSearchParams();

  const [forcePhoneEdit, setForcePhoneEdit] = useState(false);

  const activeFirmId =
    searchParameters.get(URL_PARAMS.SUBCONTRACTOR_ID) ??
    searchParameters.get(URL_PARAMS.FIRM_EDIT_ID);
  const activeWorkerId =
    searchParameters.get(URL_PARAMS.WORKER_ID) ?? searchParameters.get(URL_PARAMS.WORKER_EDIT_ID);

  const workers = useGlobalStore(state => state.workers);
  const { isDeleting, isSubmitting, setIsClose, setIsDeleting, setIsSubmitting } = useModalStore();
  const store = useGlobalStore(state => state);

  const categoryTree = store.assetsCategories.find(cat => cat.categoryTreeName.includes('Worker'));
  const categoryTreeResrName = categoryTree?.categoryTreeResName;
  const categoryPathResrName = categoryTree?.categoryPathList[0]?.categoryPathResName;

  const { data: geofenceOccupants } = useSetAssetsGeofenceOccupants();

  const initialTrackersMapData = store.lastLocations;

  const siteTagsList = store.assetsTags
    ?.filter(
      assetTag => assetTag.asset?.assetResName === activeWorkerId || !assetTag.asset?.assetResName,
    )
    .map(assetTag => {
      return {
        id: assetTag.tag.tagResName,
        name: assetTag.tag.tagName,
      };
    })
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLocaleLowerCase()));

  // last seen at least 30 mins ago
  const trackersOnSite = new Set(
    initialTrackersMapData
      .filter(
        tracker =>
          (Date.now() - tracker.location.timeOfLocation.epochTime * 1000) / (1000 * 60) <= 30,
      )
      .map(tracker => tracker.tagResName),
  );
  const workersAssetId = new Set(workers.map(worker => worker.assetResName));

  const allWorkersOnSite = new Set(
    store.assetsTags
      .filter(
        assetTag =>
          workersAssetId.has(assetTag.asset?.assetResName ?? '') &&
          trackersOnSite.has(assetTag.tag.tagResName),
      )
      .map(assetTag => assetTag.asset?.assetResName),
  );

  const people: WorkersDatasource[] = workers
    .filter(
      asset =>
        asset.extraProperties?.find(p => p.key === ExtraPropertyKeys.firm)?.value === activeFirmId,
    )
    .map(d => {
      const currentZone = geofenceOccupants?.find(
        occupant => d.assetResName == occupant.asset.assetResName,
      );
      return {
        allowedSites: d.extraProperties?.find(p => p.key === ExtraPropertyKeys.allowedSites)
          ?.value as string[],
        attached: store.assetsTags?.filter(
          assetTag => assetTag.asset?.assetResName === d.assetResName,
        ),
        currentZone,
        email: d.extraProperties?.find(p => p.key === ExtraPropertyKeys.email)?.value as string,
        family_name: d.extraProperties?.find(p => p.key === ExtraPropertyKeys.familyName)
          ?.value as string,
        given_name: d.extraProperties?.find(p => p.key === ExtraPropertyKeys.givenName)
          ?.value as string,
        id: d.assetResName,
        orgResName: d.extraProperties?.find(p => p.key === ExtraPropertyKeys.firm)?.value as string,
        phone: d.extraProperties?.find(p => p.key === ExtraPropertyKeys.phone)?.value as string,
        role: (d.extraProperties?.find(p => p.key === ExtraPropertyKeys.role)?.value as string)
          ?.replace('-', ' ')
          .toUpperCase(),
        status: d.assetStatus + '',
        zpsAccess: d.extraProperties
          ?.find(p => p.key === ExtraPropertyKeys.zpsAccess)
          ?.value.toString(),
      };
    })
    .sort((a, b) => a.id.localeCompare(b.id));

  const firms: FirmsDatasource[] = store.assetsOrganizations
    .map(firm => {
      const totalWorkers = workers.filter(
        asset =>
          asset.extraProperties?.find(p => p.key === ExtraPropertyKeys.firm)?.value ===
          firm.orgResName,
      );

      const firmWorkerIds = new Set(totalWorkers.map(asset => asset.assetResName));

      const zones = geofenceOccupants?.filter(occupant =>
        firmWorkerIds.has(occupant.asset.assetResName),
      );

      const onSite = totalWorkers.filter(asset => allWorkersOnSite.has(asset.assetResName)).length;

      return {
        color: '#10B981',
        email: firm.extraProperties?.find(p => p.key === ExtraPropertyKeys.email)?.value as string,
        family_name: firm.extraProperties?.find(p => p.key === ExtraPropertyKeys.familyName)
          ?.value as string,
        firm_name: firm.orgName,
        given_name: firm.extraProperties?.find(p => p.key === ExtraPropertyKeys.givenName)
          ?.value as string,
        id: firm.orgResName,
        on_site: onSite,
        phone: firm.extraProperties?.find(p => p.key === ExtraPropertyKeys.phone)?.value as string,
        status: firm.status,
        total_workers: totalWorkers.length ?? 0,
        zones: zones ?? [],
      };
    })
    .sort((a, b) => a.id.localeCompare(b.id));

  const firmList = store.assetsOrganizations
    .map(firm => {
      const maxLength = 15;
      return {
        id: `${firm.orgResName}`,
        name: `${firm.orgName.slice(0, maxLength)}${firm.orgName.length >= maxLength ? '...' : ''}`,
        orgResName: firm.orgResName,
      };
    })
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  const rolesList = ROLES.map(role => ({
    id: role,
    name: role.replace('-', ' ').toUpperCase(),
  }));

  const clearDeletedWorkerId = () => {
    setSearchParams(searchParams => {
      searchParams.delete(URL_PARAMS.WORKER_EDIT_ID);
      searchParams.delete(URL_PARAMS.WORKER_ID);
      return searchParams;
    });
  };

  const clearDeletedFirmId = () => {
    setSearchParams(searchParams => {
      searchParams.delete(URL_PARAMS.FIRM_EDIT_ID);
      searchParams.delete(URL_PARAMS.SUBCONTRACTOR_ID);
      return searchParams;
    });
  };

  const activeFirm = store.assetsOrganizations.find(firm => firm.orgResName === activeFirmId);
  const activeFirmAlt = firms.find(firm => firm.id === activeFirmId);

  const activeWorker = people.find(worker => worker.id === activeWorkerId);

  const userResrName = store.zpsUsers.find(user => user.emailAddress == activeWorker?.email)
    ?.userResName;

  const activeUserResrName = store.zpsUsers.find(
    user => user.status === 'active' && user.emailAddress == activeWorker?.email,
  )?.userResName;

  const isEquipmentsLoading = false;
  const { isLoading: isWorkersLoading } = useAssetHooks.getAllWorkers();

  const disableEmailAndPasswordEdit = !!store?.zpsUsers.find(
    user => user.emailAddress === activeWorker?.email,
  );

  const firmHasWorkers = !!firms.find(firm => firm.id === activeFirmId)?.total_workers;
  const workerHasTags = !!Number(
    people.find(worker => worker.id === activeWorkerId)?.attached?.length,
  );
  const workerisZpsUser = activeUserResrName;

  return {
    ...store,
    activeFirm,
    activeFirmAlt,
    activeFirmId,
    activeUserResrName,
    activeWorker,
    activeWorkerId,
    categoryPathResrName,
    categoryTreeResrName,
    clearDeletedFirmId,
    clearDeletedWorkerId,
    disableEmailAndPasswordEdit,
    firmHasWorkers,
    firmList,
    firms,
    forcePhoneEdit,
    isAssetsLoading: isEquipmentsLoading || isWorkersLoading,
    isDeleting,
    isSubmitting,
    people,
    rolesList,
    setForcePhoneEdit,
    setIsClose,
    setIsDeleting,
    setIsSubmitting,
    siteTagsList,
    userResrName,
    workerHasTags,
    workerisZpsUser,
  };
};
