import { roundToNearestMinutes } from 'date-fns';

import { translate } from '@/i18n';
import { getDateForCalendar, getDateFromTimestamp } from '@/shared/utils/date-fns';

import { SelectDataEnum } from '@/modules/Home/components/common/MapPageHeader/MapPageHeader.types';
import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';

type completedTitleProps = {
  start: Date;
  end: Date;
  setCurrentCalendarTitle: (title: string) => void;
  localization: LocaleEnum;
};

export const DATE_FORMAT = 'HH:mm';

export const timeCaption = translate('calendar.time') as unknown as string;

export const setSpecificHours = (date: Date, hours: number) => {
  return new Date(date.setHours(date.getHours() + hours));
};

export const initial = {
  currentSelectValue: SelectDataEnum.LAST_HOUR,
  endDate: setSpecificHours(
    roundToNearestMinutes(new Date(), {
      nearestTo: 5,
    }),
    0,
  ),
  startDate: setSpecificHours(
    roundToNearestMinutes(new Date(), {
      nearestTo: 5,
    }),
    -1,
  ),
};

export const transformedDate = (initialDate: null | string) => {
  return initialDate ? getDateFromTimestamp(initialDate) : new Date();
};

export const isToday = (startDate: Date) => {
  return new Date(startDate?.toDateString()) >= new Date(new Date().toDateString());
};

export const isValid = (startDate: Date, endDate: Date) => {
  return startDate >= endDate;
};

export const completedTitle = ({
  end,
  localization,
  setCurrentCalendarTitle,
  start,
}: completedTitleProps) => {
  setCurrentCalendarTitle(
    `${getDateForCalendar(start, localization)} - ${getDateForCalendar(end, localization)}`,
  );
};
