import { useQuery } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

export const useSetTags = () => {
  const site = useUIStore(store => store.site);
  const updateAssetsTags = useGlobalStore(store => store.updateAssetsTags);

  const { user } = useFetchUserData();

  const {
    data: tags,
    isError,
    isLoading,
    refetch,
  } = useQuery({
    enabled: !!user?.accountResourceName && !!site,

    queryFn: async () => {
      const data = await AssetsAPINamespace.getAllTags(user?.accountResourceName as string, site);
      updateAssetsTags(data.results);

      return data.results;
    },

    queryKey: [QUERY_KEY.ASSETS_TAGS, user?.accountResourceName, site],
  });

  return { isError, isLoading, refetch, tags };
};
