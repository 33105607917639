import { FC } from 'react';

import { translate } from '@/i18n';
import { useUpdateReservations } from '@/modules/Reservations/hooks/useUpdateReservations';
import { useWorkflowEngine } from '@/modules/Reservations/hooks/useWorkflowEngine';
import { Button } from '@/shared/components/buttons/Button';
import { Typography } from '@/shared/components/ui/Typography';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { useModalHandlerTypes } from '@/shared/hooks/global/useModalHandlerTypes.ts';
import { useModalStore } from '@/shared/store/modal';
import { useUIStore } from '@/shared/store/ui';
import { classnames } from '@/shared/utils/classnames';
import { RESERVATIONS } from '@/shared/utils/constants';

import { ReservationConfirmProps } from '../Modal.types';

import { updateReservationEvent } from '@/shared/api/reservations/reservations.types.ts';
import {
  ReservationAssetType,
  ReservationUpdateParamsWithEvent,
} from '@/shared/types/global/reservations.types.ts';

export const ConfirmReservation: FC<ReservationConfirmProps> = ({ type }) => {
  const { setIsClose } = useModalHandlerTypes();

  const content = useModalStore(state => state.payload);

  const { reservation } = content as { reservation: ReservationAssetType };

  const handleCancel = () => {
    setIsClose();
  };
  const getEventType = (type: string) => {
    if (type == 'approve') {
      return reservation.workflowContext.currentStateName == RESERVATIONS.requestedSub
        ? RESERVATIONS.eventApprovedSub
        : RESERVATIONS.eventApprovedMgr;
    }
    if (type === 'reject') {
      return reservation.workflowContext.currentStateName == RESERVATIONS.requestedSub
        ? RESERVATIONS.eventRejectSub
        : RESERVATIONS.eventRejectMgr;
    }
    return RESERVATIONS.eventPendingSub;
  };
  const { isSubmitting, setIsSubmitting } = useModalStore(({ isSubmitting, setIsSubmitting }) => ({
    isSubmitting,
    setIsSubmitting,
  }));
  const { user } = useFetchUserData();
  const site = useUIStore(store => store.site);
  const workFlowCall = useWorkflowEngine();
  const updateCall = useUpdateReservations();
  const handleSuccess = async (type: string) => {
    setIsSubmitting(true);
    try {
      const stateUpdate: updateReservationEvent = {
        accountResName: user?.accountResourceName ?? '',
        reservationResName: reservation.reservationResName,
        siteResName: site,
        triggerEventType: getEventType(type),
        userResName: user?.userResourceName,
      };
      await workFlowCall.mutateAsync(stateUpdate);
      handleCancel();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      const fData: ReservationUpdateParamsWithEvent = {
        accountResName: user?.accountResourceName ?? '',
        assetResName: reservation.assetResName,
        event: false,
        reservationPeriod: {
          endTime: { epochTime: Number(reservation.reservationPeriod.endTime.epochTime) },
          startTime: {
            epochTime: Number(reservation.reservationPeriod.startTime.epochTime),
          },
        },
        reservationResName: reservation.reservationResName,
        siteResName: site,
        status: 'inactive',
        user: reservation.user,
        workflowResName: reservation.workflowContext.workflowResName,
      };
      await updateCall.mutateAsync(fData);
      setIsClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getByTitle = (type: string) => {
    if (type === 'delete') {
      return translate('reservation.deleteTitle');
    }
    if (type === 'approve') {
      return translate('reservation.approveTitle');
    }
    if (type === 'submit') {
      return translate('reservation.submitTitle');
    }
    return translate('reservation.rejectTitle');
  };
  const getByMessage = (type: string) => {
    if (type === 'delete') {
      return translate('reservation.deleteMessage');
    }
    if (type === 'approve') {
      return translate('reservation.approveMessage');
    }
    if (type === 'submit') {
      return translate('reservation.submitMessage');
    }
    return translate('reservation.rejectMessage');
  };

  return (
    <div className="grid items-center justify-center gap-y-5 p-10">
      <div className="grid gap-y-2.5 text-center">
        <Typography as="strong" className="text-2xl font-bold tracking-[0.12px] text-slate-800">
          {getByTitle(type)}
        </Typography>
        <Typography className="text-base font-medium tracking-[0.05px] text-slate-500">
          {getByMessage(type)}
        </Typography>
      </div>

      <div className="flex justify-center gap-x-4">
        <Button
          className="w-full !border-slate-200 text-slate-800 hover:!bg-slate-100"
          onClick={handleCancel}
          variant="outline"
        >
          {translate('settings.cancel')}
        </Button>

        <Button
          className={classnames(
            'w-full',
            type === 'delete' || type === 'reject'
              ? 'bg-red-600 text-white hover:bg-red-700 focus:bg-red-700'
              : 'bg-blue-600 text-white hover:bg-blue-700 focus:bg-blue-700',
          )}
          isLoading={isSubmitting}
          onClick={() =>
            type === 'delete'
              ? handleDelete()
              : type === 'reject'
              ? handleSuccess('reject')
              : type === 'submit'
              ? handleSuccess('submit')
              : handleSuccess('approve')
          }
          variant="solid"
          withIcon
        >
          {type === 'delete'
            ? translate('reservation.delete')
            : type === 'reject'
            ? translate('reservation.reject')
            : type === 'submit'
            ? translate('reservation.submit')
            : translate('reservation.approve')}
        </Button>
      </div>
    </div>
  );
};
