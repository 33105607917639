import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { UseMutateFunction } from '@tanstack/react-query';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { DatePicker } from '@/shared/components/form/DatePicker';
import { RequiredFieldIndicator } from '@/shared/components/form/RequiredFieldIndicator';
import { Select } from '@/shared/components/form/Select';
import { useEquipment } from '@/shared/components/ui/Modal/Equipment/hooks/useEquipment.ts';
import { Typography } from '@/shared/components/ui/Typography';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity';
import { useGlobalStore } from '@/shared/store/global';
import { useModalStore } from '@/shared/store/modal';
import { classnames } from '@/shared/utils/classnames';

import { RolesEnum } from '@/shared/types/global/enums';
import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';

type FormBodyProps<T, K> = {
  params: UseFormReturn;
  mutate: UseMutateFunction<T, Error, FieldValues, unknown>;
  states: Record<string, K>;
};

export function ReserveEquipment<T, K>({ mutate, params }: Readonly<FormBodyProps<T, K>>) {
  const { isSubmitting } = useModalStore(({ isSubmitting }) => ({
    isSubmitting,
  }));

  const { locale } = useIntl();
  const company = useGlobalStore(store => store.companies);
  const zpsUser = useGlobalStore(store => store.zpsUser);
  const { renderItemVisibility } = useRole();
  const {
    closeModal,
    modifiedActiveAssets,
    modifiedActiveCompanies,
    modifiedCategories,
    modifiedCategoriesPaths,
    modifiedGeofences,
  } = useEquipment(params);
  const isManager = renderItemVisibility([RolesEnum.MANAGER, RolesEnum.ENGINEER]);
  //const isSubContractor = renderItemVisibility([RolesEnum.SUBCONTRACTOR]);
  //const isWorker = renderItemVisibility([RolesEnum.WORKER]);
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    watch,
  } = params;
  const onSubmit = async (data: FieldValues) => {
    try {
      const creatationData = { ...data, type: 'Save' };
      mutate(creatationData);
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmitDraft = (data: FieldValues) => {
    try {
      const mData = { ...data, type: 'Draft' };
      mutate(mData);
    } catch (error) {
      console.error(error);
    }
  };

  const myFirm = company
    .filter(item => item.orgResName == zpsUser.orgResName)
    .map(company => {
      return {
        id: String(company.orgResName),
        name: company.orgName,
      };
    });

  console.log('myFirm', zpsUser.orgResName);
  watch();
  const setDateValue = (type: string, selectedDate: Date | null) => {
    if (selectedDate) {
      const hours = selectedDate.getHours();
      if (type === 'toDate') {
        if (selectedDate < getValues('fromDate')) {
          selectedDate = getValues('fromDate');
        } else if (hours >= 17) {
          selectedDate.setHours(17, 0);
        }
      } else if (hours < 9) {
        selectedDate.setHours(9, 0);
      } else if (hours >= 17) {
        selectedDate.setHours(17, 0);
      }
    }
    setValue(type, selectedDate);
  };

  return (
    <form className="grid w-full max-w-md gap-y-3 pb-[70px]" onSubmit={handleSubmit(onSubmit)}>
      <Typography as="strong" className="text-xl font-bold tracking-[0.1px]">
        {translate('global.reservations')}
      </Typography>

      <div className="grid grid-cols-1 md:grid-cols-[170px_1fr] items-center gap-x-3">
        <Typography className="inline-flex w-[310px] gap-x-0.5 text-start text-sm font-medium">
          {translate('filters.type')}
          <RequiredFieldIndicator />
        </Typography>

        <div className="w-full">
          <Select
            className="w-full"
            data={modifiedCategories}
            defaultValue={getValues('categoryType')}
            hasError={!!errors?.categoryType?.message}
            label={'filters.select'}
            longText
            onChange={value => setValue('categoryType', value)}
            variant="default"
          />
        </div>
      </div>

      {getValues('categoryType') &&
        modifiedCategoriesPaths &&
        modifiedCategoriesPaths?.length > 0 && (
          <div className="grid grid-cols-1 md:grid-cols-[170px_1fr] items-center gap-x-3">
            <Typography className="inline-flex w-[310px] gap-x-0.5 text-start text-sm font-medium">
              {translate('filters.subType')}
              <RequiredFieldIndicator />
            </Typography>

            <div className="w-full">
              <Select
                className="w-full"
                data={getValues('categoryType') && modifiedCategoriesPaths}
                defaultValue={getValues('categorySubType')}
                //hasError={!!errors?.categorySubType?.message}
                label={'filters.select'}
                onChange={value => setValue('categorySubType', value)}
                variant="default"
              />
            </div>
          </div>
        )}

      <div className="grid grid-cols-1 md:grid-cols-[170px_1fr] items-center gap-x-3">
        <Typography className="inline-flex w-[310px] gap-x-0.5 text-start text-sm font-medium">
          {translate('global.equipment')}
          <RequiredFieldIndicator />
        </Typography>

        <div className="w-full">
          <Select
            className="z-[52] w-full"
            data={modifiedActiveAssets}
            defaultValue={getValues('asset')}
            hasError={!!errors?.asset?.message}
            label={'filters.select'}
            longText
            onChange={value => setValue('asset', value)}
            variant="default"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-[170px_1fr] items-center gap-x-3">
        <Typography className="inline-flex w-[310px] gap-x-0.5 text-start text-sm font-medium">
          {translate('calendar.from')}
          <Typography className="text-sm text-red-500">*</Typography>
        </Typography>

        <div className="w-full">
          <DatePicker
            className={classnames('w-full', {
              'border-rose-500 focus:border-rose-300 focus:outline-none focus:ring focus:ring-rose-200':
                errors?.fromDate,
            })}
            customDateFormat={true}
            dateFormat={locale === LocaleEnum.JPN ? 'yyyy/MM/dd HH:mm' : 'dd.MM.yyyy hh:mm a'}
            maxTime={new Date(new Date(new Date().setHours(17)).setMinutes(0))}
            minDate={new Date()}
            minTime={new Date(new Date(new Date().setHours(9)).setMinutes(0))}
            onChange={value => {
              setDateValue('fromDate', value);
              setValue('toDate', null);
            }}
            popperClassName="react-date-picker-popper"
            selected={getValues('fromDate')}
            showArrows={false}
            showTimeSelect
            timeFormat="p"
            timeIntervals={15}
          />
        </div>
      </div>

      {getValues('fromDate') && (
        <div className="grid grid-cols-1 md:grid-cols-[170px_1fr] items-center gap-x-3">
          <Typography className="inline-flex w-[310px] gap-x-0.5 text-start text-sm font-medium">
            {translate('calendar.to')}
            <Typography className="text-sm text-red-500">*</Typography>
          </Typography>

          <div className="w-full">
            <DatePicker
              className={classnames('z-[999] w-full', {
                'border-rose-500 focus:border-rose-300 focus:outline-none focus:ring focus:ring-rose-200':
                  errors?.toDate,
              })}
              customDateFormat={true}
              dateFormat={locale === LocaleEnum.JPN ? 'yyyy/MM/dd HH:mm' : 'dd.MM.yyyy hh:mm a'}
              maxDate={getValues('fromDate')}
              maxTime={new Date(new Date(new Date().setHours(17)).setMinutes(0))}
              minDate={getValues('fromDate')}
              minTime={new Date(getValues('fromDate').getTime() + 60000)}
              onChange={value => {
                const fromMinutes = getValues('fromDate').getMinutes();
                const fromHours = getValues('fromDate').getHours();
                const toMinutes = value?.getMinutes();
                const toHours = value?.getHours();
                if (toMinutes !== undefined && toHours !== undefined && toHours < fromHours) {
                  if (fromHours === 17) {
                    value?.setHours(17, 0);
                  } else {
                    if (fromMinutes === 45) {
                      value?.setMinutes(0);
                      value?.setHours(fromHours + 1);
                    } else {
                      value?.setMinutes(fromMinutes + 15);
                      value?.setHours(fromHours);
                    }
                  }
                }
                setDateValue('toDate', value);
              }}
              popperClassName="react-date-picker-popper"
              selected={getValues('toDate')}
              showArrows={false}
              showTimeSelect
              timeFormat="p"
              timeIntervals={15}
            />
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-[170px_1fr] items-center gap-x-3">
        <Typography className="inline-flex w-[310px] gap-x-0.5 text-start text-sm font-medium">
          {translate('filters.zone')}
        </Typography>

        <div className="w-full">
          <Select
            className="w-full"
            data={modifiedGeofences}
            defaultValue={getValues('zone')}
            hasError={!!errors?.zone?.message}
            label={'filters.select'}
            onChange={value => setValue('zone', value)}
            variant="default"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-[170px_1fr] items-center gap-x-3">
        <Typography className="inline-flex w-[310px] gap-x-0.5 text-start text-sm font-medium">
          {translate('global.firm')}
          <RequiredFieldIndicator />
        </Typography>

        <div className="w-full">
          <Select
            className="w-full"
            data={isManager ? modifiedActiveCompanies : myFirm}
            defaultValue={getValues('firm')}
            hasError={!!errors?.firm?.message}
            label={'filters.select'}
            onChange={value => setValue('firm', value)}
            variant="default"
          />
        </div>
      </div>

      <div className="absolute bottom-0 left-0 z-20 flex h-[70px] w-full items-center justify-between gap-x-2 rounded-b-2xl border-t border-slate-200 bg-slate-100 px-3 py-2">
        <Button
          className="border-slate-200 bg-white text-slate-800 hover:bg-slate-50 hover:text-slate-900 focus:bg-slate-50 focus:text-slate-900"
          onClick={closeModal}
          type="reset"
          variant="outline"
        >
          {translate('filters.cancel')}
        </Button>
        <div className="flex flex-row">
          <Button
            className={'mr-3'}
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmitDraft)}
            variant="outline"
            withIcon
          >
            {translate('reservation.saveAsDraft')}
          </Button>
          <Button isLoading={isSubmitting} type="submit" variant="solid" withIcon>
            {translate('settings.save')}
          </Button>
        </div>
      </div>
    </form>
  );
}
