import { useQuery } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/global';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

export const useGetZpsUsers = () => {
  const setZPSUser = useGlobalStore(store => store.setZPSUser);

  const { user } = useFetchUserData();

  return useQuery({
    enabled: !!user?.accountResourceName && !!user?.userResourceName,

    queryFn: async () => {
      const result = await AssetsAPINamespace.getZPSUserDetails(
        user?.accountResourceName as string,
        user?.userResourceName as string,
      );

      setZPSUser(result);

      return result;
    },

    queryKey: [QUERY_KEY.ZPS_USER, user?.accountResourceName, user?.userResourceName],
  });
};
