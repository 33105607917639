import { FC, useState } from 'react';

import { QRCodeCanvas } from 'qrcode.react';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { Input } from '@/shared/components/form/Input';
import { Label } from '@/shared/components/form/Label';
import { SiteLogo } from '@/shared/components/ui/SiteLogo';
import { Typography } from '@/shared/components/ui/Typography';
import { notifyService } from '@/shared/services/notify.service';
import { useGlobalStore } from '@/shared/store/global';

import { MFASetupFormProps } from '../../types/types';

import { StatusEnum } from '@/shared/types/global/enums.ts';

export const MFASetupForm: FC<MFASetupFormProps> = props => {
  const { handleCancel, handleSubmit, setupKey, totpURI } = props;

  const status = useGlobalStore(state => state.status);
  const isLoading = status === StatusEnum.LOADING;
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(setupKey);
      setCopied(true);
      setTimeout(() => notifyService.success(translate('messages.copySuccess')), 2000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <SiteLogo className="mx-auto" color="blue" />

      <div className="grid gap-y-1 text-center">
        <Typography as="strong" className="text-xl font-semibold text-slate-950">
          {translate('auth.mfaSetupTitle')}
        </Typography>

        <Typography className="text-sm font-medium text-slate-600">
          {translate('auth.mfaSetupSubtitle')}
        </Typography>
      </div>

      <form autoComplete="off" className="grid gap-y-3.5" onSubmit={handleSubmit}>
        <Label className="grid gap-y-2" labelText={translate('auth.mfaSetupLabel')}>
          <div className="inline-flex items-center gap-x-3">
            <Input
              aria-label="mfa-code-textfield"
              autoFocus={true}
              disabled={isLoading}
              onChange={() => null}
              value={setupKey}
            />
            <Button
              aria-label="mfa-setup-copy"
              disabled={copied}
              onClick={handleCopy}
              variant="outline"
              withIcon
            >
              {translate(copied ? 'filters.copied' : 'filters.copy')}
            </Button>
          </div>
        </Label>

        <div className="my-5 flex justify-center">{<QRCodeCanvas value={totpURI} />}</div>

        <div className="grid gap-y-1 text-left">
          <Typography className="text-sm font-medium text-slate-600">
            {translate('auth.mfaSetupInstruction')}
          </Typography>
        </div>
        <div className="inline-flex items-center gap-x-3">
          <Button
            aria-label="mfa-setup-cancel"
            className="w-[50%]"
            disabled={isLoading}
            onClick={handleCancel}
            variant="outline"
            withIcon
          >
            {translate('filters.cancel')}
          </Button>

          <Button
            aria-label="mfa-setup-continue"
            className="w-[50%]"
            disabled={isLoading}
            isLoading={isLoading}
            type="submit"
            variant="solid"
            withIcon
          >
            {translate('filters.next')}
          </Button>
        </div>
      </form>
    </>
  );
};
