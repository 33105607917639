import { FC, Fragment, UIEvent } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { useProgressBar } from '@/layout/Auth/hooks';
import { TransitionChild } from '@/shared/components/animation/TransitionChild';
// import { useMedia } from 'react-use';
import { Overlay } from '@/shared/components/ui/Overlay';
import { ProgressBar } from '@/shared/components/ui/ProgressBar';
import { useLockBodyScroll } from '@/shared/hooks/ui/useLockBodyScroll.ts';
import { useModalCleanupEffect } from '@/shared/hooks/ui/useModalCleanupEffect.ts';
import { useRenderModalContent } from '@/shared/hooks/ui/useRenderModalContent.tsx';
import { useModalStore } from '@/shared/store/modal';
import { classnames } from '@/shared/utils/classnames.ts';

import { PopupTypeEnum } from '@/shared/types/global/enums.ts';

export const Modal: FC = () => {
  const isOpen = useModalStore(state => state.isOpen);
  const type = useModalStore(state => state.type);

  const { handleScrollDebounced, percentage, setPercentage } = useProgressBar(type, isOpen);
  const { renderModalContent } = useRenderModalContent(type);

  useLockBodyScroll(isOpen);
  useModalCleanupEffect(isOpen, type);

  const isTermsOfUse = type === PopupTypeEnum.TERMS_OF_USE;

  if (isOpen)
    return (
      <Transition appear as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            if (isTermsOfUse) setPercentage(0);
          }}
        >
          <Overlay className="bg-black/25 backdrop-blur-sm" />

          {isTermsOfUse && <ProgressBar percentage={percentage} />}

          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div
              className={classnames(
                'flex min-h-full items-center justify-center p-3 text-center',
                isTermsOfUse && 'p-0 md:p-3',
              )}
            >
              <TransitionChild to="content">
                <Dialog.Panel
                  className={classnames(
                    'w-full max-w-md transform rounded-2xl bg-white p-4 align-middle shadow-xl transition-all md:p-5',
                    isTermsOfUse
                      ? 'max-h-screen max-w-7xl overflow-y-auto md:max-h-[calc(100dvh-20px)]'
                      : 'max-w-md',
                    isTermsOfUse && 'rounded-none md:rounded-2xl',
                  )}
                  onScroll={(event: UIEvent<HTMLElement>) =>
                    handleScrollDebounced(event.currentTarget)
                  }
                >
                  {renderModalContent()}
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
};
