import { queryOptions, useQuery } from '@tanstack/react-query';

import { ReadersAPINamespace } from '@/shared/api/readers/readers.requests.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useLocalSearchParams } from '@/shared/hooks/global/useLocalSearchParams.ts';
import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

export namespace useReadersHooks {
  export const getAll = () => {
    const site = useUIStore(state => state.site);
    const setReaders = useGlobalStore(state => state.setReaders);
    const readerVisibility = useUIStore(state => state.readerVisibility);

    const { user } = useFetchUserData();
    const { isHistoricalMode } = useLocalSearchParams();

    const readersQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!site,

      queryFn: async () => {
        const data = await ReadersAPINamespace.getAll({
          accountResourceName: user?.accountResourceName as string,
          siteResourceName: site,
        });

        const modifiedData = data?.results
          .map(reader => {
            return {
              ...reader,
              id: reader.readerResName,
              visible: readerVisibility,
            };
          })
          .filter(reader => reader.readerLocation);

        setReaders(modifiedData ?? []);

        return modifiedData;
      },

      queryKey: [
        QUERY_KEY.GET_READERS,
        user?.accountResourceName,
        user,
        site,
        isHistoricalMode,
        readerVisibility,
      ],
    });

    return useQuery(readersQueryOptions);
  };
}
