import { FC } from 'react';

import { translate } from '@/i18n';
import { useGeofenceParams } from '@/modules/Home/hooks/map/useGeofenceParams';
import { useSidebarDetails } from '@/modules/Home/hooks/sidebar';
import { useGeofencesHooks } from '@/shared/api/geofences/geofences.hooks.ts';
import { Button } from '@/shared/components/buttons/Button';
import { Typography } from '@/shared/components/ui/Typography';
import { useModalHandlerTypes } from '@/shared/hooks/global/useModalHandlerTypes.ts';
import { useGlobalStore } from '@/shared/store/global';
import { useModalStore } from '@/shared/store/modal';
import { classnames } from '@/shared/utils/classnames';

import { confirmIcons, ConfirmProps } from '@/shared/components/ui/Modal/Modal.types';
import { GeofenceCategoryEnum } from '@/shared/types/global/geofences.types.ts';

export const Confirm: FC<ConfirmProps> = ({ type }) => {
  const { setIsClose } = useModalHandlerTypes();
  const { updateSearchParameters } = useGeofenceParams();
  const { setCurrentGeofence } = useSidebarDetails();

  const { mutate: update } = useGeofencesHooks.updateStatus();
  const { mutate: remove } = useGeofencesHooks.removeGeofence();

  const geofences = useGlobalStore(state => state.geofences);
  const content = useModalStore(state => state.payload);

  const Icon = confirmIcons[type];
  const { geofenceId, geofenceName } = content as { geofenceId: string; geofenceName: string };

  const handleCancel = () => {
    updateSearchParameters();
    setCurrentGeofence([], GeofenceCategoryEnum.INACTIVE);
    setIsClose();
  };

  const handleSuccess = async () => {
    try {
      const currentGeofence = geofences.find(geofence => geofence.geofenceResName === geofenceId);

      if (!currentGeofence) return;
      update(currentGeofence);

      setCurrentGeofence([], GeofenceCategoryEnum.INACTIVE);
      handleCancel();
    } catch (error) {
      console.error(error);
      setCurrentGeofence([], GeofenceCategoryEnum.INACTIVE);
    }
  };

  const handleDelete = async () => {
    try {
      const currentGeofence = geofences.find(geofence => geofence.geofenceResName === geofenceId);

      if (!currentGeofence) return;

      remove(currentGeofence);
      setIsClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="grid items-center justify-center gap-y-5 p-10">
      <Icon className="mx-auto h-[62px] w-[62px]" />

      <div className="grid gap-y-2.5 text-center">
        <Typography as="strong" className="text-2xl font-bold tracking-[0.12px] text-slate-800">
          {type === 'delete' ? translate('confirm.deleteTitle') : translate('confirm.saveTitle')}
        </Typography>

        <Typography className="text-base font-medium tracking-[0.05px] text-slate-500">
          {type === 'delete' ? (
            <>
              {translate('confirm.deleteMessage')} &quot;{geofenceName}&quot;?
            </>
          ) : (
            translate('confirm.saveMessage')
          )}
        </Typography>
      </div>

      <div className="flex justify-center gap-x-4">
        <Button
          className=" !border-slate-200 text-slate-800 hover:!bg-slate-100"
          onClick={handleCancel}
          variant="outline"
        >
          {type === 'delete' ? translate('settings.cancel') : translate('confirm.inactive')}
        </Button>

        <Button
          className={classnames(
            '',
            type === 'delete'
              ? 'bg-red-600 text-white hover:bg-red-700 focus:bg-red-700'
              : 'bg-blue-600 text-white hover:bg-blue-700 focus:bg-blue-700',
          )}
          onClick={type === 'delete' ? handleDelete : handleSuccess}
          variant="solid"
        >
          {type === 'delete' ? translate('confirm.delete') : translate('confirm.activate')}
        </Button>
      </div>
    </div>
  );
};
