import { create } from 'zustand';

import { LocalStoreProps } from '@/shared/store/local/local.types';

export const useLocalStore = create<LocalStoreProps>((set, _) => ({
  setSocketInstance: (payload: null | WebSocket) => {
    set({
      socketInstance: payload,
    });
  },
  socketInstance: null,
}));
