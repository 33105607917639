import { queryOptions, useQuery } from '@tanstack/react-query';

import { AnalyticsAPINamespace } from '@/shared/api/analytics/analytics.requests.ts';
import { useGeofencesHooks } from '@/shared/api/geofences/geofences.hooks';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

import { AnalyticsDefaultProps, TripEdge } from '@/shared/api/analytics/analytics.types.ts';

export namespace useAnalyticsHooks {
  export const getGeofenceSummaries = (
    data: Required<Pick<AnalyticsDefaultProps, 'endTime' | 'startTime'>>,
  ) => {
    const site = useUIStore(store => store.site);

    const { user } = useFetchUserData();

    const { endTime, startTime } = data;

    const geofencesQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName,

      queryFn: async () => {
        return await AnalyticsAPINamespace.getGeofenceSummaries({
          accountResName: user?.accountResourceName as string,
          endTime,
          siteResName: site,
          startTime,
        });
      },

      queryKey: [
        QUERY_KEY.ANALYTICS_DWELL_TIMES,
        user?.accountResourceName,
        site,
        startTime,
        endTime,
      ],
    });

    return useQuery(geofencesQueryOptions);
  };

  export const getAssetUtilizationSummaries = (
    data: Required<Pick<AnalyticsDefaultProps, 'endTime' | 'startTime'>> &
      Partial<Pick<AnalyticsDefaultProps, 'categoryTreeResourceName'>> & {
        dateParameter: null | string;
      },
  ) => {
    const site = useUIStore(store => store.site);

    const { user } = useFetchUserData();
    const { data: geofences = [] } = useGeofencesHooks.getAll();

    const { categoryTreeResourceName, dateParameter, endTime, startTime } = data;

    const geofencesQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!dateParameter,

      queryFn: async () => {
        const modifiedGeofences = geofences.map(geofence => geofence.geofenceResName);

        return await AnalyticsAPINamespace.getAssetUtilizationSummaries({
          accountResName: user?.accountResourceName as string,
          categoryTreeResourceName,
          endTime,
          geofenceResourceNameList: modifiedGeofences || [],
          siteResName: site,
          startTime,
        });
      },

      queryKey: [
        QUERY_KEY.ANALYTICS_TRIPS,
        user?.accountResourceName,
        site,
        startTime,
        endTime,
        categoryTreeResourceName,
        geofences,
      ],
    });

    return useQuery(geofencesQueryOptions);
  };

  export const getGeofenceTripsSummaries = (
    data: Required<Pick<AnalyticsDefaultProps, 'endTime' | 'startTime'>> & TripEdge,
  ) => {
    const site = useUIStore(store => store.site);

    const { user } = useFetchUserData();

    const { asset, endTime, endZone, startTime, startZone } = data;

    const geofencesQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!asset && !!startZone && !!endZone,

      queryFn: async () => {
        return await AnalyticsAPINamespace.getGeofenceTripsSummaries({
          accountResName: user?.accountResourceName as string,
          assetResourceNameList: [asset],
          endGeofenceResourceName: endZone,
          endTime,
          siteResName: site,
          startGeofenceResourceName: startZone,
          startTime,
        });
      },

      queryKey: [
        QUERY_KEY.ANALYTICS_TRIPS,
        user?.accountResourceName,
        site,
        startTime,
        endTime,
        startZone,
        endZone,
        asset,
      ],
    });

    return useQuery(geofencesQueryOptions);
  };
}
