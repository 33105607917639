import { useQuery } from '@tanstack/react-query';

import { SiteOperationsAPINamespace } from '@/shared/api/site-operations';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

export const useGetSiteShifts = () => {
  const uiStore = useUIStore();
  const site = useUIStore(state => state.site);
  const siteCalendars = useUIStore(state => state.siteCalendars);

  const { user } = useFetchUserData();

  const getSiteShifts = async () => {
    const siteShifts = await SiteOperationsAPINamespace.getSiteShifts({
      accountResName: user?.accountResourceName as string,
      calendarResName: siteCalendars[0].calendarResName,
      siteResName: site,
    });

    uiStore.setSiteShifts(siteShifts);

    return siteShifts;
  };

  useQuery({
    enabled: !!user?.accountResourceName && !!user?.userResourceName && siteCalendars.length > 0,

    queryFn: getSiteShifts,

    queryKey: [
      QUERY_KEY.SITE_SHIFTS_GET,
      user?.accountResourceName,
      user?.userResourceName,
      siteCalendars,
    ],
  });
};
