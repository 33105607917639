import { FC } from 'react';

import { Geofence } from '@/modules/Home/components/map/Geofence';
import { useGlobalStore } from '@/shared/store/global';

import { GeofencesProps } from '@/shared/types/global/geofences.types.ts';

export const Geofences: FC<{ geofences: GeofencesProps[] }> = ({ geofences = [] }) => {
  const visibleGeofenceIds = useGlobalStore(store => store.visibleGeofenceIds);

  const visibleGeofences = geofences.filter(geofence =>
    visibleGeofenceIds.includes(geofence.geofenceResName),
  );

  return (
    <>
      {visibleGeofences.map(geofence => (
        <Geofence geofence={geofence} key={geofence?.geofenceResName} />
      ))}
    </>
  );
};
