import { FC } from 'react';

import { SiteSelectItem } from '@/shared/components/ui/UserMenu/SelectItem';
import { useUIStore } from '@/shared/store/ui';
import { classnames } from '@/shared/utils/classnames.ts';

import ChevronRight from '@/assets/icons/chevron-right.svg?react';
import { WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithClassName & {
  onClick: () => void;
};

export const SiteMenuItem: FC<Props> = ({ className, onClick }) => {
  const activeSite = useUIStore(state => state.site);
  const sitesList = useUIStore(state => state.sitesList);

  const siteName = sitesList.find(site => site.siteResName === activeSite)?.siteName ?? 'N/A';

  return (
    <SiteSelectItem
      aria-label="site-menu-action"
      className={classnames(
        'inline-flex w-full items-center justify-between rounded-none text-sm font-medium',
        className,
      )}
      onClick={onClick}
    >
      {siteName}
      <ChevronRight />
    </SiteSelectItem>
  );
};
