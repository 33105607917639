import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { usePeopleAsset } from '@/modules/Subcontractors/hooks/usePeopleAsset.ts';
import { useAssetsCategories } from '@/shared/hooks/assets/useAssetsCategories.ts';
import { useGlobalStore } from '@/shared/store/global';
import { useModalStore } from '@/shared/store/modal';
import { useUIStore } from '@/shared/store/ui';

import { RolesEnum } from '@/shared/types/global/enums.ts';
import { MapFilterPropEnum, MapFilterType } from '@/shared/types/global/filters.types.ts';

const DEFAULT_FILTERS = {
  assetItem: [],
  equipmentReservedBy: [],
  equipmentSubType: [],
  equipmentType: [],
  firm: [],
  group: [],
  resName: [],
  tag: [],
  workerRole: [],
  zoneFloor: [],
  zoneItem: [],
  zoneType: [],
};

const hasSelectedFilters = (data: Record<string, string | string[]>) =>
  Object.values(data)
    .flatMap(value => Object.values(value))
    .some(value => value.length > 0);

export const useMapFilter = () => {
  const setIsClose = useModalStore(state => state.setIsClose);
  const mapFilter = useUIStore(state => state.mapFilter);
  const setMapFilter = useUIStore(state => state.setMapFilter);
  const companies = useGlobalStore(state => state.companies);
  const geofences = useGlobalStore(state => state.geofences);
  const assets = useGlobalStore(state => state.assets);
  const floorsList = useUIStore(state => state.floorsList);

  const { filteredAssetsCategories } = useAssetsCategories();
  const { siteTagsList } = usePeopleAsset();

  const form = useForm<Omit<MapFilterType, 'searchKey'>>({
    defaultValues: DEFAULT_FILTERS,
  });

  const formData = form.watch();
  const filtersSelected = hasSelectedFilters(formData) || hasSelectedFilters(mapFilter);

  const filterOptions = useMemo(() => {
    return {
      [MapFilterPropEnum.ASSET_ITEM]: assets.map(asset => asset.assetResName),
      [MapFilterPropEnum.EQUIPMENT_RESERVED_BY]: [],
      [MapFilterPropEnum.EQUIPMENT_SUB_TYPE]: [
        ...filteredAssetsCategories.reduce((subTypeNames, category) => {
          category.categoryPathList.forEach(treePath => subTypeNames.add(treePath.categoryPath));
          return subTypeNames;
        }, new Set<string>()),
      ],
      [MapFilterPropEnum.EQUIPMENT_TYPE]: filteredAssetsCategories
        .map(item => item.categoryTreeName)
        .filter((value, index, self) => self.indexOf(value) === index),
      [MapFilterPropEnum.FIRM]: companies
        .map(({ orgName }) => orgName)
        .filter((value, index, self) => self.indexOf(value) === index),
      [MapFilterPropEnum.GROUP]: [],
      [MapFilterPropEnum.RES_NAME]: [],
      [MapFilterPropEnum.TAG]: siteTagsList
        ?.map(({ name }) => name)
        .filter((value, index, self) => self.indexOf(value) === index),
      [MapFilterPropEnum.WORKER_ROLE]: Object.values(RolesEnum),
      [MapFilterPropEnum.ZONE_FLOOR]: floorsList.map(({ name }) => name),
      [MapFilterPropEnum.ZONE_ITEM]: geofences.map(geofence => geofence.geofenceResName),
      [MapFilterPropEnum.ZONE_TYPE]: geofences
        .map(({ geofenceCategory }) => geofenceCategory)
        .filter((value, index, self) => self.indexOf(value) === index),
    };
  }, [companies, filteredAssetsCategories, floorsList, geofences, assets, siteTagsList]);

  const handleFormSubmit = form.handleSubmit(values => {
    try {
      setMapFilter(values);
      setIsClose();
    } catch (error) {
      console.error(error);
    }
  });

  const handleFormReset = () => {
    form.reset(DEFAULT_FILTERS);
  };

  useEffect(() => {
    form.reset(mapFilter);
  }, [mapFilter, form]);

  return { filterOptions, filtersSelected, form, handleFormReset, handleFormSubmit, setIsClose };
};
