import { FC, Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import { PrismicProvider } from '@prismicio/react';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';

import { I18nProvider } from '@/i18n';
import { AppRoutes } from '@/routes';
import { Modal } from '@/shared/components/ui/Modal';
import { Popup } from '@/shared/components/ui/Popup';
import { useLanguageFont } from '@/shared/hooks/global/useLanguageFont.ts';
import { useFullscreenLoader } from '@/shared/hooks/ui/useFullscreenLoader.ts';
import { client } from '@/shared/prismic-client/prismic.ts';
import { usePopupStore } from '@/shared/store/popup';
import { useUIStore } from '@/shared/store/ui';
import { toastOptions } from '@/shared/utils/toaster.ts';

const environmentID = import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID;

export const App: FC = () => {
  const currentLanguage = useUIStore(state => state.currentLanguage);
  const popups = usePopupStore(state => state.popups);

  const renderPopupsList = () => {
    return popups.map((popup, index) => <Popup key={popup.id} {...{ index, popup }} />);
  };

  useLanguageFont();
  useFullscreenLoader();

  return (
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={{
        environmentID,
      }}
    >
      <PrismicProvider client={client}>
        <I18nProvider locale={currentLanguage}>
          <BrowserRouter>
            <Suspense fallback={null}>
              <Modal />

              <AppRoutes />
            </Suspense>

            {renderPopupsList()}
          </BrowserRouter>

          <Toaster position="top-center" reverseOrder={true} toastOptions={toastOptions} />
        </I18nProvider>
      </PrismicProvider>
    </FlagsmithProvider>
  );
};
