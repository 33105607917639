import { useQuery } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

export function useSetAssetsGeofenceOccupants() {
  const site = useUIStore(store => store.site);

  const { user } = useFetchUserData();

  return useQuery({
    enabled: !!user?.accountResourceName && !!site,

    queryFn: async () => {
      return await AssetsAPINamespace.getAssetsGeofenceOccupants(
        user?.accountResourceName ?? '',
        site,
      );
    },

    queryKey: [QUERY_KEY.EQUIPMENT_OCCUPANTS, user?.accountResourceName, site],
    retry: false,
    retryOnMount: false,
  });
}
