import { useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { translate } from '@/i18n';
import { usePeopleAsset } from '@/modules/Subcontractors/hooks/usePeopleAsset.ts';
import { useMutateFirm } from '@/shared/components/ui/Modal/Subcontractor/hooks/useFirmMutations';
import { useMutatePeopleAsset } from '@/shared/components/ui/Modal/Subcontractor/hooks/usePeopleMutations';
import { useAssetsCategories } from '@/shared/hooks/assets/useAssetsCategories.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { mixpanelService } from '@/shared/services/mixpanel.service.ts';
import { notifyService } from '@/shared/services/notify.service';
import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';

import {
  AssetsCreateRequest,
  AssetsUpdateRequest,
  OrganizationBodyRequest,
  OrganizationUpdateRequest,
  ZPSUserRequestBody,
} from '@/shared/api/assets/assets.types';
import { ExtraPropertyKeys, FormTypes } from '@/shared/components/ui/Modal/Subcontractor/types';

export const useInviteForm = () => {
  const [mode, setMode] = useState('firm');
  const {
    activeFirm,
    activeWorker,
    activeWorkerId: workerId,
    assets,
    categoryPathResrName,
    categoryTreeResrName,
    disableEmailAndPasswordEdit,
    firmHasWorkers,
    forcePhoneEdit,
    userResrName,
    workerHasTags,
    workerisZpsUser,
  } = usePeopleAsset();
  const { user } = useFetchUserData();
  const site = useUIStore(state => state.site);
  const companies = useGlobalStore(store => store.companies);
  const { filteredAssetsCategories } = useAssetsCategories();

  const {
    createAssetsMutation,
    createOneZPSUserMutation,
    deleteAssetsMutation,
    personForm,
    updateAssetsMutation,
    updateOneZPSUserMutation,
  } = useMutatePeopleAsset();
  const { createFirmMutation, deleteFirmMutation, firmForm, updateFirmMutation } = useMutateFirm();

  const personFormHandler = async (data: FieldValues, type: FormTypes) => {
    if (
      type === 'workerEdit' &&
      !forcePhoneEdit &&
      disableEmailAndPasswordEdit &&
      data.email != activeWorker?.email
    ) {
      notifyService.error(translate('messages.workerEmailPasswordEditError'));
      return;
    }
    const emailToLower = String(data.email).toLocaleLowerCase();
    const body: AssetsCreateRequest = {
      accountResName: user?.accountResourceName + '',
      assetName: data.givenName + ' ' + data.familyName,
      categoryPathResName: categoryPathResrName + '',
      categoryTreeResName: categoryTreeResrName + '',
      extraProperties: [
        { key: ExtraPropertyKeys.givenName, value: data.givenName },
        { key: ExtraPropertyKeys.familyName, value: data.familyName },
        { key: ExtraPropertyKeys.email, value: emailToLower },
        { key: ExtraPropertyKeys.phone, value: data.phone },
        { key: ExtraPropertyKeys.role, value: data.role },
        { key: ExtraPropertyKeys.firm, value: data.firm },
      ],
      siteResName: site,
    };

    const category = filteredAssetsCategories.find(
      cat => cat.categoryTreeResName === categoryTreeResrName,
    );

    const company = companies.find(company => company.orgResName === data.firm);

    const giveZpsAccess = data.zpsAccess;

    const zpsBody: ZPSUserRequestBody = {
      accountResName: user?.accountResourceName + '',
      emailAddress: emailToLower,
      extraProperties: [{ key: ExtraPropertyKeys.role, value: data.role }],
      firstName: data.givenName,
      lastName: data.familyName,
      managers: [],
      orgResName: data.firm,
      phoneNumber: data.phone,
      status: giveZpsAccess ? 'active' : 'inactive',
      userGroups: [],
    };

    if (giveZpsAccess && !userResrName) {
      createOneZPSUserMutation(zpsBody);
    } else if (userResrName) {
      updateOneZPSUserMutation(zpsBody);
    }

    if (type === 'workerEdit') {
      //edit worker
      body.assetStatus = activeWorker?.status as 'active' | 'inactive';
      updateAssetsMutation(body);
    } else {
      createAssetsMutation(body);

      mixpanelService.logAddWorker(
        category?.categoryTreeName ?? 'N/A',
        company?.orgName ?? 'N/A',
        giveZpsAccess,
      );
    }
  };

  const firmFormHandler = async (data: FieldValues, type: FormTypes) => {
    const body: OrganizationBodyRequest = {
      accountResName: user?.accountResourceName + '',
      extraProperties: [
        { key: ExtraPropertyKeys.givenName, value: data.firmGivenName },
        { key: ExtraPropertyKeys.familyName, value: data.firmFamilyName },
        { key: ExtraPropertyKeys.email, value: data.firmEmail },
        { key: ExtraPropertyKeys.phone, value: data.firmPhone },
        { key: ExtraPropertyKeys.color, value: data.color },
      ],
      orgName: data.newFirm,
    };

    if (type === 'firmEdit') {
      //edit firm
      updateFirmMutation({
        orgResName: activeFirm?.orgResName ?? '',
        status: data.status,
        ...body,
      });
    } else {
      createFirmMutation({ status: data.status, ...body });
    }
  };

  const deleteHandler = async (type: FormTypes) => {
    if (type === 'firmEdit') {
      if (firmHasWorkers) {
        notifyService.error(translate('messages.firmWithWorkerDeleteError'));
        return;
      }
      //delete firm
      const deleteBody: OrganizationUpdateRequest = {
        accountResName: user?.accountResourceName + '',
        extraProperties: activeFirm?.extraProperties,
        orgName: activeFirm?.orgName,
        orgResName: activeFirm?.orgResName ?? '',
      };
      deleteFirmMutation(deleteBody);
    } else if (type === 'workerEdit') {
      if (workerHasTags && workerisZpsUser) {
        notifyService.error(translate('messages.workerWithZPSAcessOrTagsDeleteError'));
        return;
      }
      if (workerHasTags) {
        notifyService.error(translate('messages.workerWithTagsDeleteError'));
        return;
      }
      if (workerisZpsUser) {
        notifyService.error(translate('messages.workerWithZPSAcessDeleteError'));
        return;
      }
      //delete person
      const asset = assets.find(asset => asset.assetResName === workerId);
      const updateBody: AssetsUpdateRequest = {
        accountResName: user?.accountResourceName + '',
        assetName: asset?.assetName,
        assetResName: asset?.assetResName ?? '',
        assetStatus: 'deleted',
        siteResName: site,
      };
      deleteAssetsMutation(updateBody);
    }
  };

  const props: {
    type: FormTypes;
    mode: string;
    setMode: (value: string) => void;
  } = {
    mode,
    setMode: (value: string) => setMode(value),
    type: 'invite' as 'firmEdit' | 'invite' | 'workerEdit',
  };

  return {
    deleteHandler,
    firmForm,
    firmFormHandler,
    personForm,
    personFormHandler,
    props,
  };
};
