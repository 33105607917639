import { FC } from 'react';

import { Nav } from '@/shared/components/navigation/Nav';
import { NavLink } from '@/shared/components/navigation/Navbar/NavLink';
import { useNavbar } from '@/shared/hooks/ui/useNavbar.ts';
import { useNavbarLinks } from '@/shared/hooks/ui/useNavbarLinks.ts';
import { useAlertsStore } from '@/shared/store/actions';
import { classnames } from '@/shared/utils/classnames';

import { NavbarProps, NavLinkProps } from '@/shared/components/navigation/Navbar/Navbar.types';
import { RoutesEnum } from '@/shared/types/global/enums.ts';

export const Navbar: FC<NavbarProps> = ({ className, role }) => {
  const { filteredLinks } = useNavbarLinks();
  const { alertsSettings } = useNavbar();

  const wsAlertAvailable = useAlertsStore(store => store.wsAlertAvailable);

  return (
    <Nav
      className={classnames('hidden items-center gap-x-2 md:flex xl:gap-x-4', className)}
      role={role}
    >
      {filteredLinks.map((link: NavLinkProps) => {
        return (
          <NavLink
            hasNewEvent={link.path === RoutesEnum.ACTIONS && wsAlertAvailable}
            key={link.name.toLowerCase()}
            withCounter={link.path === RoutesEnum.ACTIONS && alertsSettings?.enabled}
            {...link}
          />
        );
      })}
    </Nav>
  );
};
