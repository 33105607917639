import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { CognitoUser } from 'amazon-cognito-identity-js';

import { MFASetupForm } from '@/modules/Auth/components/MFASetupForm';
import { MFAVerifyForm } from '@/modules/Auth/components/MFAVerifyForm';
import { useMFALogin } from '@/modules/Auth/hooks/useMFALogin';
import { Fade } from '@/shared/components/animation/Fade';
import { useGlobalStore } from '@/shared/store/global';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

export const MFASetup: FC = () => {
  const location = useLocation();

  const { handleVerifyMFACode, navigate, setShowVerificationForm, showVerificationForm } =
    useMFALogin();

  const state = location.state as {
    totpURI: string;
    secretCode: string;
    accountName: string;
    username: string;
    rememberMe: boolean;
    termsOfUse: 'done' | 'idle';
    password: string;
    isMFAEnabled: boolean;
  };

  const currentCognitoUser = useGlobalStore(store => store.currentCognitoUser);

  const onSetupFormSubmit = async () => {
    try {
      setShowVerificationForm(true);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const onVerifyFormSubmit = async (code: string) => {
    try {
      handleVerifyMFACode(
        code,
        state.accountName,
        state.rememberMe,
        currentCognitoUser as CognitoUser,
        state.isMFAEnabled,
      );
    } catch (error: unknown) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!currentCognitoUser) {
      navigate(RoutesEnum.LOGIN);

      return;
    }

    setShowVerificationForm(state.isMFAEnabled);
  }, [currentCognitoUser]);

  const handleCancelSetup = () => navigate(RoutesEnum.LOGIN);
  const handleCancelVerification = () => {
    if (state.isMFAEnabled) {
      navigate(RoutesEnum.LOGIN);
    } else {
      setShowVerificationForm(false);
    }
  };

  return (
    <Fade className="fullHeightWithoutFooter">
      <div className="flex h-full min-h-full items-center justify-center bg-slate-100">
        <div className="grid w-full max-w-[476px] gap-y-5 rounded-2xl border border-slate-200 bg-white px-5 py-[30px]">
          {showVerificationForm ? (
            <MFAVerifyForm
              handleCancel={handleCancelVerification}
              handleSubmit={onVerifyFormSubmit}
            />
          ) : (
            <MFASetupForm
              handleCancel={handleCancelSetup}
              handleSubmit={onSetupFormSubmit}
              setupKey={state.secretCode}
              totpURI={state.totpURI}
            />
          )}
        </div>
      </div>
    </Fade>
  );
};
