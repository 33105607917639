import { useGlobalStore } from '@/shared/store/global';
import { useSocketMapDataStore } from '@/shared/store/socket-map-data';
import { findTagValue } from '@/shared/utils/assets.ts';
import { isEmptyOrIncludes } from '@/shared/utils/is-empty-or-includes.ts';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { MapFilterType } from '@/shared/types/global/filters.types.ts';
import { LocationHistory } from '@/shared/types/global/locations.types.ts';

export const useLastTrackersLocation = (
  lastLocations: LocationHistory[],
  equipment: AssetBody[],
  filters: MapFilterType,
  searchKey: string,
) => {
  const assetsCategories = useGlobalStore(store => store.assetsCategories);
  const companies = useGlobalStore(store => store.companies);
  const socketMapData = useSocketMapDataStore(state => state.socketMapData);

  const assetsInUse = [...equipment];

  return lastLocations
    .filter(location => {
      const locationAsset = assetsInUse.find(
        asset => asset?.visible && location.tagResName === findTagValue(asset),
      );

      if (!locationAsset) return false;

      const locationAssetCategory = assetsCategories.find(
        category => category.categoryTreeResName === locationAsset?.categoryTreeResName,
      );

      // noinspection UnnecessaryLocalVariableJS
      const locationAssetIsVisible = (() => {
        const { equipmentReservedBy, equipmentSubType, equipmentType, firm, group, resName } =
          filters;

        if (
          searchKey.length === 0 &&
          firm.length === 0 &&
          equipmentType.length === 0 &&
          equipmentSubType.length === 0 &&
          equipmentReservedBy.length === 0 &&
          group.length === 0 &&
          resName.length === 0
        ) {
          return true;
        }

        const _firm = locationAsset?.companyName ?? '';
        const _categoryName = locationAssetCategory?.categoryTreeName ?? '';
        const subCategoryName =
          locationAssetCategory?.categoryPathList.find(
            sub => sub.categoryPathResName === locationAsset.categoryPathResName,
          )?.categoryPath ?? '';
        const _reservedBy = findTagValue(locationAsset, 'reservedBy') ?? '';
        const _group = findTagValue(locationAsset, 'group') ?? '';

        return (
          isEmptyOrIncludes(searchKey, locationAsset.assetName) &&
          isEmptyOrIncludes(firm, _firm) &&
          isEmptyOrIncludes(equipmentType, _categoryName) &&
          isEmptyOrIncludes(equipmentSubType, subCategoryName) &&
          isEmptyOrIncludes(equipmentReservedBy, _reservedBy) &&
          isEmptyOrIncludes(group, _group) &&
          isEmptyOrIncludes(resName, locationAsset.assetResName)
        );
      })();

      return locationAssetIsVisible;
    })
    .map(location => {
      const locationAsset = assetsInUse.find(asset => location.tagResName === findTagValue(asset))!;
      const locationAssetCompany = companies.find(
        company => company.orgResName === findTagValue(locationAsset, 'firm'),
      );
      const locationAssetCompanyName = locationAssetCompany?.orgName ?? 'N/A';
      const categoryTreeName = assetsCategories.find(
        category => category.categoryTreeResName === locationAsset?.categoryTreeResName,
      )?.categoryTreeName as string;

      return {
        ...location,
        anchor: {
          ...locationAsset,
          categoryTreeName,
          company: locationAssetCompanyName ?? 'N/A',
          tracker: true,
        },
      };
    })
    .filter(location => {
      const locationData = socketMapData.find(data => data.tagResName === location.tagResName);
      return locationData?.tagResName !== location.tagResName;
    });
};
