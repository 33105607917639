import { useSearchParams } from 'react-router-dom';

import { useGlobalStore } from '@/shared/store/global';
import { URL_PARAMS } from '@/shared/utils/constants.ts';

import { SidebarParameters } from '@/shared/types/global/parameters.types.ts';

export const useSidebarDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const geofenceStore = useGlobalStore(state => state);

  const sidebarParameter = searchParams.get(URL_PARAMS.SIDEBAR) as SidebarParameters;
  const editZoneIdParameter = searchParams.get(URL_PARAMS.GEOFENCE_RES_NAME);
  const currentMode = searchParams.get(URL_PARAMS.SIDEBAR);

  const handleExitEditor = () => {
    setSearchParams(params => {
      params.set(URL_PARAMS.SIDEBAR, SidebarParameters.EDIT);
      params.delete(URL_PARAMS.GEOFENCE_RES_NAME);
      return params;
    });
  };

  return {
    ...geofenceStore,
    currentMode,
    editZoneIdParameter,
    handleExitEditor,
    setSearchParams,
    sidebarParameter,
  };
};
