import { useRef } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ReservationsAPINamespace } from '@/shared/api/reservations/reservations.requests';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { reservationFormSchema } from '@/shared/schemas/validate-schemas';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { useGlobalStore } from '@/shared/store/global';
import { useModalStore } from '@/shared/store/modal';
import { useUIStore } from '@/shared/store/ui';
import { QUERY_KEY, RESERVATIONS } from '@/shared/utils/constants';

import { useWorkflowEngine } from './useWorkflowEngine';

import { updateReservationEvent } from '@/shared/api/reservations/reservations.types';

export function useCreateReservations() {
  const typeRef = useRef('Draft');

  const { site } = useUIStore(({ site }) => ({ site }));
  const { setIsClose, setIsSubmitting } = useModalStore(({ setIsClose, setIsSubmitting }) => ({
    setIsClose,
    setIsSubmitting,
  }));
  const company = useGlobalStore(store => store.companies);

  const params = useForm<FieldValues>({
    mode: 'onChange',
    resolver: zodResolver(reservationFormSchema),
  });

  const { user } = useFetchUserData();
  const workFlowCall = useWorkflowEngine();

  const queryClient = useQueryClient();
  const { mutate, ...states } = useMutation({
    mutationFn: async (data: FieldValues) => {
      setIsSubmitting(true);
      const { asset, firm, fromDate, toDate, type } = data;
      typeRef.current = type;

      const selectedCompany = await company.find(item => item.orgResName == firm);

      return await ReservationsAPINamespace.createReservations({
        accountResName: user?.accountResourceName ?? '',
        appResName: 'pending',
        assetResName: asset,
        reservationPeriod: {
          endTime: {
            epochTime: Date.parse(toDate),
          },
          startTime: {
            epochTime: Date.parse(fromDate),
          },
        },
        siteResName: site,
        status: 'active',
        user: {
          orgName: selectedCompany?.orgName,
          orgResName: selectedCompany?.orgResName,
          orgType: 'pending',
          userId: user?.userResourceName,
        },
        workflowResName: 'workflow-12345',
      });
    },

    mutationKey: [],

    onError: error => {
      console.error(error);
      setIsSubmitting(false);
      notifyService.error('Reservation create failed');
    },

    onSuccess: async successData => {
      if (typeRef.current == 'Draft') {
        setIsSubmitting(false);
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY.RESERVATION] });
        setIsClose();

        notifyService.success('Reservation create success');
      } else {
        const stateUpdate: updateReservationEvent = {
          accountResName: user?.accountResourceName ?? '',
          reservationResName: String(successData),
          siteResName: site,
          triggerEventType: RESERVATIONS.eventPendingSub,
          userResName: user?.userResourceName,
        };

        workFlowCall.mutateAsync(stateUpdate);
      }
    },
  });

  return { mutate, params, states };
}
