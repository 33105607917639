import { useQuery } from '@tanstack/react-query';

import { SiteOperationsAPINamespace } from '@/shared/api/site-operations';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

export const useGetSiteCalendars = () => {
  const uiStore = useUIStore();
  const { user } = useFetchUserData();
  const site = useUIStore(state => state.site);

  const getSiteCalendars = async () => {
    const calendars = await SiteOperationsAPINamespace.getSiteCalendars({
      accountResName: user?.accountResourceName as string,
      siteResName: site as string,
    });

    uiStore.setSiteCalendars(calendars);

    return calendars;
  };

  useQuery({
    enabled: !!user?.accountResourceName && !!user?.userResourceName,

    queryFn: getSiteCalendars,

    queryKey: [QUERY_KEY.SITE_CALENDARS_GET, user?.accountResourceName, user?.userResourceName],
  });
};
