import { useUIStore } from '@/shared/store/ui';

import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';

export const usePrismicLocale = () => {
  const currentLanguage = useUIStore(state => state.currentLanguage);

  const lang = currentLanguage === LocaleEnum.EN ? 'en-us' : 'ja-jp';

  return { lang };
};
