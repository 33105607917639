import { useQuery } from '@tanstack/react-query';

import { translate } from '@/i18n';
import { AssetsAPINamespace } from '@/shared/api/assets';
import { useAssetStatus } from '@/shared/hooks/assets/useAssetStatus.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { notifyService } from '@/shared/services/notify.service';
import { useGlobalStore } from '@/shared/store/global';
import { QUERY_KEY } from '@/shared/utils/constants';

import { ExtraPropertyKeys } from '@/shared/components/ui/Modal/Subcontractor/types';

export const useSetCompanies = () => {
  const setCompanies = useGlobalStore(store => store.setCompanies);
  const zpsUser = useGlobalStore(store => store.zpsUser);
  const { user } = useFetchUserData();
  const { isShowInactive } = useAssetStatus();

  useQuery({
    enabled: !!user?.accountResourceName,

    queryFn: async () => {
      const data = await AssetsAPINamespace.getAllOrganizations(
        user?.accountResourceName as string,
      );

      let firms = data;

      const role = zpsUser?.extraProperties?.find(p => p.key === ExtraPropertyKeys.role);

      if (
        zpsUser.orgResName &&
        role &&
        ['subcontractor', 'worker'].includes(role['value'].toLocaleLowerCase())
      ) {
        firms = data.filter(firm => firm.orgResName === zpsUser.orgResName);
        notifyService.success(translate('messages.workerSubscontractorAlert'), { duration: 5000 });
      }

      const companiesInUse = firms?.filter(asset => {
        return asset.status !== 'deleted';
      });

      const companiesToShow = companiesInUse.filter(company => {
        const show =
          company.status === 'active' || (company.status === 'inactive' && isShowInactive);
        return !!show;
      });

      setCompanies(companiesToShow, false);

      return companiesInUse;
    },

    queryKey: [QUERY_KEY.COMPANIES, user?.accountResourceName, zpsUser, isShowInactive],
  });
};
