import { FC } from 'react';
import { useToggle } from 'react-use';

import { useThree } from '@react-three/fiber';

import { ClusterPopup } from '@/modules/Home/components/map/ClusterPopup';
import {
  useAnchorPopupState,
  useMapModelDetails,
  useSmoothAnchorPosition,
} from '@/modules/Home/hooks/map';
import { CLUSTER_CONFIG } from '@/modules/Home/utils/MapModel.config';
import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';

import { ClusterProps } from '@/modules/Home/types/MapModel.types';

export const Cluster: FC<ClusterProps> = ({
  id,
  position,
  totalNumber,
  trackersData,
  workerPercentage,
}) => {
  const { camera } = useThree();
  const clearLocatedAnchor = useGlobalStore(state => state.clearLocatedAnchor);

  const [isOpened, togglePopup] = useToggle(false);
  const { anchorRef } = useSmoothAnchorPosition(position);

  const { setIsHovered } = useMapModelDetails();

  useAnchorPopupState(isOpened, togglePopup);

  const handleClosedPopup = () => {
    clearLocatedAnchor();
    togglePopup();
  };
  const siteId = useUIStore(state => state.site);
  const zoomLevel =
    CLUSTER_CONFIG.CLUSTER_ZOOM_LEVEL_CUSTOM[siteId] ?? CLUSTER_CONFIG.CLUSTER_ZOOM_LEVEL;

  if (camera.position.z >= zoomLevel)
    return (
      <mesh
        onPointerOut={() => setIsHovered(false)}
        onPointerOver={() => setIsHovered(true)}
        ref={anchorRef}
        renderOrder={-1} //NOSONAR
      >
        <ClusterPopup
          isOpened={isOpened}
          key={id}
          togglePopup={handleClosedPopup}
          totalNumber={totalNumber}
          trackersData={trackersData}
          workerPercentage={workerPercentage}
        />
      </mesh>
    );
};
