import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSessionStorage } from 'react-use';

import { useCurrentRoute } from '@/shared/hooks/global/useCurrentRoute.ts';
import { LS_KEYS } from '@/shared/utils/constants';

export const useLayoutOperations = () => {
  const { pathname } = useLocation();
  const [, setLocation] = useSessionStorage(LS_KEYS.LOCATION);
  const { isHomePage, isLoginPage } = useCurrentRoute();

  useEffect(() => {
    if (!isLoginPage) {
      setLocation(pathname);
    }
  }, [isLoginPage, pathname, setLocation]);

  return { isHomePage, pathname };
};
