import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ReservationsAPINamespace } from '@/shared/api/reservations/reservations.requests';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { notifyService } from '@/shared/services/notify.service';
import { useModalStore } from '@/shared/store/modal';
import { useUIStore } from '@/shared/store/ui';
import { QUERY_KEY } from '@/shared/utils/constants';

import { updateReservationEvent } from '@/shared/api/reservations/reservations.types';

export function useWorkflowEngine() {
  const { setIsClose, setIsSubmitting } = useModalStore(({ setIsClose, setIsSubmitting }) => ({
    setIsClose,
    setIsSubmitting,
  }));
  const { site } = useUIStore(({ site }) => ({ site }));

  const { user } = useFetchUserData();
  const queryClient = useQueryClient();
  const { mutateAsync, ...states } = useMutation({
    mutationFn: async (data: updateReservationEvent) => {
      setIsSubmitting(true);

      const { accountResName, reservationResName, siteResName, triggerEventType, userResName } =
        data;

      return await ReservationsAPINamespace.updateReservationState({
        accountResName: accountResName ?? user?.accountResourceName,
        reservationResName: reservationResName,
        siteResName: siteResName ?? site,
        triggerEventType: triggerEventType,
        userResName: userResName ?? user?.userResourceName,
      });
    },

    mutationKey: [],

    onError: async error => {
      console.error(error);
      setIsSubmitting(false);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY.RESERVATION] });
      notifyService.success('Reservation success');
    },

    onSuccess: async () => {
      setIsSubmitting(false);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY.RESERVATION] });
      setIsClose();
      notifyService.success('Reservation success');
    },
  });

  return { mutateAsync, states };
}
