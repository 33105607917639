import { FC, useEffect, useRef } from 'react';

import { OrbitControls } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { OrbitControls as OrbitControlsType } from 'three-stdlib';

import { CustomOrbitsControlProps } from '@/modules/Home/types/MapModel.types.ts';

export const CustomOrbitControls: FC<CustomOrbitsControlProps> = ({
  mapView,
  onUpdateMapCoords,
  popups,
  thumbnailMapCoords,
}) => {
  const { camera, gl } = useThree();
  const controls = useRef<OrbitControlsType>(null);

  useEffect(() => {
    if (thumbnailMapCoords?.target) {
      // Update camera position and rotation based on the main map model's OrbitControls
      camera.position.copy(thumbnailMapCoords.position);
      camera.rotation.copy(thumbnailMapCoords.rotation);
      camera.updateProjectionMatrix();

      controls?.current?.target.copy(thumbnailMapCoords.target);
    }
  }, [camera, thumbnailMapCoords]);

  useEffect(() => {
    const handleChange = () => {
      const orbitControls = controls.current;

      if (orbitControls && onUpdateMapCoords) {
        onUpdateMapCoords({
          position: orbitControls.object.position,
          rotation: orbitControls.object.rotation,
          scale: orbitControls.object.scale,
          target: orbitControls.target,
        });
      }
    };

    const currentControls = controls.current;

    if (currentControls) {
      currentControls.addEventListener('change', handleChange);
    }

    return () => {
      if (currentControls) {
        currentControls.removeEventListener('change', handleChange);
      }
    };
  }, [onUpdateMapCoords]);

  useEffect(() => {
    if (mapView && controls.current) {
      controls.current?.reset();
      camera.position.setZ(11);
      camera.updateProjectionMatrix();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapView]);

  return (
    <OrbitControls
      args={[camera, gl.domElement]}
      enabled={popups.length <= 0}
      enableDamping={false}
      enablePan={true}
      maxAzimuthAngle={1}
      maxDistance={20}
      maxPolarAngle={Math.PI / 2}
      minAzimuthAngle={-1}
      minDistance={1}
      minPolarAngle={Math.PI / 3}
      ref={controls}
    />
  );
};
