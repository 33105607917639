import { FC, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthLayout } from '@/layout/Auth';
import { ConnectionLayout } from '@/layout/Connection';
import { ErrorLayout } from '@/layout/Error';
import { Layout } from '@/layout/Layout';
import { SecureLayout } from '@/layout/Secure';
import { UpdaterLayout } from '@/layout/Updater';
import { Login } from '@/pages';
import { MFASetup } from '@/pages/Auth/MFASetup';
import { MobileAuth } from '@/pages/Auth/MobileAuth';
import { LazyRoutesNamespace } from '@/routes/lazy-app-routes';
import { useAlertsFeatureFlag } from '@/shared/feature-flags/useAlertsFeatureFlag.ts';
import { useReservationsControlFlag } from '@/shared/feature-flags/useReservationsControlFlag.ts';
import { useMixpanelConfigUpdate } from '@/shared/hooks/global/useMixpanelConfigUpdate.ts';
import { useScrollToTop } from '@/shared/hooks/global/useScrollToTop.ts';
import { newRelicService } from '@/shared/services/newrelic.service';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

newRelicService.init();

export const AppRoutes: FC = () => {
  const { alertsImplementation } = useAlertsFeatureFlag();
  const { reservationControlFlag } = useReservationsControlFlag();

  const [renderAlertsRoute, setRenderAlertsRoute] = useState(false);
  const [renderReservationsRoute, setRenderReservationsRoute] = useState(false);

  useScrollToTop();
  useMixpanelConfigUpdate();

  useEffect(() => {
    setRenderAlertsRoute(alertsImplementation?.enabled as boolean);
    setRenderReservationsRoute(reservationControlFlag.enabled);
  }, [alertsImplementation.enabled, reservationControlFlag.enabled]);

  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route element={<UpdaterLayout />}>
          <Route element={<SecureLayout />}>
            <Route element={<ConnectionLayout />}>
              <Route element={<Layout />} path={RoutesEnum.HOME}>
                <Route element={<LazyRoutesNamespace.Home />} index />

                {renderReservationsRoute && (
                  <Route
                    element={<LazyRoutesNamespace.Reservations />}
                    path={RoutesEnum.RESERVATIONS}
                  />
                )}

                <Route element={<LazyRoutesNamespace.Equipment />} path={RoutesEnum.EQUIPMENT} />
                <Route element={<LazyRoutesNamespace.Subcontractors />} path={RoutesEnum.PEOPLE} />
                <Route element={<LazyRoutesNamespace.Reports />} path={RoutesEnum.REPORTS} />

                {renderAlertsRoute && (
                  <Route element={<LazyRoutesNamespace.Actions />} path={RoutesEnum.ACTIONS} />
                )}

                <Route element={<LazyRoutesNamespace.Settings />} path={RoutesEnum.SETTINGS} />
                <Route element={<LazyRoutesNamespace.Releases />} path={RoutesEnum.RELEASES} />
                <Route element={<LazyRoutesNamespace.Licences />} path={RoutesEnum.LICENSES} />
              </Route>
            </Route>
          </Route>

          <Route element={<AuthLayout />}>
            <Route element={<Login />} path={RoutesEnum.LOGIN} />
            <Route element={<MFASetup />} path={RoutesEnum.MFA} />
            <Route element={<LazyRoutesNamespace.TermsOfUse />} path={RoutesEnum.TERMS} />
            <Route element={<LazyRoutesNamespace.SiteSelect />} path={RoutesEnum.SITE_SELECT} />
            <Route
              element={<LazyRoutesNamespace.RestorePassword />}
              path={RoutesEnum.RESTORE_PASSWORD}
            />
            <Route element={<MobileAuth />} path={RoutesEnum.MOBILE_AUTH} />
          </Route>
        </Route>
      </Route>

      <Route element={<LazyRoutesNamespace.ErrorPage />} path="*" />
    </Routes>
  );
};
