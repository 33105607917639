import { MISSING_DATA } from '@/shared/utils/constants';
import { generateId } from '@/shared/utils/helpers';

import { AuditLogDatasource } from '@/shared/types/reports/reports.types.ts';

export const auditLogsList: AuditLogDatasource[] = Array.from({
  length: 20,
}).map(_ => {
  return {
    actor: MISSING_DATA,
    details: MISSING_DATA,
    event: MISSING_DATA,
    id: generateId(),
    source: MISSING_DATA,
    timestamp: MISSING_DATA,
  };
});
