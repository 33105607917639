import { baseQuery, signRequest } from '@/shared/api/utils';
import { REQUESTS_OPTIONS } from '@/shared/utils/constants.ts';

import {
  AssetsCreateRequest,
  AssetsTagsUpdateRequest,
  AssetsUpdateRequest,
  OrganizationBodyRequest,
  OrganizationUpdateRequest,
  ZPSUserRequestBody,
  ZPSUserUpdateRequest,
} from '@/shared/api/assets/assets.types';
import {
  AssetBody,
  AssetCategory,
  AssetOrganization,
  AssetsResponse,
  AssetsTagsResponse,
  GeofenceOccupants,
} from '@/shared/types/assets/assets.types';
import {
  MinimizedTagBody,
  TagBody,
  UpdateAssetAttachedTagsRequest,
} from '@/shared/types/assets/tags.types.ts';
import { UserProps, ZPSUser } from '@/shared/types/users/user.types';

export namespace AssetsAPINamespace {
  const client = baseQuery('ASSETS');
  const userClient = baseQuery('USER');

  export const getAll = async (
    accountResName: string,
    siteResName: string,
    categoryTreeResourceName?: string,
  ): Promise<AssetsResponse> => {
    const renderCustomURL = () => {
      if (categoryTreeResourceName)
        return `/accounts/${accountResName}/sites/${siteResName}/assets?treeResName=${categoryTreeResourceName}&limit=${REQUESTS_OPTIONS.LIMIT_POINTS}`;

      return `/accounts/${accountResName}/sites/${siteResName}/assets`;
    };

    const signedRequest = await signRequest('ASSETS', renderCustomURL(), 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getWithDetails = async (
    results: AssetBody[],
    user: UserProps,
    site: string,
    visible: boolean,
  ): Promise<AssetBody[]> => {
    return await Promise.all(
      results.map(async asset => {
        try {
          const details = await AssetsAPINamespace.getOne({
            accountResName: user?.accountResourceName as string,
            assetResName: asset.assetResName,
            siteResName: site,
          });

          return {
            ...asset,
            ...(details.assetStatus && { assetStatus: details.assetStatus }),
            categoryPathResName: details.categoryPathResName,
            categoryTreeResName: details.categoryTreeResName,
            extraProperties: details.extraProperties,
            visible,
          };
        } catch {
          return asset;
        }
      }),
    );
  };

  export const getOne = async ({
    accountResName,
    assetResName,
    siteResName,
  }: {
    accountResName: string;
    siteResName: string;
    assetResName: string;
  }): Promise<AssetBody> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/${assetResName}`;
    const signedRequest = await signRequest('ASSETS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getAllTags = async (
    accountResName: string,
    siteResName: string,
  ): Promise<AssetsTagsResponse> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/tags/availabilities?limit=${REQUESTS_OPTIONS.LIMIT_POINTS}0&forward=true`;
    const signedRequest = await signRequest('ASSETS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  // 01.07 - unimplemented
  export const getAssetsGeofenceOccupants = async (
    accountResName: string,
    siteResName: string,
  ): Promise<GeofenceOccupants[]> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/enclosing-geofences?checkTime=${new Date(
      Date.now(),
    ).setMinutes(new Date(Date.now()).getMinutes() - 3)}`;
    const signedRequest = await signRequest('ASSETS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data.results;
  };

  export const getAllGroups = async (
    accountResName: string,
    siteResName: string,
  ): Promise<unknown[]> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/groups`;
    const signedRequest = await signRequest('ASSETS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getAllCategoryTress = async (
    accountResName: string,
    siteResName: string,
  ): Promise<AssetCategory[]> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/category-trees`;
    const signedRequest = await signRequest('ASSETS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getAllOrganizations = async (
    accountResName: string,
  ): Promise<AssetOrganization[]> => {
    const url = `/accounts/${accountResName}/organizations?statuses=active&limit=${REQUESTS_OPTIONS.LIMIT_POINTS}`;
    const signedRequest = await signRequest('USER', url, 'GET');

    const result = await userClient.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data.results;
  };

  export const getAssetAttachedTags = async (
    accountResourceName: string,
    siteResourceName: string,
    assetResourceName: string,
  ): Promise<MinimizedTagBody[]> => {
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/assets/${assetResourceName}/attached-tags`;
    const signedRequest = await signRequest('ASSETS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const updateAssetAttachedTags = async ({
    accountResourceName,
    assetResourceName,
    siteResourceName,
    ...body
  }: UpdateAssetAttachedTagsRequest): Promise<TagBody[]> => {
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/assets/${assetResourceName}/attached-tags`;
    const signedRequest = await signRequest('ASSETS', url, 'PUT', body.tags);

    const result = await client.put(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const createOne = async ({
    accountResName,
    siteResName,
    ...body
  }: AssetsCreateRequest): Promise<string> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets`;

    const signedRequest = await signRequest('ASSETS', url, 'POST', body);

    const result = await client.post(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const updateOne = async ({
    accountResName,
    assetResName,
    siteResName,
    ...body
  }: AssetsUpdateRequest): Promise<string> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/${assetResName}`;

    const signedRequest = await signRequest('ASSETS', url, 'PATCH', body);

    const result = await client.patch(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const attachTags = async ({
    accountResName,
    assetResName,
    siteResName,
    tags: body,
  }: AssetsTagsUpdateRequest): Promise<TagBody[]> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/${assetResName}/attached-tags`;

    const signedRequest = await signRequest('ASSETS', url, 'PUT', body);

    const result = await client.put(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const createOneOrganization = async ({
    accountResName,
    ...body
  }: OrganizationBodyRequest): Promise<string> => {
    const url = `/accounts/${accountResName}/organizations`;

    const signedRequest = await signRequest('USER', url, 'POST', body);

    const result = await userClient.post(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const updateOneOrganization = async ({
    accountResName,
    orgResName,
    ...body
  }: OrganizationUpdateRequest): Promise<AssetOrganization> => {
    const url = `/accounts/${accountResName}/organizations/${orgResName}`;

    const signedRequest = await signRequest('USER', url, 'PATCH', body);

    const result = await userClient.patch(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const createOneZPSUser = async ({
    accountResName,
    ...body
  }: ZPSUserRequestBody): Promise<string> => {
    const url = `/accounts/${accountResName}/users?limit=20&forward=true`;
    const signedRequest = await signRequest('USER', url, 'POST', body);

    const result = await userClient.post(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: {
        ...signedRequest.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return result.data;
  };

  export const getZPSUsers = async (accountResName: string): Promise<ZPSUser[]> => {
    const url = `/accounts/${accountResName}/users?limit=${REQUESTS_OPTIONS.LIMIT_POINTS}`;
    const signedRequest = await signRequest('USER', url, 'GET');

    const result = await userClient.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data.results;
  };

  export const getZPSUserDetails = async (
    accountResName: string,
    userResName: string,
  ): Promise<ZPSUser> => {
    const url = `/accounts/${accountResName}/users/${userResName}`;
    const signedRequest = await signRequest('USER', url, 'GET');

    const result = await userClient.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const updateOneZPSUser = async ({
    accountResName,
    userResName,
    ...body
  }: ZPSUserUpdateRequest): Promise<ZPSUser> => {
    const url = `/accounts/${accountResName}/users/${userResName}`;

    const signedRequest = await signRequest('USER', url, 'PATCH', body);

    const result = await userClient.patch(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };
}
