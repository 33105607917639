import { create } from 'zustand';

import { ModalProps } from '@/shared/store/modal/modal.types';
import { PopupTypeEnum } from '@/shared/types/global/enums.ts';

export const useModalStore = create<ModalProps>((set, _) => ({
  isDeleting: false,
  isOpen: false,
  isSubmitting: false,
  payload: null,
  setIsClose: () => set({ isOpen: false, payload: null, type: PopupTypeEnum.DEFAULT }),

  setIsDeleting: (status: boolean) => set({ isDeleting: status }),

  setIsOpen: (type: PopupTypeEnum, payload?: unknown) => set({ isOpen: true, payload, type }),

  setIsSubmitting: (status: boolean) => set({ isSubmitting: status }),

  type: PopupTypeEnum.DEFAULT,
}));
