import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { SiteMap } from '@/shared/api/maps/maps.types.ts';
import { ReservationsListResponse } from '@/shared/api/reservations/reservations.types';
import { GlobalProps } from '@/shared/store/global/global.types';
import {
  AssetBody,
  AssetCategory,
  AssetOrganization,
  AssetsTagsBody,
} from '@/shared/types/assets/assets.types.ts';
import { Tag } from '@/shared/types/assets/tags.types.ts';
import { RoutesEnum, StatusEnum } from '@/shared/types/global/enums.ts';
import {
  GeofenceCategoryEnum,
  GeofencesProps,
  Point,
} from '@/shared/types/global/geofences.types.ts';
import { LocationHistory } from '@/shared/types/global/locations.types.ts';
import { ReadersProps } from '@/shared/types/global/readers.types.ts';
import { SlicedZPSUser, ZPSUser } from '@/shared/types/users/user.types.ts';

export const useGlobalStore = create<GlobalProps>()(
  devtools(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (set, get) => ({
      assets: [],
      assetsCategories: [],

      assetsGroups: [],
      assetsOrganizations: [],

      assetsTags: [],
      batchedWorkers: [],

      clearLastLocations: () => {
        set({ lastLocations: [] });
      },
      clearLocatedAnchor: () => {
        set({ locatedAnchor: [] });
      },
      clearLocationsHistory: () => {
        set({
          locationsHistory: [],
        });
      },
      companies: [],
      currentCognitoUser: null,

      currentGeofenceId: '',
      currentGeofencePoints: [],
      currentGeofenceType: GeofenceCategoryEnum.INACTIVE,
      equipment: [],
      equipmentInUse: [],
      executionId: '',
      geofenceMapBlobUrl: '',
      geofenceMapBlobUrlList: {},
      geofences: [],
      isAssetsLoading: true,
      isCompanyLoading: true,

      isEquipmentInUseLoading: true,
      isEquipmentLoading: true,
      isGeofenceMapBlobUrlLoading: true,

      isGeofencesLoading: true,
      isLocationsHistoryLoading: true,

      isReadersLoading: true,
      isReservationLoading: true,
      isWorkersLoading: true,

      lastLocations: [],
      locatedAnchor: [],
      locationsHistory: [],

      mapPreview: null,
      modifyLastLocations: [],
      previousTab: RoutesEnum.HOME,
      readers: [],
      reservation: [],
      searchFieldValue: '',

      serModifyLastLocations: (lastLocations: LocationHistory[]) => {
        set({ modifyLastLocations: lastLocations });
      },
      setAssets: (
        assets: AssetBody[],
        assetsCategories: AssetCategory[],
        assetsOrganizations: AssetOrganization[],
        assetsGroups: unknown[],
        assetsTags,
        isLoading = false,
      ) => {
        set({
          assets,
          assetsCategories,
          assetsGroups,
          assetsOrganizations,
          assetsTags,
          isAssetsLoading: isLoading,
        });
      },
      setBatchedWorkers: (batchedWorkers: AssetBody[]) => {
        batchedWorkers.sort((w1, w2) => w1.assetName.localeCompare(w2.assetName));
        set({ batchedWorkers });
      },

      setCompanies: (companies: AssetOrganization[], isLoading = false) => {
        set({ companies, isCompanyLoading: isLoading });
      },
      setCurrentCognitoUser: user => {
        set({ currentCognitoUser: user });
      },
      setCurrentGeofence: (coordinates: Point[], type: GeofenceCategoryEnum) => {
        set({
          currentGeofencePoints: coordinates,
          currentGeofenceType: type,
        });
      },

      setCurrentGeofenceId: (id: string) => {
        set({
          currentGeofenceId: id,
        });
      },
      setEquipment: (equipment: AssetBody[], isLoading = false) => {
        equipment.sort((eq1, eq2) => eq1.assetName.localeCompare(eq2.assetName));
        set({ equipment, isEquipmentLoading: isLoading });
      },
      setEquipmentInUse: (equipment: AssetBody[], isLoading = false) => {
        equipment.sort((eq1, eq2) => eq1.assetName.localeCompare(eq2.assetName));
        set({ equipmentInUse: equipment, isEquipmentInUseLoading: isLoading });
      },
      setExecutionId: (executionId: string) => {
        set({ executionId });
      },

      setGeoFenceMapBlobUrl: value => {
        set({ geofenceMapBlobUrl: value, isGeofenceMapBlobUrlLoading: false });
      },
      setGeoFenceMapBlobUrlList: value => {
        set({ geofenceMapBlobUrlList: value });
      },
      setGeofenceMapBlobUrlLoading: loading => {
        set({ isGeofenceMapBlobUrlLoading: loading });
      },
      setGeofences: (geofences: GeofencesProps[], isLoading = false) => {
        geofences.sort((g1, g2) => g1.geofenceName.localeCompare(g2.geofenceName));
        const currentGeofenceResourceName = get().currentGeofenceId;

        const modifiedGeofences = geofences.map(geofence => {
          return {
            ...geofence,
            visible: geofence.geofenceResName === currentGeofenceResourceName,
          };
        });

        set({
          geofences: modifiedGeofences,
          isGeofencesLoading: isLoading,
        });
      },
      setGeofenceVisible: (id, visible, reset) => {
        if (!id) return;
        const visibleGeofenceIds: string[] = [];
        if (!reset) visibleGeofenceIds.push(...get().visibleGeofenceIds);

        if (visible && !visibleGeofenceIds.includes(id)) visibleGeofenceIds.push(id);
        else if (!visible) visibleGeofenceIds.splice(visibleGeofenceIds.indexOf(id), 1);
        set({ visibleGeofenceIds });
      },

      setLastLocations: (lastLocations: LocationHistory[]) => {
        set({ lastLocations });
      },
      setLocatedAnchor: (locatedAnchor: string) => {
        set(state =>
          state.locatedAnchor.includes(locatedAnchor)
            ? { locatedAnchor: state.locatedAnchor.filter(id => id !== locatedAnchor) }
            : { locatedAnchor: [...state.locatedAnchor, locatedAnchor] },
        );
      },
      setLocationsHistory: (locationsHistory: LocationHistory[], isLoading = false) => {
        set(state => ({
          isLocationsHistoryLoading: isLoading,
          locationsHistory: [...state.locationsHistory, ...locationsHistory],
        }));
      },
      setMapPreview: value => {
        set({ mapPreview: value });
      },
      setPreviousTab: (value: RoutesEnum) => {
        set({
          previousTab: value,
        });
      },
      setReaders: (readers: ReadersProps[], isLoading = false) => {
        readers.sort((r1, r2) => r1.readerName.localeCompare(r2.readerName));
        set({ isReadersLoading: isLoading, readers });
      },
      setReservation: (reservation: ReservationsListResponse, isLoading = false) => {
        set({ isReservationLoading: isLoading, reservation });
      },

      setSearchFieldValue: value => {
        set({ searchFieldValue: value });
      },
      setSidebarFilterValue: (value: string) => {
        set({
          sidebarFilterValue: value,
        });
      },
      setSiteMapConfig: value => {
        set({ siteMapConfig: value });
      },

      setSiteMapList: (value: SiteMap[]) => {
        set({ siteMapList: value });
      },
      setSiteTagsList: (value: Tag[]) => {
        set({ siteTagsList: value });
      },

      setStatus: (status: StatusEnum) => set({ status }),
      setViewableCoordinates: (value: Array<number>) => {
        set({
          viewableCoordinates: value,
        });
      },

      setWebSocketUrl: (webSocketUrl: string) => {
        set({ webSocketUrl });
      },
      setWorkers: (workers: AssetBody[], isLoading = false) => {
        workers.sort((w1, w2) => w1.assetName.localeCompare(w2.assetName));
        set({ isWorkersLoading: isLoading, workers });
      },

      setWorkersInUse: (workers: AssetBody[]) => {
        workers.sort((w1, w2) => w1.assetName.localeCompare(w2.assetName));
        set({ workersInUse: workers });
      },
      setZPSUser: (zpsUser: ZPSUser) => {
        set({ zpsUser });
      },

      setZPSUsers: (zpsUsers: ZPSUser[]) => {
        set({ zpsUsers });
      },
      sidebarFilterValue: '',
      siteMapConfig: null,

      siteMapList: [],
      siteTagsList: [],
      status: StatusEnum.IDLE,
      toggleGeofenceVisible: id => {
        if (!id) return;
        const { setGeofenceVisible, visibleGeofenceIds } = get();
        setGeofenceVisible(id, !visibleGeofenceIds.includes(id));
      },
      updateAssets: (assets: AssetBody[]) => {
        set({ assets });
      },
      updateAssetsCategories: (assetsCategories: AssetCategory[]) => {
        set({ assetsCategories });
      },

      updateAssetsOrganizations: (assetsOrganizations: AssetOrganization[]) => {
        set({ assetsOrganizations });
      },
      updateAssetsTags: (assetsTags: AssetsTagsBody[]) => {
        set({ assetsTags });
      },

      updateZPSUsers: (zpsUsers: SlicedZPSUser[] | ZPSUser[]) => {
        set({ zpsUsers: zpsUsers as unknown as ZPSUser[] });
      },
      viewableCoordinates: [],

      visibleGeofenceIds: [],
      webSocketUrl: '',

      workers: [],
      workersInUse: [],
      zpsUser: {} as ZPSUser,
      zpsUsers: [],
    }),
    {
      name: 'Global Store',
    },
  ),
);
