import { CognitoUserAttributesProps } from '@/shared/types/auth/cognito-user-pool.types.ts';
import { Inputs } from '@/shared/types/global/common.types.ts';
import { RoutesEnum } from '@/shared/types/global/enums.ts';

//* Normalize pathname
export const normalizedPathname = (pathname: string): string | undefined => {
  if (pathname === RoutesEnum.HOME) return 'Map';

  return pathname.slice(1).slice(0, 1).toUpperCase() + pathname.slice(2);
};

//* Generate random id
// export const generateId = (): string => crypto.randomUUID();
export const generateId = (): string => Date.now().toString();

//* Get file extension
export const getBase64 = (file: File, setImage: (data: string) => void) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = function () {
    setImage(reader.result as string);
  };

  reader.onerror = function (error) {
    console.error('ErrorPageWrapper:', error);
  };
};

//* Use to get the value of the input
export const onlyNumbers = (value: string) => {
  if (Number.isNaN(value)) return '';

  return value.replace(/[^\d.-]/g, '');
};

//* Get attributes list for Cognito
export const attributesList = (data: Inputs) =>
  Object.entries(data).reduce((acc: CognitoUserAttributesProps, [key, value]) => {
    if (value) {
      acc.push({
        Name: key,
        Value: value,
      });
    }

    return acc;
  }, [] as CognitoUserAttributesProps);

//* Split string with upper case letters
export const splitWithUpperCase = (value: string): string => {
  return value?.split(/(?=[A-Z])/).join(' ');
};

//* Generate HEX color from user id
export const generateHexFromUserId = (id: string): string => {
  const color = id?.slice(0, 6);

  return `#${color}`;
};
