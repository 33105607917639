import { ElementType, FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Tab as T, TabGroupProps } from '@headlessui/react';

import { classnames } from '@/shared/utils/classnames';

import { WithChildren, WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithChildren & WithClassName;

export const Group: FC<WithChildren & TabGroupProps<ElementType>> = ({ children, ...props }) => {
  return <T.Group {...props}>{children}</T.Group>;
};

const Wrapper: FC<WithChildren> = ({ children }) => {
  return (
    <div className="overflow-x relative w-full after:absolute after:-bottom-[0.2px] after:left-0 after:z-0 after:block after:h-0.5 after:w-full after:bg-slate-200 after:content-['']">
      {children}
    </div>
  );
};

const List: FC<
  Props & {
    withWrapper?: boolean;
  }
> = ({ children, className, withWrapper }) => {
  return (
    <T.List
      className={classnames(
        'text-sm font-medium uppercase text-slate-800 pb-2 xl:p-0',
        {
          'overflow-x relative z-10 w-full flex-nowrap': withWrapper,
        },
        className,
      )}
    >
      {children}
    </T.List>
  );
};

const Tab: FC<
  Props & {
    disabled?: boolean;
    withCounter?: boolean;
    onClick?: () => void;
  }
> = ({ children, className, disabled, onClick, withCounter = false }) => {
  const [_, setSearchParameters] = useSearchParams();

  const onParamsReset = () => setSearchParameters(new URLSearchParams());

  return (
    <T
      className={({ selected }) =>
        classnames(
          'relative border-b-2 py-2 pr-3 tracking-wider transition-colors duration-300 first-letter:capitalize hover:text-blue-600 focus:text-blue-600 focus:outline-none md:px-3',
          {
            'border-blue-600 !text-blue-600': selected,
            'border-slate-200': !selected,
            'pointer-events-none opacity-50': disabled,
            'pr-5': withCounter,
          },
          className,
        )
      }
      disabled={disabled}
      onClick={() => {
        onClick && onClick();
        onParamsReset();
      }}
    >
      {children}
    </T>
  );
};

const CanvasTab: FC<
  Props & {
    disabled?: boolean;
    withCounter?: boolean;
    onClick?: () => void;
  }
> = ({ children, className, disabled, onClick, withCounter = false }) => {
  return (
    <T
      className={({ selected }) =>
        classnames(
          'relative border-b-2 px-3 py-2 tracking-wider transition-colors duration-300 first-letter:capitalize hover:text-blue-600 focus:text-blue-600',
          {
            'border-blue-600 text-blue-600': selected,
            'border-slate-200': !selected,
            'pointer-events-none opacity-50': disabled,
            'pr-5': withCounter,
          },
          className,
        )
      }
      disabled={disabled}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {children}
    </T>
  );
};

const ActionsTab: FC<
  Props & {
    disabled?: boolean;
  }
> = ({ children, className, disabled, ...props }) => {
  return (
    <T
      className={({ selected }) =>
        classnames(
          'inline-flex items-center gap-2 rounded-lg px-3 py-2 text-sm transition-colors duration-300 first-letter:capitalize',
          {
            'bg-blue-600 !text-white': selected,
            'bg-transparent': !selected,
            'pointer-events-none opacity-50': disabled,
          },
          className,
        )
      }
      {...props}
    >
      {children}
    </T>
  );
};

const TabPanels: FC<Props> = ({ children, className }) => {
  return <T.Panels className={classnames('p-2', className)}>{children}</T.Panels>;
};

const TabPanel: FC<Props> = ({ children, className }) => {
  return <T.Panel className={classnames(className)}>{children}</T.Panel>;
};

export const Tabs = {
  ActionsTab,
  CanvasTab,
  Group,
  List,
  Tab,
  TabPanel,
  TabPanels,
  Wrapper,
};
