export namespace WebAPIStorageNamespace {
  // Local storage API
  export namespace LocalStorageService {
    export const setLocalItem = <T>(key: string, value: T, json = true) => {
      try {
        if (json) {
          const serializedValue = JSON.stringify(value);
          localStorage.setItem(key, serializedValue);
        } else localStorage.setItem(key, value as never);
      } catch (error) {
        console.error('Set state error:', error);
      }
    };

    export const getLocalItem = (key: string) => {
      try {
        return localStorage.getItem(key);
      } catch (error) {
        console.error('Set state error:', error);
      }
    };

    export const getLocalItemParsed = (key: string) => {
      try {
        const serializedState = localStorage.getItem(key);
        return serializedState === null ? undefined : JSON.parse(serializedState);
      } catch (error) {
        console.error('Set state error:', error);
      }
    };

    export const removeLocalItem = (key: string) => {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error('Set state error:', error);
      }
    };

    export const clearLocalItems = () => {
      try {
        localStorage.clear();
      } catch (error) {
        console.error('Set state error:', error);
      }
    };
  }

  // Session storage API
  export namespace SessionService {
    export const setSessionItem = <T>(key: string, value: T, json = true) => {
      try {
        if (json) {
          const serializedValue = JSON.stringify(value);
          sessionStorage.setItem(key, serializedValue);
        } else sessionStorage.setItem(key, value as never);
      } catch (error) {
        console.error('Set state error:', error);
      }
    };

    export const getSessionItem = (key: string) => {
      try {
        return sessionStorage.getItem(key);
      } catch (error) {
        console.error('Set state error:', error);
      }
    };

    export const getSessionItemParsed = (key: string) => {
      try {
        const serializedState = sessionStorage.getItem(key);
        return serializedState === null ? undefined : JSON.parse(serializedState);
      } catch (error) {
        console.error('Set state error:', error);
      }
    };

    export const removeSessionItem = (key: string) => {
      try {
        sessionStorage.removeItem(key);
      } catch (error) {
        console.error('Set state error:', error);
      }
    };

    export const clearSessionItems = () => {
      try {
        sessionStorage.clear();
      } catch (error) {
        console.error('Set state error:', error);
      }
    };
  }
}
