import { useMemo } from 'react';

import { useModifyAlertsData } from '@/modules/Actions/hooks/useModifyAlertsData.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useAlertsStore } from '@/shared/store/actions';
import { useGlobalStore } from '@/shared/store/global';
import { findTagValue } from '@/shared/utils/assets.ts';
import { MAX_COUNTER } from '@/shared/utils/constants.ts';

import {
  ActionCategoryEnum,
  ActionTitleEnum,
  AlertProps,
  MetricProps,
} from '@/shared/types/actions/actions.types.ts';
import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { RolesEnum } from '@/shared/types/global/enums.ts';
import { ReadersProps } from '@/shared/types/global/readers.types.ts';
import { UserChildProps } from '@/shared/types/users/user.types.ts';

export const useActionsCounter = () => {
  const { user } = useFetchUserData();

  const assets = useGlobalStore(store => store.assets);
  const readers = useGlobalStore(store => store.readers);
  const alertsData = useAlertsStore(store =>
    store.alertsData.filter(
      action =>
        actionHasAssetOrReader({ action, assets, readers, user }) &&
        actionIsAccessibleByRole(action, user),
    ),
  );
  const archivedAlertsData = useAlertsStore(store =>
    store.archivedAlertsData.filter(action =>
      actionHasAssetOrReader({ action, assets, readers, user }),
    ),
  );
  const metricsData = useAlertsStore(store =>
    store.metricsData.filter(action => actionHasAssetOrReader({ action, assets, readers, user })),
  );
  const isAlertsDataLoading = useAlertsStore(store => store.isAlertsDataLoading);
  const isMetricsDataLoading = useAlertsStore(store => store.isMetricsDataLoading);

  const actionsData = { alertsData, metricsData };

  const countForBadge = useMemo((): number | string => {
    const totalData = [...alertsData, ...metricsData];

    if (totalData.length > MAX_COUNTER) return `${MAX_COUNTER}+`;

    return totalData.length;
  }, [alertsData, metricsData]);

  const filterAlerts = (category: ActionCategoryEnum) => {
    return actionsData.alertsData.filter(item => item.alertCategory === category);
  };

  const { modifiedAlertsData } = useModifyAlertsData();

  const metricsResult = modifiedAlertsData(actionsData.metricsData) ?? [];
  const systemAlertsResult = modifiedAlertsData(filterAlerts(ActionCategoryEnum.SYSTEM)) ?? [];
  const safetyAlertsResult = modifiedAlertsData(filterAlerts(ActionCategoryEnum.SAFETY)) ?? [];
  const equipmentAlertsResult =
    modifiedAlertsData(filterAlerts(ActionCategoryEnum.EQUIPMENT)) ?? [];
  const zoneAlertsResult = modifiedAlertsData(filterAlerts(ActionCategoryEnum.ZONE)) ?? [];
  const reservationAlertsResult =
    modifiedAlertsData(filterAlerts(ActionCategoryEnum.RESERVATION)) ?? [];
  const archivedAlertsResult = modifiedAlertsData(archivedAlertsData) ?? [];

  const counts = {
    all: alertsData.length + metricsData.length,
    archived: archivedAlertsData.length,
    equipment: equipmentAlertsResult.length,
    forBadge: countForBadge,
    reservation: reservationAlertsResult.length,
    safety: safetyAlertsResult.length,
    system: systemAlertsResult.length,
    zones: zoneAlertsResult.length,
  };

  const isActionsDataLoading = isAlertsDataLoading || isMetricsDataLoading;

  const hasPriority = actionsData?.alertsData.length > 0;

  const isActionsDataEmpty =
    actionsData.alertsData.length <= 0 && actionsData.metricsData.length <= 0;

  return {
    actionsData,
    archivedAlertsResult,
    counts,
    equipmentAlertsResult,
    hasPriority,
    isActionsDataEmpty,
    isActionsDataLoading,
    metricsResult,
    reservationAlertsResult,
    safetyAlertsResult,
    systemAlertsResult,
    zoneAlertsResult,
  };
};

function actionHasAssetOrReader(args: {
  action: AlertProps | MetricProps;
  assets: AssetBody[];
  readers: ReadersProps[];
  user?: null | Partial<UserChildProps>;
}) {
  if (!args.user?.role) return false;

  const itemIsAlert =
    Object.prototype.hasOwnProperty.call(args.action, 'alertResName') ||
    Object.prototype.hasOwnProperty.call(args.action, 'alertName');

  if (itemIsAlert) {
    const alert = args.action as AlertProps;

    if (alert.alertCategory === ActionCategoryEnum.EQUIPMENT && args.user.role === RolesEnum.KOTO) {
      return false;
    }

    const alertAssetExists = args.assets.some(asset => {
      return (
        asset.assetResName === alert.assetResName || alert.deviceResName === findTagValue(asset)
      );
    });

    const alertReaderExists = args.readers.some(reader => {
      return alert.deviceResName === reader.readerResName;
    });

    return alertAssetExists || alertReaderExists;
  } else {
    // TODO: Change logic during full metrics implementation
    return false;
  }
}

function actionIsAccessibleByRole(
  action: AlertProps | MetricProps,
  user?: null | Partial<UserChildProps>,
) {
  if (!user?.role) return false;

  const accessMatrix: {
    [k in ActionTitleEnum]: {
      [v in RolesEnum]: boolean;
    };
  } = {
    [ActionTitleEnum.CRITICAL_BATTERY]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: true,
    },
    [ActionTitleEnum.DEAD_BATTERY]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: true,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: true,
    },
    [ActionTitleEnum.DEVICE_UNRESPONSIVE]: {
      [RolesEnum.ENGINEER]: false,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: false,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.EQUIPMENT_ENTERED_RED_ZONE]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.EQUIPMENT_IN_USE_BY_WRONG_SUB_FIRM]: {
      [RolesEnum.ENGINEER]: false,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: false,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.EQUIPMENT_IN_USE_WITHOUT_RESERVATION]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: true,
    },
    [ActionTitleEnum.EQUIPMENT_NOT_UTILIZED_ON_WORKDAY]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.EQUIPMENT_REDZONE_ACTIVATED]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: true,
    },
    [ActionTitleEnum.EQUIPMENT_REDZONE_RELEASED]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: true,
    },
    [ActionTitleEnum.EQUIPMENT_RESERVATION_APPROVED]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.EQUIPMENT_RESERVATION_CANCELLED]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.EQUIPMENT_RESERVATION_EDITED]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.EQUIPMENT_RESERVATION_REQUEST]: {
      [RolesEnum.ENGINEER]: false,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: false,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.EQUIPMENT_RESERVATION_SUB_REQUEST]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.EQUIPMENT_RESERVATION_SUBCONTRACTOR_REQUEST_APPROVED]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: true,
    },
    [ActionTitleEnum.EQUIPMENT_RESERVATION_SUBCONTRACTOR_REQUEST_REJECTED]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: true,
    },
    [ActionTitleEnum.EQUIPMENT_RESERVATION_WORKER_REQUEST]: {
      [RolesEnum.ENGINEER]: false,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: false,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.EQUIPMENT_RESERVED_NOTINUSE]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.EQUIPMENT_STILL_IN_USE_AFTER_RESERVATION_ENDS]: {
      [RolesEnum.ENGINEER]: false,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: false,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.LOW_BATTERY]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: true,
    },
    [ActionTitleEnum.NEW_RESERVATION]: {
      [RolesEnum.ENGINEER]: false,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: false,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.NO_UPCOMING_EQUIPMENT_RESERVATION]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.READER_OFFLINE]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.READER_ONLINE]: {
      [RolesEnum.ENGINEER]: false,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.RESERVATION_APPROVED_WITH_CHANGES]: {
      [RolesEnum.ENGINEER]: false,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: false,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.RESERVATION_CONFLICT]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.RESERVATION_DRAFT_EXPIRED]: {
      [RolesEnum.ENGINEER]: false,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: false,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.RESERVATION_REQUEST_EXPIRED]: {
      [RolesEnum.ENGINEER]: false,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: false,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.TAG_LEFT_SITE]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.TAG_OFFLINE]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: true,
    },
    [ActionTitleEnum.TAG_ONLINE]: {
      [RolesEnum.ENGINEER]: false,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: false,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.WORKER_REDZONE_ENTERED]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: true,
      [RolesEnum.WORKER]: true,
    },
    [ActionTitleEnum.WORKERS_LEFT_SITE_FOR_DAY]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
    [ActionTitleEnum.WORKERS_STILL_ON_SITE_AFTER_5PM]: {
      [RolesEnum.ENGINEER]: true,
      [RolesEnum.KOTO]: false,
      [RolesEnum.MANAGER]: true,
      [RolesEnum.SUBCONTRACTOR]: false,
      [RolesEnum.WORKER]: false,
    },
  };

  const itemIsAlert =
    Object.prototype.hasOwnProperty.call(action, 'alertResName') ||
    Object.prototype.hasOwnProperty.call(action, 'alertName');

  if (itemIsAlert) {
    const alert = action as AlertProps;
    return accessMatrix[alert.alertName as ActionTitleEnum]?.[user.role] ?? false;
  } else {
    // TODO: Change logic during full metrics implementation
    return false;
  }
}
