import { queryOptions, useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets/assets.requests.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';
import { QUERY_KEY, REQUESTS_OPTIONS } from '@/shared/utils/constants.ts';

import { AssetTypeEnum } from '@/modules/Home/types/MapModel.types.ts';
import { AssetsFullDetailsParams } from '@/shared/api/assets/assets.types.ts';
import { AssetBody, AssetCategory, AssetsResponse } from '@/shared/types/assets/assets.types.ts';

const getCategoryTree = (categories: AssetCategory[], filter: AssetTypeEnum) => {
  return categories.find(category => category.categoryTreeName.includes(filter))
    ?.categoryTreeResName;
};

export namespace useAssetHooks {
  export const getAllEquipments = () => {
    const { data: assetsCategories = [] } = getCategoryTrees('onlyEquipment');

    const site = useUIStore(store => store.site);
    const equipmentVisibility = useUIStore(state => state.equipmentVisibility);

    const { user } = useFetchUserData();

    const categories: string[] = assetsCategories.map(item => item.categoryTreeResName);

    return useQueries({
      combine: (
        results: UseQueryResult<AssetsResponse, Error>[],
      ): {
        data: AssetBody[];
        isLoading: boolean;
        isSuccess: boolean;
        isError: boolean;
        isPending: boolean;
      } => {
        return {
          data: results
            .filter(result => result?.data?.results)
            .flatMap(result => {
              return result?.data?.results.map(equipment => ({
                ...equipment,
                visible: equipmentVisibility,
              }));
            }) as AssetBody[],
          isError: results.some(result => result.isError),
          isLoading: results.some(result => result.isLoading),
          isPending: results.some(result => result.isPending),
          isSuccess: results.every(result => result.isSuccess),
        };
      },

      queries: categories.map(category => ({
        enabled: !!user?.accountResourceName && !!site && assetsCategories.length > 0,

        queryFn: async () => {
          const params: AssetsFullDetailsParams = {
            includeTags: true,
            limit: REQUESTS_OPTIONS.LIMIT_POINTS,
            statuses: ['active', 'inactive'],
            treeResName: category,
          };

          return await AssetsAPINamespace.getAllWithDetails({
            accountResName: user?.accountResourceName as string,
            params,
            siteResName: site,
          });
        },

        queryKey: [QUERY_KEY.EQUIPMENTS_GET, category, user?.accountResourceName, site],
      })),
    });
  };

  export const getAllWorkers = () => {
    const { data: assetsCategories = [] } = getCategoryTrees('onlyWorker');

    const site = useUIStore(store => store.site);
    const workersVisibility = useUIStore(state => state.workerVisibility);

    const { user } = useFetchUserData();

    const currentCategory = getCategoryTree(assetsCategories, AssetTypeEnum.Worker);

    const workerQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!site && assetsCategories.length > 0,

      queryFn: async () => {
        const params: AssetsFullDetailsParams = {
          includeTags: true,
          limit: REQUESTS_OPTIONS.LIMIT_POINTS,
          statuses: ['active', 'inactive'],
          treeResName: currentCategory,
        };

        return await AssetsAPINamespace.getAllWithDetails({
          accountResName: user?.accountResourceName as string,
          params,
          siteResName: site,
        });
      },

      queryKey: [QUERY_KEY.WORKERS, user?.accountResourceName, site, currentCategory],

      select: data => {
        return data.results.map(worker => ({
          ...worker,
          visible: workersVisibility,
        }));
      },
    });

    return useQuery(workerQueryOptions);
  };

  export const getCategoryTrees = (filter?: 'onlyEquipment' | 'onlyWorker') => {
    const site = useUIStore(state => state.site);

    const { user } = useFetchUserData();

    const assetsCategoriesQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!site,

      queryFn: async () => {
        return await AssetsAPINamespace.getAllCategoryTress(
          user?.accountResourceName as string,
          site,
        );
      },

      queryKey: [QUERY_KEY.ASSETS_CATEGORIES, user?.accountResourceName, site],

      select: data => {
        const categories = data.map(item => {
          const modifiedItemPaths = item.categoryPathList.map(pathItem => {
            return {
              ...pathItem,
              extended: false,
            };
          });

          return {
            ...item,
            categoryPathList: modifiedItemPaths,
            extended: true,
          };
        });

        if (filter === 'onlyWorker') {
          return categories.filter(category =>
            category.categoryTreeName.includes(AssetTypeEnum.Worker),
          );
        } else if (filter === 'onlyEquipment') {
          return categories.filter(category =>
            category.categoryTreeName.includes(AssetTypeEnum.Equipment),
          );
        } else {
          return categories;
        }
      },
    });

    return useQuery(assetsCategoriesQueryOptions);
  };
}
