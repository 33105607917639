export enum RoutesEnum {
  ACTIONS = '/actions',
  EQUIPMENT = '/equipment',
  HOME = '/',
  LICENSES = '/licenses',
  LOGIN = '/login',
  MFA = '/mfa',
  MOBILE_AUTH = '/mobile-auth',
  PEOPLE = '/people',
  RELEASES = '/releases',
  REPORTS = '/reports',
  RESERVATIONS = '/reservations',
  RESTORE_PASSWORD = '/restore-password',
  SETTINGS = '/settings',
  SITE_SELECT = '/site-select',
  TERMS = '/terms',
}

export enum NotifyEnum {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
}

export enum RolesEnum {
  ENGINEER = 'engineer',
  KOTO = 'koto',
  MANAGER = 'manager',
  SUBCONTRACTOR = 'subcontractor',
  WORKER = 'worker',
}

export enum StatusEnum {
  ERROR = 'error',
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
}

export enum ReservationStatusEnum {
  ACTIVE = 'active',
  APPROVED = 'approved',
  COMPLETED = 'completed',
  INACTIVE = 'inactive',
  REJECTED = 'rejected',
  REQUESTED = 'requested',
}

export enum AvailabilityEnum {
  AVAILABLE = 'Available',
  RESERVED = 'Reserved',
}

export enum PopupTypeEnum {
  ALERTS = 'alerts',
  CONFIRM_DELETE = 'confirm_delete',
  CONFIRM_DELETE_FIRM = 'confirm_delete_firm',
  CONFIRM_DELETE_WORKER = 'confirm_delete_worker',
  CONFIRM_SAVE = 'confirm_save',
  DEFAULT = 'default',
  EQUIPMENT = 'equipment',
  EQUIPMENT_CREATE = 'equipment_create',
  EQUIPMENT_DELETE = 'equipment_delete',
  EQUIPMENT_EDIT = 'equipment_edit',
  EQUIPMENT_RESERVE = 'equipment_reserve',
  GROUPS_CREATE = 'groups_create',
  GROUPS_DELETE = 'groups_delete',
  GROUPS_EDIT = 'groups_edit',
  MAP = 'map',
  MAP_FILTER = 'map_filter',
  PEOPLES = 'subcontractors',
  REPORTS = 'reports',
  REQUESTS = 'requests',
  RESERVATIONS = 'reservations',
  RESERVATIONS_CONFIRM_APPROVE = 'reservations_confirm_approve',
  RESERVATIONS_CONFIRM_DELETE = 'reservations_confirm_delete',
  RESERVATIONS_CONFIRM_REJECT = 'reservations_confirm_reject',
  RESERVATIONS_CONFIRM_SUBMIT = 'reservations_confirm_submit',
  SETTINGS = 'settings',
  SUBCONTRACTORS_FIRM_EDIT = 'subcontractors_firm_edit',
  SUBCONTRACTORS_INVITE = 'subcontractors_invite',
  SUBCONTRACTORS_WORKER_EDIT = 'subcontractors_worker_edit',
  TERMS_OF_USE = 'terms_of_use',
  ZONE = 'zone',
}

export enum SidebarViewEnum {
  ADD_ZONE = 'add_zone',
  EDIT = 'edit',
  EDIT_ZONE = 'edit_zone',
  VIEW = 'view',
}
