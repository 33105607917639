import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { LS_KEYS } from '@/shared/utils/constants';

import { SiteResultProps } from '@/shared/api/sites/sites.types.ts';
import { UiStoreProps } from '@/shared/store/ui/ui.types';
import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';
import {
  CurrencyEnum,
  DisplayTimeZoneEnum,
  SystemViewEnum,
  TimeFormatEnum,
} from '@/shared/types/settings/system.types.ts';

export const useUIStore = create<UiStoreProps>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (set, get) => ({
        clearTagsId: () => {
          set({ tagsId: [] });
        },
        currency: CurrencyEnum.USD,

        currentLanguage: LocaleEnum.EN,
        displayTimeZone: DisplayTimeZoneEnum.SITE,

        distance: 5,
        endTime: '',

        equipmentVisibility: true,
        floor: null,

        floorsList: [],
        isCanvasPopupsOpen: false,

        isCellCopyTipActive: true,
        isShowInactive: false,

        mapFilter: {
          assetItem: [],
          equipmentReservedBy: [],
          equipmentSubType: [],
          equipmentType: [],
          firm: [],
          group: [],
          resName: [],
          searchKey: '',
          tag: [],
          workerRole: [],
          zoneFloor: [],
          zoneItem: [],
          zoneType: [],
        },
        openedViewPanel: [],

        readerVisibility: false,
        setCurrency: (currency: CurrencyEnum) => {
          set({ currency });
        },

        setCurrentLanguage: (location: LocaleEnum) => {
          set({ currentLanguage: location });
        },
        setDisplayTimeZone: value => {
          set({ displayTimeZone: value });
        },

        setDistance: distance => {
          set({ distance });
        },
        setEndTime: value => {
          set({ endTime: value });
        },

        setEquipmentVisibility: value => {
          set({ equipmentVisibility: value });
        },
        setFloor: floor => {
          set({ floor });
        },

        setFloorsList: floorsList => {
          set({ floorsList });
        },
        setIsCanvasPopupsOpen: (value: boolean) => {
          set({ isCanvasPopupsOpen: value });
        },

        setIsCellCopyTipActive: value => {
          set({ isCellCopyTipActive: value });
        },
        setIsShowInactive: (isShowInactive: boolean) => {
          set({ isShowInactive });
        },

        setMapFilter: filter => {
          const mapFilter = get().mapFilter;
          set({ mapFilter: { ...mapFilter, ...filter } });
        },
        setOpenedViewPanel: (openedViewPanel: string[]) => {
          set({ openedViewPanel });
        },

        setReaderVisibility: value => {
          set({ readerVisibility: value });
        },
        setSite: (site: string) => {
          set({ site });
        },

        setSiteCalendars: siteCalendars => {
          set({ siteCalendars });
        },
        setSitePolicies: sitePolicies => {
          set({ sitePolicies });
        },

        setSiteShifts: siteShifts => {
          set({ siteShifts });
        },
        setSitesList: (sitesList: SiteResultProps[]) => {
          set({ sitesList });
        },

        setSiteTimeZone: value => {
          set({ siteTimeZone: value });
        },
        setStartTime: value => {
          set({ startTime: value });
        },

        setSystemView: (view: SystemViewEnum) => {
          set({ systemView: view });
        },
        setTagId: (tagId: string) => {
          set(state =>
            state.tagsId.includes(tagId)
              ? { tagsId: state.tagsId.filter(id => id !== tagId) }
              : { tagsId: [...state.tagsId, tagId] },
          );
        },

        setTagIds: (tagsId: string[]) => {
          set({ tagsId });
        },
        setTimeFormat: value => {
          set({ timeFormat: value });
        },

        setUserSettings: settings => {
          set({ userSettings: settings });
        },
        setWorkerVisibility: value => {
          set({ workerVisibility: value });
        },

        site: '',
        siteCalendars: [],
        sitePolicies: [],
        siteShifts: [],

        sitesList: [],
        siteTimeZone: '',

        startTime: '',
        systemView: SystemViewEnum.METRIC,

        tagsId: [],
        timeFormat: TimeFormatEnum.H24,
        userSettings: [],
        workerVisibility: true,
      }),
      {
        name: LS_KEYS.UI_STORE,
      },
    ),
    {
      name: 'UI Store',
    },
  ),
);
