import { ColumnsProps } from '@/modules/Subcontractors/utils/Groups.utils';
import { MISSING_DATA } from '@/shared/utils/constants';
import { generateId } from '@/shared/utils/helpers';

export const groupsList: ColumnsProps[] = Array.from({ length: 5 }).map(_ => {
  return {
    id: generateId(),
    name: MISSING_DATA,
    workersCount: MISSING_DATA,
  };
});
