import { MISSING_DATA } from '@/shared/utils/constants';

export const groups = Array.from({ length: 1 }).map((_, index) => {
  return {
    additionalProp1: {},
    categoryPathList: [],
    categoryTreeName: MISSING_DATA,
    categoryTreeResName: `ctree-site-equipment-${index}`,
    categoryTreeStatus: MISSING_DATA,
    creationTime: '',
    lastUpdateTime: '',
  };
});
