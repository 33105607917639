import { useCallback, useEffect, useMemo, useRef } from 'react';

import { usePopupStore } from '@/shared/store/popup';
import { classnames } from '@/shared/utils/classnames';

import { PopupState } from '@/shared/store/popup/popup.types';

export const Popup = ({ index, popup }: { popup: PopupState; index: number }) => {
  const {
    backdrop,
    children,
    clientAxis,
    currentTarget,
    disableClickOutside,
    id,
    isOpen,
    popover,
    position,
  } = popup;

  const detectedHeight = window.innerHeight;
  const detectedWidth = window.innerWidth;

  const popupRef = useRef<HTMLDivElement | null>(null);

  const closePopup = usePopupStore(store => store.closePopup);

  const handleClose = useCallback(() => {
    closePopup(id);
  }, [closePopup, id]);

  const targetRect = useMemo(
    () => (currentTarget instanceof HTMLElement ? currentTarget.getBoundingClientRect() : null),
    [currentTarget],
  );

  const getPosition = () => {
    const popupElementWidth = popover.width;
    const popupElementHeight = popover.height;
    const maxHeight = detectedHeight - popupElementHeight - 16;

    const calculatePosition = (x: number, y: number) => {
      const calculateX = () => {
        const halfPopupWidth = popupElementWidth / 2;
        const minX = 10;
        const maxX = detectedWidth - popupElementWidth - 10;
        return Math.max(minX, Math.min(x - halfPopupWidth, maxX));
      };

      const calculateY = (offsetY: number) => {
        const minY = 10;
        return Math.max(minY, Math.min(y + offsetY, maxHeight));
      };

      switch (position) {
        case 'bottom':
          return {
            x: calculateX(),
            y: y + 10 > maxHeight ? maxHeight : y + 10,
          };
        case 'left':
          return {
            x: x - popupElementWidth < 0 ? 10 : x - popupElementWidth - 10,
            y: calculateY(0),
          };
        case 'right':
          return {
            x:
              x + popupElementWidth > detectedWidth
                ? detectedWidth - popupElementWidth - 10
                : x + 10,
            y: calculateY(0),
          };
        case 'top':
          return {
            x: calculateX(),
            y: y - popupElementHeight - 10 < 0 ? 10 : y - popupElementHeight - 10,
          };
        default:
          return {
            x:
              x + popupElementWidth > detectedWidth
                ? window.innerWidth - popupElementWidth - 10
                : x + 10,
            y: calculateY(0),
          };
      }
    };

    return targetRect
      ? calculatePosition(targetRect.right, targetRect.top)
      : calculatePosition(clientAxis.clientX, clientAxis.clientY);
  };

  useEffect(() => {
    if (!disableClickOutside) {
      const handleClickOutside = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
          handleClose();
        }
      };

      document.addEventListener('click', handleClickOutside, true);
      document.addEventListener('close-popup', handleClose, true);

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
        document.addEventListener('close-popup', handleClose, true);
      };
    }
  }, [disableClickOutside, handleClose]);

  const { x, y } = getPosition();

  return (
    isOpen && (
      <>
        <div
          className={classnames(`fixed z-[9999] rounded-md bg-white`)}
          ref={popupRef}
          style={{
            left: `${x}px`,
            top: `${y}px`,
            zIndex: index,
          }}
        >
          {children}
        </div>

        {backdrop && <div className="fixed left-0 top-0 z-40 h-full w-full bg-amber-700" />}
      </>
    )
  );
};
