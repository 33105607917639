import { queryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { useGeofenceParams } from '@/modules/Home/hooks/map/useGeofenceParams.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { geofencesService } from '@/shared/services/geofences.service.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { useGlobalStore } from '@/shared/store/global';
import { usePopupStore } from '@/shared/store/popup';
import { useUIStore } from '@/shared/store/ui';
import { QUERY_KEY } from '@/shared/utils/constants.ts';
import { ERROR_MESSAGES } from '@/shared/utils/error-messages.ts';

import { GeofencesProps } from '@/shared/types/global/geofences.types.ts';

export namespace useGeofencesHooks {
  export const getAll = () => {
    const { user } = useFetchUserData();

    const site = useUIStore(store => store.site);
    const floor = useUIStore(store => store.floor);
    const setGeofences = useGlobalStore(state => state.setGeofences);
    const setCurrentGeofenceId = useGlobalStore(state => state.setCurrentGeofenceId);

    const geofencesQueryOptions = queryOptions({
      queryKey: [
        QUERY_KEY.GEOFENCES_GET,
        user?.accountResourceName,
        site,
        floor?.id,
        setGeofences,
        setCurrentGeofenceId,
      ],

      /** Most likely, this option will be used in the future */
      // queryFn: async () => {
      //   return await GeofencesAPINamespace.getAll({
      //     accountResName: user?.accountResourceName as string,
      //     siteResName: site,
      //     floorResName: floor?.id as string,
      //   });
      // },

      enabled: !!user?.accountResourceName && !!site && !!floor?.id,

      queryFn: async () => {
        geofencesService.options = {
          accountResName: user?.accountResourceName as string,
          floorResName: floor?.id as string,
          siteResName: site,
        };

        return await geofencesService.getGeofences({
          setCurrentGeofenceId,
          setGeofences,
        });
      },
    });

    return useQuery(geofencesQueryOptions);
  };

  export const updateStatus = () => {
    const { user } = useFetchUserData();
    const { queryClient, site } = useGeofenceParams();
    const visibleGeofenceIds = useGlobalStore(state => state.visibleGeofenceIds);
    const setGeofenceVisible = useGlobalStore(state => state.setGeofenceVisible);
    const closePopup = usePopupStore(store => store.closePopup);

    const updateGeofenceStatus = async (params: GeofencesProps) => {
      geofencesService.options = {
        accountResName: user?.accountResourceName as string,
        floorResName: params?.floorResName,
        siteResName: site,
      };

      await geofencesService.updateGeofenceStatus(params);
    };

    return useMutation({
      mutationFn: async (params: GeofencesProps) => {
        await notifyService.promise(updateGeofenceStatus(params), {
          error: ERROR_MESSAGES.GEOFENCE_UPDATE_FAILED,
          loading: ERROR_MESSAGES.GEOFENCE_UPDATING,
          success: ERROR_MESSAGES.GEOFENCE_UPDATE_SUCCESS,
        });
        setGeofenceVisible(params.geofenceResName, true, true);

        await updateGeofenceStatus(params);
        return params.geofenceResName;
      },

      mutationKey: [QUERY_KEY.GEOFENCE_UPDATE],

      onError: error => {
        console.error(error);
        notifyService.error(ERROR_MESSAGES.GEOFENCE_UPDATE_FAILED);
      },

      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.GEOFENCES_GET],
        });

        for (const geofenceId of visibleGeofenceIds) {
          closePopup(geofenceId);
        }
      },
    });
  };

  export const removeGeofence = () => {
    const { queryClient, site } = useGeofenceParams();
    const { user } = useFetchUserData();

    const deleteGeofence = async (params: GeofencesProps) => {
      geofencesService.options = {
        accountResName: user?.accountResourceName as string,
        floorResName: params?.floorResName,
        siteResName: site,
      };

      await geofencesService.deleteGeofence(params);
    };

    return useMutation({
      mutationFn: async (params: GeofencesProps) => {
        await notifyService.promise(deleteGeofence(params), {
          error: ERROR_MESSAGES.GEOFENCE_DELETE_FAILED,
          loading: ERROR_MESSAGES.GEOFENCE_DELETING,
          success: ERROR_MESSAGES.GEOFENCE_DELETE_SUCCESS,
        });

        await deleteGeofence(params);
      },

      mutationKey: [QUERY_KEY.GEOFENCE_DELETE],

      onError: error => {
        console.error(error);
        notifyService.error(ERROR_MESSAGES.GEOFENCE_DELETE_FAILED);
      },

      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.GEOFENCES_GET],
        });
      },
    });
  };
}
