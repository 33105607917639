import { useSearchParams } from 'react-router-dom';

import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';

import { GeofenceCategoryEnum } from '@/shared/types/global/geofences.types.ts';

export const useClearSiteData = () => {
  const [, setSearchParameters] = useSearchParams();

  const setFloor = useUIStore(state => state.setFloor);
  const setFloorsList = useUIStore(state => state.setFloorsList);
  const setWorkers = useGlobalStore(store => store.setWorkers);
  const setWorkersInUse = useGlobalStore(store => store.setWorkersInUse);
  const setEquipment = useGlobalStore(store => store.setEquipment);
  const setEquipmentInUse = useGlobalStore(store => store.setEquipmentInUse);
  const setGeofences = useGlobalStore(state => state.setGeofences);
  const setGeoFenceMapBlobUrl = useGlobalStore(state => state.setGeoFenceMapBlobUrl);
  const setCurrentGeofence = useGlobalStore(state => state.setCurrentGeofence);
  const setSiteMapConfig = useGlobalStore(state => state.setSiteMapConfig);

  const clearSiteData = () => {
    setSearchParameters(new URLSearchParams());

    setGeoFenceMapBlobUrl('');
    setSiteMapConfig(null);

    setFloor(null);
    setFloorsList([]);

    setGeofences([]);
    setCurrentGeofence([], GeofenceCategoryEnum.INACTIVE);

    setWorkers([]);
    setWorkersInUse([]);
    setEquipment([]);
    setEquipmentInUse([]);
  };

  return { clearSiteData };
};
