import { useNavigate } from 'react-router-dom';

import { queryOptions, useQuery } from '@tanstack/react-query';

import { SitesAPINamespace } from '@/shared/api/sites/sites.requests.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';
import { checkOnSimilar } from '@/shared/utils/check-on-similar.ts';
import { QUERY_KEY, REQUESTS_OPTIONS } from '@/shared/utils/constants.ts';
import { ERROR_MESSAGES } from '@/shared/utils/error-messages.ts';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

export namespace useSitesHooks {
  export const getAll = () => {
    const navigate = useNavigate();

    const setSitesList = useUIStore(state => state.setSitesList);
    const site = useUIStore(state => state.site);
    const setSite = useUIStore(state => state.setSite);

    const { user } = useFetchUserData();

    const sitesQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName,

      queryFn: async () => {
        const data = await SitesAPINamespace.getAll(user?.accountResourceName as string);

        const result = checkOnSimilar(data?.results, 'siteResName');

        if (!site && result.length === 1) {
          setSite(result[0].siteResName);
          notifyService.success(ERROR_MESSAGES.LOGIN_SUCCESS);
        }

        if (result.length <= 0) {
          navigate(RoutesEnum.LOGIN);
          notifyService.error(ERROR_MESSAGES.SITES_LIST_ERROR);
        }

        setSitesList(result);
        return data;
      },

      queryKey: [QUERY_KEY.SITES, user?.accountResourceName, site],

      retry: REQUESTS_OPTIONS.LIMIT_REQUEST,
    });

    return useQuery(sitesQueryOptions);
  };

  export const getAllTags = () => {
    const site = useUIStore(store => store.site);
    const setSiteTagsList = useGlobalStore(store => store.setSiteTagsList);

    const { user } = useFetchUserData();

    const siteTagsQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!site,

      queryFn: async () => {
        const data = await SitesAPINamespace.getTags(user?.accountResourceName as string, site);
        setSiteTagsList(data.results);

        return data;
      },

      queryKey: [QUERY_KEY.SITE_TAGS, user?.accountResourceName, site],
    });

    return useQuery(siteTagsQueryOptions);
  };
}
