import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { Typography } from '@/shared/components/ui/Typography';
import { useModalHandlerTypes } from '@/shared/hooks/global/useModalHandlerTypes.ts';
import { classnames } from '@/shared/utils/classnames';

import { useInviteForm } from '../Subcontractor/hooks/useInviteForm';

import { ConfirmAssetDeleteProps, confirmIcons } from '@/shared/components/ui/Modal/Modal.types';

export const ConfirmAssetDelete = ({ type }: ConfirmAssetDeleteProps) => {
  const { isDeleting, setIsClose } = useModalHandlerTypes();
  const { deleteHandler } = useInviteForm();

  const Icon = confirmIcons['delete'];

  const handleCancel = () => {
    setIsClose();
  };

  const handleDelete = async () => {
    await deleteHandler(type);
  };

  return (
    <div className="grid items-center justify-center gap-y-5 p-10">
      <Icon className="mx-auto h-[62px] w-[62px]" />

      <div className="grid gap-y-2.5 text-center">
        <Typography as="strong" className="text-2xl font-bold tracking-[0.12px] text-slate-800">
          {type === 'firmEdit' ? translate('global.deleteFirm') : translate('global.deleteWorker')}
        </Typography>

        <Typography className="text-base font-medium tracking-[0.05px] text-slate-500">
          {type === 'firmEdit'
            ? translate('messages.confirmDeleteFirm')
            : translate('messages.confirmDeleteWorker')}
        </Typography>
      </div>

      <div className="flex justify-center gap-x-4">
        <Button
          className="w-full !border-slate-200 text-slate-800 hover:!bg-slate-100"
          onClick={handleCancel}
          type="button"
          variant="outline"
        >
          {translate('settings.cancel')}
        </Button>

        <Button
          className={classnames(
            'w-full',
            'bg-red-600 text-white hover:bg-red-700 focus:bg-red-700',
          )}
          isLoading={isDeleting}
          onClick={handleDelete}
          type="submit"
          variant="solid"
          withIcon
        >
          {translate('confirm.delete')}
        </Button>
      </div>
    </div>
  );
};
