import { FC, useEffect } from 'react';
import { Controller, FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';

import { translate } from '@/i18n';
import { usePeopleAsset } from '@/modules/Subcontractors/hooks/usePeopleAsset.ts';
import { Button } from '@/shared/components/buttons/Button';
import { FilterSelect } from '@/shared/components/form/FilterSelect';
import { Input } from '@/shared/components/form/Input';
import { RequiredFieldIndicator } from '@/shared/components/form/RequiredFieldIndicator';
import { Select } from '@/shared/components/form/Select';
import { Toggle } from '@/shared/components/form/Toggle';
import { groupsList } from '@/shared/components/ui/Modal/Subcontractor/data';
import { Typography } from '@/shared/components/ui/Typography';
import { useFiltersHideGroupsZonesShowMapFlag } from '@/shared/feature-flags/useFiltersHideGroupsZonesShowMapFlag.ts';
import { useToggleModal } from '@/shared/hooks/global/useToggleModal.ts';
import { MESSAGES } from '@/shared/schemas/constants';
import { classnames } from '@/shared/utils/classnames.ts';

import { FormTypes } from './types';

import { PopupTypeEnum } from '@/shared/types/global/enums.ts';
import { MapFilterPropEnum } from '@/shared/types/global/filters.types.ts';

type FormBodyProps = {
  params: UseFormReturn;
  handler: (vals: FieldValues, type: FormTypes) => void;
  type: FormTypes;
  mode: string;
  setMode: (status: string) => void;
};

export const SubcontractorsFormBody: FC<FormBodyProps> = ({
  handler,
  mode,
  params,
  type = 'invite',
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = params;

  const {
    activeFirmId: firmId,
    activeUserResrName,
    activeWorkerId: workerId,
    assetsTags,
    disableEmailAndPasswordEdit,
    firmList,
    firms,
    forcePhoneEdit,
    isDeleting,
    isSubmitting,
    people,
    rolesList,
    setForcePhoneEdit,
    setIsClose: closeModal,
  } = usePeopleAsset();

  const { filtersHideGroupsZonesShowMapFlag } = useFiltersHideGroupsZonesShowMapFlag();

  const isPersonMode = mode.toLocaleLowerCase().includes('person') || type === 'workerEdit';
  const isButtonDisabled = Object.keys(errors).some(key => !['email', 'phone'].includes(key));

  const isEditing = type === 'firmEdit' || type === 'workerEdit';

  const { handler: deleteHandler } = useToggleModal();

  const isZpsAccessError =
    getValues('zpsAccess') && errors?.phone?.message === MESSAGES.EMAIL_PHONE_REQUIRED;

  useEffect(() => {
    switch (type) {
      case 'firmEdit': {
        const firm = firms.find(firm => firm.id === firmId);
        setValue('newFirm', firm?.firm_name);
        setValue('status', firm?.status);
        setValue('firmGivenName', firm?.given_name);
        setValue('firmFamilyName', firm?.family_name);
        setValue('firmEmail', firm?.email);
        setValue('firmPhone', firm?.phone);

        break;
      }
      case 'invite': {
        setValue('firm', firmId);
        setValue('status', 'inactive');
        break;
      }
      case 'workerEdit': {
        const worker = people.find(worker => worker.id === workerId);
        setValue('givenName', worker?.given_name);
        setValue('familyName', worker?.family_name);
        setValue('email', worker?.email);
        setValue('phone', worker?.phone);
        setValue('firm', worker?.orgResName);
        setValue('role', worker?.role?.replace(' ', '-').toLocaleLowerCase());
        setValue('zpsAccess', !!activeUserResrName);
        setValue('tags', [
          ...new Set(
            assetsTags
              .filter(assetTag => assetTag?.asset?.assetResName === workerId)
              .map(assetTag => assetTag.tag.tagName),
          ),
        ]);
        break;
      }
    }
  }, [type]);

  useEffect(() => {
    if (errors?.phone?.message) {
      setForcePhoneEdit(true);
    }
  }, [errors?.phone?.message]);

  useEffect(() => {
    return () => setForcePhoneEdit(false);
  }, []);

  const submitHandler: SubmitHandler<FieldValues> = (vals: FieldValues) => {
    handler(vals, type);
  };

  const renderActions = () => {
    return (
      <>
        {isEditing && (
          <Button
            className="border-slate-200 bg-red-500 text-white hover:bg-red-400"
            disabled={type === 'firmEdit'}
            isLoading={isDeleting}
            onClick={() => {
              if (type === 'firmEdit') {
                deleteHandler(PopupTypeEnum.CONFIRM_DELETE_FIRM, '');
              } else if (type === 'workerEdit') {
                deleteHandler(PopupTypeEnum.CONFIRM_DELETE_WORKER, '');
              }
            }}
            type="reset"
            variant="outline"
          >
            {translate('confirm.delete')}
          </Button>
        )}

        <div>
          <Button
            className="mx-2 border-slate-200 bg-white text-slate-800 hover:bg-slate-50 hover:text-slate-900 focus:bg-slate-50 focus:text-slate-900"
            onClick={closeModal}
            type="reset"
            variant="outline"
          >
            {translate('filters.cancel')}
          </Button>

          <Button
            disabled={isZpsAccessError || isButtonDisabled}
            isLoading={isSubmitting}
            type="submit"
            variant="solid"
            withIcon
          >
            {type === 'invite' ? translate('filters.invite') : translate('settings.save')}
          </Button>
        </div>
      </>
    );
  };

  watch();

  return (
    <form className="grid w-full max-w-md gap-y-3 pb-[70px]" onSubmit={handleSubmit(submitHandler)}>
      <div className="inline-flex items-center gap-x-3">
        <Typography
          as="span"
          className="inline-flex w-[128px] gap-x-0.5 text-start text-sm font-medium"
        >
          {translate('global.firm')}

          {type === 'invite' || type === 'workerEdit' ? <RequiredFieldIndicator /> : null}
        </Typography>

        <div className="inline-flex w-full gap-x-3">
          {isPersonMode ? (
            <div className="w-full">
              <Controller
                control={control}
                name="firm"
                render={({ field }) => (
                  <Select
                    data={firmList}
                    defaultValue={getValues('firm')}
                    hasError={!!errors?.firm?.message}
                    label="auth.select"
                    onChange={field.onChange}
                    variant="default"
                  />
                )}
              />
            </div>
          ) : (
            <Input
              className="w-full"
              {...register('newFirm', {
                required: type === 'invite' || type === 'firmEdit',
              })}
              error={`${errors?.newFirm?.message ?? ''}`}
              placeholder="assets.enter"
            />
          )}
        </div>
      </div>
      {!isPersonMode && (
        <div className="inline-flex items-center gap-x-3">
          <Controller
            control={control}
            name="status"
            render={({ field }) => (
              <div className="w-full items-center gap-x-2 inline-flex">
                <Toggle
                  checked={field.value === 'active'}
                  onChange={() => field.onChange(field.value === 'active' ? 'inactive' : 'active')}
                />
                <Typography
                  as="span"
                  className="inline-flex w-[128px] gap-x-0.5 text-start text-sm font-medium"
                >
                  {field.value === 'active'
                    ? translate('assets.active')
                    : translate('assets.inactive')}
                </Typography>
              </div>
            )}
          />
        </div>
      )}
      {isPersonMode && type !== 'firmEdit' && (
        <>
          {/*Role*/}
          <div className="inline-flex items-center gap-x-3">
            <Typography
              as="span"
              className="inline-flex w-[128px] gap-x-0.5 text-start text-sm font-medium"
            >
              {translate('global.role')}
              <RequiredFieldIndicator />
            </Typography>

            <div className="w-full">
              <Controller
                control={control}
                name="role"
                render={({ field }) => (
                  <Select
                    data={rolesList}
                    defaultValue={getValues('role')}
                    hasError={!!errors?.role?.message}
                    label="auth.select"
                    onChange={field.onChange}
                    variant="default"
                  />
                )}
              />
            </div>
          </div>

          {filtersHideGroupsZonesShowMapFlag.enabled && (
            <div className="inline-flex items-center gap-x-3">
              <Typography as="span" className="w-[128px] text-start text-sm font-medium">
                {translate('global.groups')}
              </Typography>

              <div className="w-full">
                <Controller
                  control={control}
                  name="groups"
                  render={({ field }) => (
                    <Select
                      data={groupsList}
                      label="auth.select"
                      onChange={field.onChange}
                      variant="default"
                    />
                  )}
                />
              </div>
            </div>
          )}
        </>
      )}

      <div className="inline-flex items-center gap-x-3">
        <Typography as="span" className="gap-x-0.4 w-[130px] text-start text-sm font-medium">
          {translate('global.givenName')}

          {type === 'invite' || type === 'workerEdit' ? <RequiredFieldIndicator /> : null}
        </Typography>

        <div className="w-full">
          <Input
            {...register(isPersonMode ? 'givenName' : 'firmGivenName', {
              required: type === 'invite' || type === 'workerEdit',
            })}
            error={
              isPersonMode
                ? `${errors?.givenName?.message ?? ''}`
                : `${errors?.firmGivenName?.message ?? ''}`
            }
            placeholder="assets.enter"
          />
        </div>
      </div>

      <div className="inline-flex items-center gap-x-3">
        <Typography as="span" className="gap-x-0.4 w-[130px] text-start text-sm font-medium">
          {translate('global.familyName')}

          {type === 'invite' || type === 'workerEdit' ? <RequiredFieldIndicator /> : null}
        </Typography>

        <div className="w-full">
          <Input
            {...register(isPersonMode ? 'familyName' : 'firmFamilyName', {
              required: type === 'invite' || type === 'workerEdit',
            })}
            error={
              isPersonMode
                ? `${errors?.familyName?.message ?? ''}`
                : `${errors?.firmFamilyName?.message ?? ''}`
            }
            placeholder="assets.enter"
          />
        </div>
      </div>

      {isPersonMode && type !== 'firmEdit' && (
        <>
          <div className="inline-flex items-center gap-x-3">
            <Typography as="span" className="w-[128px] text-start text-sm font-medium">
              {translate('settings.tag')}
            </Typography>

            <div className="w-full">
              <Controller
                control={control}
                name={'tags'}
                render={({ field: { onChange, value } }) => (
                  <FilterSelect
                    asSelect
                    name={MapFilterPropEnum.TAG}
                    onChange={onChange}
                    value={value ?? []}
                  />
                )}
              />
            </div>
          </div>

          <div className="inline-flex items-center gap-x-3">
            <Toggle
              checked={getValues('zpsAccess') ?? false}
              onChange={() => setValue('zpsAccess', !getValues('zpsAccess'))}
            />

            <Typography as="span" className="text-sm font-medium">
              {translate('global.giveZpsAccess')}
            </Typography>
          </div>
        </>
      )}

      <div className="inline-flex items-center gap-x-3">
        <Typography
          as="span"
          className="inline-flex w-[128px] gap-x-0.5 text-start text-sm font-medium"
        >
          {translate('global.email')}

          {(!isPersonMode || getValues('zpsAccess')) &&
            (type === 'invite' || type === 'workerEdit') && <RequiredFieldIndicator />}
        </Typography>

        <div className="w-full">
          <Input
            {...register(isPersonMode ? 'email' : 'firmEmail', {
              required: (!isPersonMode || getValues('zpsAccess')) && type === 'invite',
            })}
            disabled={isPersonMode && isEditing && disableEmailAndPasswordEdit}
            error={
              isPersonMode
                ? isZpsAccessError
                  ? `${errors?.phone?.message ?? ''}`
                  : `${errors?.email?.message ?? ''}`
                : `${errors?.firmEmail?.message ?? ''}`
            }
            placeholder="global.email"
          />
        </div>
      </div>

      <div className="inline-flex items-center gap-x-3">
        <Typography
          as="span"
          className="inline-flex w-[128px] gap-x-0.5 text-start text-sm font-medium"
        >
          {translate('global.phone')}

          {(!isPersonMode || getValues('zpsAccess')) &&
            (type === 'invite' || type === 'workerEdit') && <RequiredFieldIndicator />}
        </Typography>

        <div className="w-full">
          <Input
            {...register(isPersonMode ? 'phone' : 'firmPhone', {
              required: (!isPersonMode || getValues('zpsAccess')) && type === 'invite',
            })}
            disabled={!forcePhoneEdit && isPersonMode && isEditing && disableEmailAndPasswordEdit}
            error={
              isPersonMode
                ? `${errors?.phone?.message ?? ''}`
                : `${errors?.firmPhone?.message ?? ''}`
            }
            placeholder="global.phone"
          />
        </div>
      </div>

      <div
        className={classnames(
          'absolute bottom-0 left-0 z-20 flex h-[70px] w-full items-center justify-between gap-x-2 rounded-b-2xl border-t border-slate-200 bg-slate-100 px-3 py-2',
          isEditing ? 'justify-between' : 'justify-end',
        )}
      >
        {renderActions()}
      </div>
    </form>
  );
};
