import { useEffect } from 'react';

import { useUIStore } from '@/shared/store/ui';

import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';

const langFontMap: { [k in LocaleEnum]: string } = {
  [LocaleEnum.EN]: 'Noto Sans',
  [LocaleEnum.JPN]: 'Noto Sans JP',
};

export function useLanguageFont() {
  const currentLanguage = useUIStore(state => state.currentLanguage);

  useEffect(() => {
    const langFont = langFontMap[currentLanguage];

    if (langFont) {
      document.body.style.fontFamily = `${langFont}, sans-serif`;
      document.documentElement.lang = currentLanguage === LocaleEnum.JPN ? 'ja' : 'en';
    }
  }, [currentLanguage]);
}
