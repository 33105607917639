import { useIsMutating } from '@tanstack/react-query';

import { WebAPIStorageNamespace } from '@/shared/services/api-storage.service';
import { useUIStore } from '@/shared/store/ui';
import { LS_KEYS, QUERY_KEY } from '@/shared/utils/constants.ts';

import { RolesEnum } from '@/shared/types/global/enums.ts';
import { UserProps } from '@/shared/types/users/user.types.ts';

export const useAssetStatus = () => {
  const isShowInactive = useUIStore(store => store.isShowInactive);
  const setIsShowInActive = useUIStore(store => store.setIsShowInactive);
  const userStorageData =
    WebAPIStorageNamespace.SessionService.getSessionItem(LS_KEYS.USER_DATA) ??
    WebAPIStorageNamespace.LocalStorageService.getLocalItem(LS_KEYS.USER_DATA);
  const user: UserProps = JSON.parse(userStorageData as string);

  const isMutatingAssetsStatus = useIsMutating({
    mutationKey: [QUERY_KEY.TOGGLE_ASSET_STATUS],
  });

  const isMutatingFirmStatus = useIsMutating({
    mutationKey: [QUERY_KEY.TOGGLE_FIRM_STATUS],
  });

  const allowToggleEquipment =
    user?.role === RolesEnum.MANAGER || user?.role === RolesEnum.ENGINEER;

  const isMutatingStatus = !!isMutatingAssetsStatus || !!isMutatingFirmStatus;

  const toggleShowInactive = () => {
    setIsShowInActive(!isShowInactive);
  };

  return {
    allowToggleEquipment: allowToggleEquipment,
    isMutatingStatus,
    isShowInactive,
    setIsShowInActive,
    toggleShowInactive,
  };
};
