import { queryOptions, useQuery } from '@tanstack/react-query';

import { SettingsAPINamespace } from '@/shared/api/settings/settings.requests.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { SettingsService } from '@/shared/services/settings.service.ts';
import { useUIStore } from '@/shared/store/ui';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

import { SettingsKeyEnum, SettingsNameEnum } from '@/shared/api/settings/settings.types.ts';

export namespace useSettingsHooks {
  export const getAll = () => {
    const uiStore = useUIStore();

    const { user } = useFetchUserData();

    const getUserSettings = async () => {
      const settings = await SettingsAPINamespace.getAll({
        accountResName: user?.accountResourceName as string,
        userResName: user?.userResourceName as string,
      });

      uiStore.setUserSettings(settings?.results);

      const userSettings = new SettingsService(
        settings?.results,
        SettingsNameEnum.UserSettings,
        SettingsKeyEnum.UserSettings,
      );

      // User settings
      uiStore.setCurrency(userSettings.getSelectedCurrency());
      uiStore.setSiteTimeZone(userSettings.getSelectedSiteTimeZone());
      uiStore.setDisplayTimeZone(userSettings.getSelectedDisplayTimeZone());
      uiStore.setTimeFormat(userSettings.getSelectedTimeFormat());
      uiStore.setSystemView(userSettings.getSelectedMeasurementSystem());

      return settings;
    };

    const settingsQueryOptions = queryOptions({
      enabled: !!user?.accountResourceName && !!user?.userResourceName,

      queryFn: getUserSettings,

      queryKey: [QUERY_KEY.USER_SETTINGS_GET, user?.accountResourceName, user?.userResourceName],
    });

    return useQuery(settingsQueryOptions);
  };
}
