import { lazy } from 'react';

export namespace LazyRoutesNamespace {
  export const Equipment = lazy(() =>
    import('@/pages/Equipment').then(({ Equipment }) => ({
      default: Equipment,
    })),
  );
  export const Home = lazy(() => import('@/pages/Home').then(({ Home }) => ({ default: Home })));
  export const Reports = lazy(() =>
    import('@/pages/Reports').then(({ Reports }) => ({ default: Reports })),
  );
  export const ErrorPage = lazy(() =>
    import('@/pages/ErrorPage').then(({ ErrorPage }) => ({
      default: ErrorPage,
    })),
  );
  export const Reservations = lazy(() =>
    import('@/pages/Reservations').then(({ Reservations }) => ({
      default: Reservations,
    })),
  );
  export const Settings = lazy(() =>
    import('@/pages/Settings').then(({ Settings }) => ({ default: Settings })),
  );
  export const Subcontractors = lazy(() =>
    import('@/pages/Subcontractors').then(({ Subcontractors }) => ({
      default: Subcontractors,
    })),
  );
  export const Actions = lazy(() =>
    import('@/pages/Actions').then(({ Actions }) => ({ default: Actions })),
  );
  export const Login = lazy(() =>
    import('@/pages/Auth/Login').then(({ Login }) => ({ default: Login })),
  );
  export const SiteSelect = lazy(() =>
    import('@/pages/Auth/Site').then(({ Site }) => ({ default: Site })),
  );
  export const RestorePassword = lazy(() =>
    import('@/pages/Auth/RestorePassword').then(({ RestorePassword }) => ({
      default: RestorePassword,
    })),
  );
  export const Releases = lazy(() =>
    import('@/pages/Releases').then(({ Releases }) => ({ default: Releases })),
  );
  export const TermsOfUse = lazy(() =>
    import('@/pages/Auth/TermsOfUse').then(({ TermsOfUse }) => ({ default: TermsOfUse })),
  );
  export const Licences = lazy(() =>
    import('@/pages/Licences').then(({ Licences }) => ({ default: Licences })),
  );
}
