import { useEffect } from 'react';

import mp from 'mixpanel-browser';

import { useFeatureFlags } from '@/shared/hooks/global/useFeatureFlags.ts';

export const useMixpanelConfigUpdate = () => {
  const { flags } = useFeatureFlags();

  useEffect(() => {
    const flag = flags.mix_panel_implementation;

    // Enable URL tracking when feature flag is enabled
    if (flag?.enabled) {
      mp.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
        api_host: 'https://mp.zainar.net',
        debug: import.meta.env.MODE === 'development',
        persistence: 'localStorage',
        track_pageview: 'full-url',
      });
    }
  }, [flags.mix_panel_implementation]);
};
