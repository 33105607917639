import { translate } from '@/i18n';
import { RESERVATIONS } from '@/shared/utils/constants.ts';

export const renderBadgeTitle = (content: string) => {
  switch (content) {
    case RESERVATIONS.approvedMgr:
      return translate('reservation.approvedMgr');
    case RESERVATIONS.approvedSub:
      return translate('reservation.approvedSub');
    case RESERVATIONS.conflictedSub:
      return translate('reservation.conflictSub');
    case RESERVATIONS.conflictedMgr:
      return translate('reservation.conflictMgr');
    case RESERVATIONS.draftSub:
      return translate('reservation.draft');
    case RESERVATIONS.rejectedSub:
      return translate('reservation.rejectedSub');
    case RESERVATIONS.rejectedMgr:
      return translate('reservation.rejectedMgr');
    case RESERVATIONS.requestedSub:
      return translate('reservation.requested');
    default:
      return 'New';
  }
};

export const renderBadgeColor = (content: string) => {
  switch (content) {
    case RESERVATIONS.approvedSub:
      return 'border-green-600 text-green-600';
    case RESERVATIONS.approvedMgr:
      return 'border-green-600 text-green-600';
    case RESERVATIONS.conflictedSub:
      return 'border-amber-600 text-amber-600';
    case RESERVATIONS.conflictedMgr:
      return 'border-amber-600 text-amber-600';
    case RESERVATIONS.draftSub:
      return 'border-slate-800 text-slate-800';
    case RESERVATIONS.rejectedSub:
      return 'border-red-600 text-red-600';
    case RESERVATIONS.rejectedMgr:
      return 'border-red-600 text-red-600';
    case RESERVATIONS.requestedSub:
      return 'border-slate-800 text-slate-800';
    default:
      return 'border-slate-800 text-slate-800';
  }
};

export const workflowStates = [
  {
    name: translate('reservation.approvedMgr'),
    id: RESERVATIONS.approvedMgr,
  },
  {
    name: translate('reservation.approvedSub'),
    id: RESERVATIONS.approvedSub,
  },
  {
    name: translate('reservation.conflictMgr'),
    id: RESERVATIONS.conflictedMgr,
  },
  {
    name: translate('reservation.conflictSub'),
    id: RESERVATIONS.conflictedSub,
  },
  {
    name: translate('reservation.draft'),
    id: RESERVATIONS.draftSub,
  },
  {
    name: translate('reservation.rejectedMgr'),
    id: RESERVATIONS.rejectedMgr,
  },
  {
    name: translate('reservation.rejectedSub'),
    id: RESERVATIONS.rejectedSub,
  },
  {
    name: translate('reservation.requested'),
    id: RESERVATIONS.requestedSub,
  },
];
