export const MISSING_DATA = '-';

export const DEFAULT_MAX_HEIGHT = 3;
export const DEFAULT_FLOOR_HEIGHT = 3;
export const MAX_COUNTER = 99;
export const BROADCAST_CHANNEL_NAME = 'zps-bc';
export const BROADCAST_LOGOUT_KEY = 'zps-bc-logout';

export const LS_KEYS = {
  ACCOUNT_NAME: 'account_name',
  ACCOUNT_RES_NAME: 'account_res_name',
  BUILD_TIME: 'buildtime',
  CLIENT_ID: 'client_id',
  COGNITO: 'aws.cognito.identity',
  COLLAPSE: 'collapse',
  GLOBAL: 'global',
  IDENTITY_POOL_ID: 'identity_pool_id',
  IS_SHOW_INACTIVE: 'is_show_inactive',
  LOCATION: 'location',
  REGION: 'region',
  REMEMBER_ME: 'remember_me',
  SHOW_GRID: 'show-grid',
  TERM_OF_USE: 'term-of-use',
  UI: 'ui',
  UI_STORE: 'ui-settings',
  USER_DATA: 'user-data',
  USER_POOL_ID: 'user_pool_id',
  USERNAME: 'username',
  VIEW_ZONES: 'view-zones',
};

export const QUERY_KEY = {
  ALERTS: 'alerts',
  ALL_SITES_MAPS: 'all-sites-maps',
  ANALYTICS_DWELL_TIMES: 'analytics-dwell-times',
  ANALYTICS_TRIPS: 'analytics-trips',
  ARCHIVE_ACTION: 'archive-action',
  ARCHIVED_ALERTS: 'archived-alerts',
  ASSETS: 'assets',
  ASSETS_CATEGORIES: 'assets-categories',
  ASSETS_DETAILS: 'assets-details',
  ASSETS_TAGS: 'assets-tags',
  CHECK_UPDATE: 'check-update',
  COMPANIES: 'companies',
  EMPLOYEES: 'employees',
  EQUIPMENT_CREATE: 'create-equipment',
  EQUIPMENT_DELETE: 'delete-equipment',
  EQUIPMENT_OCCUPANTS: 'equipment_occupants',
  EQUIPMENT_TAGS: 'equipment_tags',
  EQUIPMENT_UPDATE: 'update-equipment',
  EQUIPMENTS_GET: 'get-equipments',
  EXECUTE: 'execute',
  FLOORS: 'floors',
  GEOFENCE_CREATE: 'create-geofence',
  GEOFENCE_DELETE: 'delete-geofence',
  GEOFENCE_UPDATE: 'update-geofence',
  GEOFENCES_GET: 'get-geofences',
  GET_READERS: 'readers',
  LOCATION_HISTORY: 'location-history',
  LOGIN: 'login',
  MAPS: 'maps',
  METRICS: 'metrics',
  REFRESH_TOKEN: 'refresh-token',
  RESERVATION: 'reservation',
  RESERVATION_HISTORY: 'reservation-history',
  RESERVATION_EVENT_SUBMIT: 'reservation_event_submit',
  SET_ASSETS: 'set-assets',
  SITE_CALENDARS_GET: 'site-calendars-get',
  SITE_POLICIES_GET: 'site-policies-get',
  SITE_SHIFTS_GET: 'site-shifts-get',
  SITE_TAGS: 'site-tags',
  SITES: 'sites',
  TOGGLE_ASSET_STATUS: 'toggle-asset-status',
  TOGGLE_ASSET_STATUS_BATCH: 'toggle-asset-status-batch',
  TOGGLE_FIRM_STATUS: 'toggle-firm-status',
  TRACKERS_HISTORY: 'trackers-history',
  USER_POOL: 'user-pool',
  USER_SETTINGS_CREATE: 'user-settings-create',
  USER_SETTINGS_GET: 'user-settings-get',
  USER_SETTINGS_UPDATE: 'user-settings-update',
  WORKERS: 'workers',
  ZPS_USER: 'zps-user-detail',
  ZPS_USERS: 'zps-users',
};

export const TEST_ID = {
  ACCORDION_TITLE_ICON: 'title-icon',
  ACTIVE_ROUTE: 'active-route',
  NAVIGATE: 'navigate',
  OUTLET: 'outlet',
};

export const URL_PARAMS = {
  ACTIONS: 'view',
  ASSET: 'asset',
  ASSET_AVAILABILITY: 'availability',
  ASSET_RES_NAME: 'asset_res_name',
  COUNT: 'count',
  CURENT_ZONE: 'currentZone',
  DATE: 'date',
  DAY: 'current_day',
  END_TIME: 'end_time',
  END_ZONE: 'endZone',
  EQUIPMENT_ID: 'equipment_id',
  EQUIPMENT_STATUS: 'equipment-status',
  EQUIPMENT_SUBTYPE: 'subtype',
  EQUIPMENT_TYPE: 'type',
  EXTRA_GROUP: 'extra-group',
  FIRM: 'firm',
  FIRM_EDIT_ID: 'firm_edit_id',
  GEOFENCE_RES_NAME: 'geofence_res_name',
  GROUP: 'group',
  GROUP_ID: 'group_id',
  IS_READY_TO_FETCH: 'is_ready_to_fetch',
  MODE: 'mode',
  MULTI_ASSETS: 'multi-asset',
  MULTI_DATE: 'multi-date',
  MULTI_ZONES: 'multi-zone',
  ROLE: 'role',
  SIDEBAR: 'sidebar',
  START_TIME: 'start_time',
  START_ZONE: 'startZone',
  STATUS: 'status',
  STEP: 'step',
  SUBCONTRACTOR_ID: 'subcontractor_id',
  TAB: 'tab',
  TAG_ATTACHED: 'attached',
  TIMESTAMP: 'timestamp',
  TOKEN: 'token',
  WORKER_EDIT_ID: 'worker_edit_id',
  WORKER_ID: 'worker_id',
  ZONE: 'zone',
};

export const MODE = {
  HISTORICAL: 'historical',
  REALTIME: 'realtime',
};

export const REQUESTS_OPTIONS = {
  DELAY: 60,
  INTERVAL: 1000,
  LIMIT_PER_VIEW: 10,
  LIMIT_POINTS: 1000,
  LIMIT_REQUEST: 2,
  TIMEOUT: 5000,
};

export const PAGINATION = {
  PAGE_SIZE: 50,
};

export const SOCKET = {
  EVENT: {
    CLIENT_CLOSED: '[WS]: Disconnected from server',
    CLIENT_OPENED: '[WS]: Connected to server',
    CONNECTION_ERROR: '[WS]: Connection error',
    INTERNAL_SERVER_ERROR: 'Internal server error',
    ON_RECONNECT_STOP: '[WS]: Reconnect stopped',
    RECONNECT: '[WS]: Reconnecting to server',
    SHOULD_RECONNECT: '[WS]: Should reconnect',
  },
  MESSAGE: {
    ACTION: 'setFilterOptions',
    MESSAGE_TYPES: [
      'location-updates',
      'geomatch-updates',
      'alerts-equipment',
      'alerts-safety',
      'alerts-system',
      'alerts-zone',
    ],
  },
  OPTIONS: {
    HEARTBEAT_INTERVAL: 25000,
    HEARTBEAT_TIMEOUT: 600000,
    RECONNECT_ATTEMPTS: 10,
    RECONNECT_INTERVAL: 3000,
  },
};

export const RESERVATIONS = {
  approvedMgr: 'Approved-Mgr',
  approvedSub: 'Pending-Mgr',
  conflictedMgr: 'Conflict-Mgr',
  conflictedSub: 'Conflict-Sub',
  draftSub: 'New-Reservation',
  eventApprovedMgr: 'approved-mgr',
  eventApprovedSub: 'submit-mgr',
  eventNewFromRejectSub: 'modify-reservation',
  eventPendingSub: 'submit-sub',
  eventRejectMgr: 'rejected-mgr',
  eventRejectSub: 'rejected-sub',
  eventResolveConflictMgr: 'resolve-conflict-mgr',
  eventResolveConflictSub: 'resolve-conflict-sub',
  rejectedMgr: 'Rejected-Mgr',
  rejectedSub: 'Rejected-Sub',
  requestedSub: 'Pending-Sub',
};
