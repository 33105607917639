import { forwardRef, InputHTMLAttributes } from 'react';

import { Input } from '@/shared/components/form/Input';

export const DatePickerCustomInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  return <Input {...props} placeholder={'global.date'} ref={ref} />;
});
