import { FC } from 'react';

import { SiteLogo } from '@/shared/components/ui/SiteLogo';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';

import SectionLoader from '@/assets/icons/section-loader.svg?react';
import { WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithClassName & {
  appearance: 'custom' | 'full' | 'indicator' | 'inline' | 'ping' | 'section';
  loaderText?: JSX.Element | string;
  sectionLoaderClassName?: string;
  loaderTextClassName?: string;
  indicatorColor?: string;
  pingColor?: string;
  fullHeight?: boolean;
};

export const Loader: FC<Props> = ({
  appearance,
  className,
  fullHeight,
  indicatorColor,
  loaderText,
  loaderTextClassName,
  pingColor,
  sectionLoaderClassName,
}) => {
  switch (appearance) {
    case 'full':
      return (
        <div className="fixed inset-0 z-[9999] flex h-full w-full items-center justify-center bg-black/20 backdrop-blur-sm">
          <SiteLogo className="mx-auto h-[33px] w-[140px] animate-ping" />
        </div>
      );

    case 'indicator':
      return (
        <span
          className={classnames(
            'counterAnimation relative flex size-1.5 items-center justify-center',
            className,
          )}
        >
          <span
            className={classnames(
              'absolute inline-flex h-full w-full animate-[ping_3s__ease-in-out_infinite] rounded-full opacity-75',
              indicatorColor ?? 'bg-white',
            )}
          />
          <span
            className={classnames(
              'relative inline-flex h-1.5 w-1.5 rounded-full',
              indicatorColor ?? 'bg-white',
            )}
          />
        </span>
      );

    case 'inline':
      return (
        <span
          aria-label="loading"
          className="inline-block h-3.5 w-3.5 animate-spin rounded-full border-2 border-current border-t-transparent text-slate-200"
        />
      );

    case 'ping':
      return (
        <span className="relative flex h-2 w-2 items-center justify-center">
          <span
            className={classnames(
              'absolute inline-flex h-full w-full animate-ping rounded-full opacity-75',
              pingColor ?? 'bg-white',
            )}
          />
          <span
            className={classnames(
              'relative inline-flex h-1.5 w-1.5 rounded-full bg-white',
              pingColor ?? 'bg-white',
            )}
          />
        </span>
      );

    case 'section':
      return (
        <div
          className={classnames(
            'flex w-full flex-col content-center items-center justify-center gap-y-2',
            `${fullHeight && 'h-[100vh]'}`,
            className,
          )}
        >
          <SectionLoader
            className={classnames('h-16 w-16 animate-spin text-blue-500', sectionLoaderClassName)}
          />

          {loaderText && (
            <Typography
              className={classnames('text-lg font-medium text-slate-950', loaderTextClassName)}
            >
              {loaderText}
            </Typography>
          )}
        </div>
      );
  }
};
