import { SettingsService } from '@/shared/services/settings.service.ts';
import { useUIStore } from '@/shared/store/ui';

import { SettingsKeyEnum, SettingsNameEnum } from '@/shared/api/settings/settings.types.ts';

export function useNavbar() {
  const uiStore = useUIStore();

  const alertSettingsService = new SettingsService(
    uiStore.userSettings,
    SettingsNameEnum.UserSettings,
    SettingsKeyEnum.AlertSettings,
  );

  const currentSettings = alertSettingsService.getUserSettingValue();

  return { alertsSettings: currentSettings };
}
