/* eslint-disable no-await-in-loop */
import { baseQuery, signRequest } from '@/shared/api/utils';

import {
  AlertDataProps,
  AlertProps,
  MetricDataProps,
} from '@/shared/types/actions/actions.types.ts';

export namespace ActionsAPINamespace {
  const client = baseQuery('ACTIONS');

  export const getAlerts = async (
    accountResourceName: string,
    siteResourceName: string,
    userResourceName: string,
  ): Promise<AlertProps[]> => {
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/users/${userResourceName}/alerts`;
    const signedRequest = await signRequest('ACTIONS', url, 'GET');
    const response = await client.get<AlertDataProps>(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return response.data.results;
  };

  export const getArchivedAlerts = async (
    accountResourceName: string,
    siteResourceName: string,
    userResourceName: string,
    startTimestamp: number,
    endTimestamp: number,
    lastResourcePointer?: string,
  ): Promise<AlertDataProps> => {
    const url = lastResourcePointer
      ? `/accounts/${accountResourceName}/sites/${siteResourceName}/users/${userResourceName}/alerts?alertStates=archived&limit=1000&startTime=${startTimestamp}&endTime=${endTimestamp}&resourcePointer=${lastResourcePointer}`
      : `/accounts/${accountResourceName}/sites/${siteResourceName}/users/${userResourceName}/alerts?alertStates=archived&limit=1000&startTime=${startTimestamp}&endTime=${endTimestamp}`;

    const signedRequest = await signRequest('ACTIONS', url, 'GET');

    const response = await client.get<AlertDataProps>(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return response.data;
  };

  export const archiveAlert = async (
    accountResourceName: string,
    siteResourceName: string,
    userResourceName: string,
    alertResourceName: string,
  ): Promise<AlertDataProps> => {
    const body = { actionType: 'archive' };
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/users/${userResourceName}/alerts/${alertResourceName}/actions`;
    const signedRequest = await signRequest('ACTIONS', url, 'POST', body);

    const result = await client.post(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getAllMetrics = async (
    accountResourceName: string,
    siteResourceName: string,
  ): Promise<MetricDataProps> => {
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/metrics`;
    const signedRequest = await signRequest('ACTIONS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };
}
