import { FC, SyntheticEvent } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useIntl } from 'react-intl';
import { useMedia } from 'react-use';

import { addDays } from 'date-fns';

import { IconButton } from '@/shared/components/buttons/IconButton';
import { DatePickerCustomInput } from '@/shared/components/form/DatePicker/DatePickerCustomInput.tsx';
import { classnames } from '@/shared/utils/classnames';
import { getCurrentLocale } from '@/shared/utils/date-fns';

import Calendar from '@/assets/icons/calendar.svg?react';
import CLock from '@/assets/icons/clock.svg?react';
import { WithClassName } from '@/shared/types/global/common.types.ts';
import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';

type Props = ReactDatePickerProps &
  WithClassName & {
    icon?: 'calendar' | 'clock';
    withArrows?: boolean;
    showArrows?: boolean;
    withMaxDate?: boolean;
    customDateFormat?: boolean;
  };

export const DatePicker: FC<Props> = ({
  className,
  customDateFormat = false,
  icon = 'calendar',
  onChange,
  selected,
  showArrows = true,
  withArrows = false,
  withMaxDate = false,
  ...props
}) => {
  const { locale } = useIntl();
  const isMobile = useMedia('(max-width: 767px)');
  const isDeepMobile = useMedia('(max-width: 474px)');

  const renderIcon = (icon: 'calendar' | 'clock') => {
    if (icon === 'calendar') {
      return (
        <Calendar className="z-50 inline-flex !size-3.5 cursor-pointer items-center justify-center text-slate-500" />
      );
    }

    return (
      <CLock className="z-50 inline-flex !h-3.5 !w-3.5 cursor-pointer items-center justify-center text-slate-500" />
    );
  };

  const handleAddDay = (event: SyntheticEvent) => {
    if (selected) {
      const newDate = addDays(selected as Date, 1);

      const isNewDateBigger = newDate >= new Date();

      if (!withMaxDate) onChange?.(newDate, event);

      if (!isNewDateBigger) onChange?.(newDate, event);
    }
  };

  const handleSubtractDay = (event: SyntheticEvent) => {
    if (selected) {
      const newDate = addDays(selected, -1);
      onChange?.(newDate, event);
    }
  };

  return (
    <>
      {withArrows && (
        <IconButton
          className={classnames(
            'hidden size-[38px] shrink-0 rotate-90 items-center rounded-lg border-[1px] border-slate-200 bg-white text-slate-800 opacity-40 xl:flex',
            { hidden: isMobile },
          )}
          icon="arrow-sm"
          onClick={handleSubtractDay}
        />
      )}

      <ReactDatePicker
        className={classnames(
          'inline-flex w-48 cursor-pointer items-center !py-[8px] !pl-9 tracking-[0.07px] text-slate-950 caret-transparent',
          'defaultInputStyle inline-flex w-64 cursor-pointer items-center !py-[8px] !pl-9 text-base tracking-[0.07px] text-slate-950 md:text-sm',
          { 'text-sm': isMobile, 'w-full': isDeepMobile },
          className,
        )}
        icon={renderIcon(icon)}
        locale={getCurrentLocale(locale as LocaleEnum)}
        maxDate={withMaxDate ? new Date() : undefined}
        onChange={onChange}
        popperClassName="!z-50"
        portalId="date-picker"
        selected={selected}
        showIcon
        showPopperArrow={false}
        toggleCalendarOnIconClick
        withPortal={isMobile}
        {...props}
        customInput={<DatePickerCustomInput />}
        dateFormat={
          customDateFormat
            ? props.dateFormat
            : locale === LocaleEnum.JPN
            ? 'yyyy/MM/dd'
            : 'dd.MM.yyyy'
        }
        timeFormat={
          customDateFormat ? (locale === LocaleEnum.JPN ? 'HH:mm' : 'hh:mm a') : props.timeFormat
        }
      />

      {withArrows && (
        <IconButton
          className={classnames(
            'hidden size-[38px] shrink-0 rotate-[270deg] items-center rounded-lg border-[1px] border-slate-200 bg-white text-slate-800 opacity-40 xl:flex',
            { hidden: isMobile },
          )}
          icon="arrow-sm"
          onClick={handleAddDay}
        />
      )}
    </>
  );
};
