import { translate } from '@/i18n';
import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';
import { findTagValue } from '@/shared/utils/assets.ts';
import { MISSING_DATA } from '@/shared/utils/constants.ts';
import { convertStringToDate, formatTimestamp } from '@/shared/utils/date-fns.ts';

import {
  ActionCategoryEnum,
  ActionSeverityEnum,
  ActionSubCategoryEnum,
  ActionTitleEnum,
  AlertProps,
  MetricProps,
} from '@/shared/types/actions/actions.types.ts';

interface UseActionCardDataProps {
  action: AlertProps | MetricProps;
}

export const useActionCardData = ({ action }: UseActionCardDataProps) => {
  const assets = useGlobalStore(store => store.assets);
  const readers = useGlobalStore(store => store.readers);
  const defaultShift = useUIStore(state =>
    state.siteShifts.length > 0 ? state.siteShifts[0] : null,
  );
  const geofences = useGlobalStore(store => store.geofences);

  const itemIsAlert =
    Object.prototype.hasOwnProperty.call(action, 'alertName') ||
    Object.prototype.hasOwnProperty.call(action, 'alertResName');

  if (itemIsAlert) {
    const alert = action as AlertProps;
    const alertMetadata = parseMetadata(alert.metadata);

    const alertAsset = assets?.find(
      asset =>
        alert.assetResName === asset.assetResName || alert.deviceResName === findTagValue(asset),
    );

    const alertReader = readers?.find(reader => alert.deviceResName === reader.readerResName);

    const alertGeofence = geofences?.find(
      geofence => geofence.geofenceResName === alertMetadata.geofenceResName,
    );

    const alertI18nData = {
      assetName: alertReader?.readerName ?? alertAsset?.assetName,
      closingTime: defaultShift
        ? formatTimestamp(
            convertStringToDate(
              defaultShift?.shiftEndTime.shiftDate,
              defaultShift?.shiftEndTime.shiftTime,
            ).getTime(),
            'h:mm aa',
          )
        : undefined,
      timestamp: alert.timestamp,
      zoneName: alertGeofence?.geofenceName,
    };

    const jsxElements = createJsxElements(alert.alertName, alertI18nData);

    return {
      actionResName: alert.alertResName,
      assetResName: alert.assetResName,
      category: alert.alertCategory,
      descriptionNode: jsxElements.description,
      severity: alert.alertSeverity,
      subCategory: alert.attribute,
      subCategoryNode: createSubCategoryNode(alert.attribute),
      timestamp: formatTimestamp(alert.timestamp, 'MM/dd/yy h:mm aa'),
      titleNode: jsxElements.title,
    };
  } else {
    const metric = action as MetricProps;
    const jsxElements = createJsxElements(metric.metricName);

    return {
      actionResName: metric.alertResName,
      assetResName: undefined,
      category: ActionCategoryEnum.SYSTEM,
      descriptionNode: jsxElements.description,
      severity: ActionSeverityEnum.DEFAULT,
      subCategory: ActionSubCategoryEnum.TAG,
      subCategoryNode: createSubCategoryNode(ActionSubCategoryEnum.TAG),
      timestamp: formatTimestamp(metric.timestamp, 'MM/dd/yy h:mm aa'),
      titleNode: jsxElements.title,
    };
  }
};

function createJsxElements(
  title: string,
  args: {
    assetName?: string;
    timestamp?: number;
    closingTime?: string;
    zoneName?: string;
  } = {},
) {
  const i18nData = {
    assetName: args.assetName ?? MISSING_DATA,
    closingTime: args.closingTime ?? MISSING_DATA,
    firmName: MISSING_DATA,
    personName: MISSING_DATA,
    reservationDay: MISSING_DATA,
    timeRange: MISSING_DATA,
    timestamp: args.timestamp ? formatTimestamp(args.timestamp, 'h:mm aa') : MISSING_DATA,
    zoneName: args.zoneName ?? MISSING_DATA,
  };

  const actionTitle: ActionTitleEnum = title as ActionTitleEnum;

  switch (actionTitle) {
    case ActionTitleEnum.CRITICAL_BATTERY:
      return {
        description: translate('alerts.batteryCriticalDescription', i18nData),
        title: translate('alerts.batteryCritical'),
      };
    case ActionTitleEnum.DEAD_BATTERY:
      return {
        description: translate('alerts.batteryDeadDescription', i18nData),
        title: translate('alerts.batteryDead'),
      };
    case ActionTitleEnum.DEVICE_UNRESPONSIVE:
      return {
        description: translate('alerts.tagOfflineDescription', i18nData),
        title: translate('alerts.tagOffline'),
      };
    case ActionTitleEnum.EQUIPMENT_ENTERED_RED_ZONE:
      return {
        description: translate('alerts.equipmentEnteredRedZoneDescription', i18nData),
        title: translate('alerts.equipmentEnteredRedZone'),
      };
    case ActionTitleEnum.EQUIPMENT_IN_USE_BY_WRONG_SUB_FIRM:
      return {
        description: translate('alerts.equipmentInUseByWrongSubDescription', i18nData),
        title: translate('alerts.equipmentInUseByWrongSub'),
      };
    case ActionTitleEnum.EQUIPMENT_IN_USE_WITHOUT_RESERVATION:
      return {
        description: translate('alerts.equipmentInUseWithoutReservationDescription', i18nData),
        title: translate('alerts.equipmentInUseWithoutReservation'),
      };
    case ActionTitleEnum.EQUIPMENT_NOT_UTILIZED_ON_WORKDAY:
      return {
        description: translate('alerts.equipmentUnderUtilizedForWorkDayDescription', i18nData),
        title: translate('alerts.equipmentUnderUtilizedForWorkDay'),
      };
    case ActionTitleEnum.EQUIPMENT_REDZONE_ACTIVATED:
      return {
        description: translate('alerts.redZoneActivatedDescription', i18nData),
        title: translate('alerts.redZoneActivated'),
      };
    case ActionTitleEnum.EQUIPMENT_REDZONE_RELEASED:
      return {
        description: translate('alerts.redZoneReleasedDescription', i18nData),
        title: translate('alerts.redZoneReleased'),
      };
    case ActionTitleEnum.EQUIPMENT_RESERVATION_APPROVED:
      return {
        description: translate('alerts.equipmentReservationApprovedDescription', i18nData),
        title: translate('alerts.equipmentReservationApproved'),
      };
    case ActionTitleEnum.EQUIPMENT_RESERVATION_CANCELLED:
      return {
        description: translate('alerts.equipmentReservationCancelledDescription', i18nData),
        title: translate('alerts.equipmentReservationCancelled'),
      };
    case ActionTitleEnum.EQUIPMENT_RESERVATION_EDITED:
      return {
        description: translate('alerts.equipmentReservationEditedDescription', i18nData),
        title: translate('alerts.equipmentReservationEdited'),
      };
    case ActionTitleEnum.EQUIPMENT_RESERVATION_REQUEST:
      return {
        description: translate('alerts.newEquipmentReservationDescription', i18nData),
        title: translate('alerts.newEquipmentReservation'),
      };
    case ActionTitleEnum.EQUIPMENT_RESERVATION_SUB_REQUEST:
      return {
        description: translate('alerts.equipmentReservationRequestFromSubDescription', i18nData),
        title: translate('alerts.equipmentReservationRequestFromSub'),
      };
    case ActionTitleEnum.EQUIPMENT_RESERVATION_SUBCONTRACTOR_REQUEST_APPROVED:
      return {
        description: translate('alerts.equipmentReservationApprovedDescription', i18nData),
        title: translate('alerts.equipmentReservationApproved'),
      };
    case ActionTitleEnum.EQUIPMENT_RESERVATION_SUBCONTRACTOR_REQUEST_REJECTED:
      return {
        description: translate('alerts.equipmentReservationDeclinedDescription', i18nData),
        title: translate('alerts.equipmentReservationDeclined'),
      };
    case ActionTitleEnum.EQUIPMENT_RESERVATION_WORKER_REQUEST:
      return {
        description: translate('alerts.equipmentReservationRequestFromWorkerDescription', i18nData),
        title: translate('alerts.equipmentReservationRequestFromWorker'),
      };
    case ActionTitleEnum.EQUIPMENT_RESERVED_NOTINUSE:
      return {
        description: translate('alerts.equipmentUnderUtilizedForWorkDayDescription', i18nData),
        title: translate('alerts.equipmentUnderUtilizedForWorkDay'),
      };
    case ActionTitleEnum.EQUIPMENT_STILL_IN_USE_AFTER_RESERVATION_ENDS:
      return {
        description: translate('alerts.equipmentInUseAtEndOfReservationDescription', i18nData),
        title: translate('alerts.equipmentInUseAtEndOfReservation'),
      };
    case ActionTitleEnum.LOW_BATTERY:
      return {
        description: translate('alerts.batteryLowDescription', i18nData),
        title: translate('alerts.batteryLow'),
      };
    case ActionTitleEnum.NEW_RESERVATION:
      return {
        description: translate('alerts.newEquipmentReservationDescription', i18nData),
        title: translate('alerts.newEquipmentReservation'),
      };
    case ActionTitleEnum.NO_UPCOMING_EQUIPMENT_RESERVATION:
      return {
        description: translate('alerts.noUpcomingEquipmentReservationsDescription', i18nData),
        title: translate('alerts.noUpcomingEquipmentReservations'),
      };
    case ActionTitleEnum.READER_OFFLINE:
      return {
        description: translate('alerts.readerOfflineDescription', i18nData),
        title: translate('alerts.readerOffline'),
      };
    case ActionTitleEnum.READER_ONLINE:
      return {
        description: translate('alerts.newReaderOnlineDescription', i18nData),
        title: translate('alerts.newReaderOnline'),
      };
    case ActionTitleEnum.RESERVATION_APPROVED_WITH_CHANGES:
      return {
        description: translate(
          'alerts.equipmentReservationApprovedWithChangesDescription',
          i18nData,
        ),
        title: translate('alerts.equipmentReservationApprovedWithChanges'),
      };
    case ActionTitleEnum.RESERVATION_CONFLICT:
      return {
        description: translate('alerts.conflictingReservationsDescription', i18nData),
        title: translate('alerts.conflictingReservations'),
      };
    case ActionTitleEnum.RESERVATION_DRAFT_EXPIRED:
      return {
        description: translate('alerts.equipmentReservationDraftExpiredDescription', i18nData),
        title: translate('alerts.equipmentReservationDraftExpired'),
      };
    case ActionTitleEnum.RESERVATION_REQUEST_EXPIRED:
      return {
        description: translate('alerts.equipmentReservationExpiredDescription', i18nData),
        title: translate('alerts.equipmentReservationExpired'),
      };
    case ActionTitleEnum.TAG_LEFT_SITE:
      return {
        description: translate('alerts.tagLeftSiteDescription', i18nData),
        title: translate('alerts.tagLeftSite'),
      };
    case ActionTitleEnum.TAG_OFFLINE:
      return {
        description: translate('alerts.tagOfflineDescription', i18nData),
        title: translate('alerts.tagOffline'),
      };
    case ActionTitleEnum.TAG_ONLINE:
      return {
        description: translate('alerts.newTagOnlineDescription', i18nData),
        title: translate('alerts.newTagOnline'),
      };
    case ActionTitleEnum.WORKER_REDZONE_ENTERED:
      return {
        description: translate('alerts.workerEnteredRedZoneDescription', i18nData),
        title: translate('alerts.workerEnteredRedZone'),
      };
    case ActionTitleEnum.WORKERS_LEFT_SITE_FOR_DAY:
      return {
        description: translate('alerts.jobSiteEmptyEndOfDayDescription', i18nData),
        title: translate('alerts.jobSiteEmptyEndOfDay'),
      };
    case ActionTitleEnum.WORKERS_STILL_ON_SITE_AFTER_5PM:
      return {
        description: translate('alerts.someoneOnSiteEndOfDayDescription', i18nData),
        title: translate('alerts.someoneOnSiteEndOfDay'),
      };
    default:
      return {
        description: null,
        title: null,
      };
  }
}

function createSubCategoryNode(subCategory: ActionSubCategoryEnum) {
  switch (subCategory) {
    case ActionSubCategoryEnum.AFTER_HOURS:
      return translate('alert.subCategory.after_hours');
    case ActionSubCategoryEnum.BEHIND:
      return translate('alert.subCategory.behind');
    case ActionSubCategoryEnum.DANGER:
      return translate('alert.subCategory.danger');
    case ActionSubCategoryEnum.EMERGENCY:
      return translate('alert.subCategory.emergency');
    case ActionSubCategoryEnum.READER:
      return translate('alert.subCategory.reader');
    case ActionSubCategoryEnum.RED_ZONE:
      return translate('alert.subCategory.red_zone');
    case ActionSubCategoryEnum.RESERVATION:
      return translate('alert.subCategory.reservation');
    case ActionSubCategoryEnum.TAG:
      return translate('alert.subCategory.tag');
    case ActionSubCategoryEnum.UPDATE:
      return translate('alert.subCategory.update');
    case ActionSubCategoryEnum.UTILIZATION:
      return translate('alert.subCategory.utilization');
    case ActionSubCategoryEnum.WORK_ZONE:
      return translate('alert.subCategory.work_zone');
    case ActionSubCategoryEnum.ZONE:
      return translate('alert.subCategory.zone');
    default:
      return null;
  }
}

function parseMetadata(metadata?: string) {
  if (!metadata) return {};

  try {
    return JSON.parse(metadata);
  } catch (error) {
    console.warn('Failed to parse alert metadata', error);
    return {};
  }
}
