import { useEffect, useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { SystemViewNamespace } from '@/shared/services/system-view.service.ts';
import { useAlertsStore } from '@/shared/store/actions';
import { useGlobalStore } from '@/shared/store/global';
import { useSocketMapDataStore } from '@/shared/store/socket-map-data';
import { useUIStore } from '@/shared/store/ui';
import { SOCKET } from '@/shared/utils/constants.ts';
import { useConnectLocalSockets } from '@/shared/ws/local';

import { SocketConnectionStatusProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';

export const useWebSocketConnection = () => {
  const didUnmount = useRef(false);

  const site = useUIStore(state => state.site);
  const setSocketMapData = useSocketMapDataStore(state => state.setSocketMapData);
  const setConnectionStatus = useSocketMapDataStore(state => state.setConnectionStatus);
  const setWsAlertAvailable = useAlertsStore(store => store.setWsAlertAvailable);
  const addAlertData = useAlertsStore(store => store.addAlertData);
  const archiveAlert = useAlertsStore(store => store.moveAlertToArchive);

  const { socketUrl } = useConnectLocalSockets();
  const { user } = useFetchUserData();

  const { readyState, sendMessage } = useWebSocket(socketUrl, {
    onClose: () => {
      console.info(SOCKET.EVENT.CLIENT_CLOSED);
    },
    onError: error => {
      console.error(error);
    },
    onMessage: (event: { data: unknown }) => {
      if (!event.data) return;

      const parsedData = JSON.parse(event.data as string);
      if (parsedData?.message === SOCKET.EVENT.INTERNAL_SERVER_ERROR) return;

      if (parsedData?.notification && parsedData?.data) {
        const assets = useGlobalStore.getState().assets;
        const readers = useGlobalStore.getState().readers;
        const alerts = useAlertsStore.getState().alertsData;
        const assetResourceName = parsedData.data?.assetResName;
        const alertResourceName = parsedData.messageAttributes.alertResName.Value;
        const alertDeviceName = parsedData.messageAttributes.deviceResName.Value;

        if (alertResourceName && (assetResourceName || alertDeviceName)) {
          const existingAlert = alerts.find(alert => alert.alertResName === alertResourceName);
          const alertAsset = assets.find(asset => asset.assetResName === assetResourceName);
          const alertReader = readers.find(reader => reader.readerResName === alertDeviceName);
          const alertIsActive = parsedData.data.active?.toString() === 'true';

          if (!existingAlert && alertIsActive && (alertAsset || alertReader)) {
            addAlertData({
              accountResName: parsedData.messageAttributes.accountResName.Value,
              alertDescription: '',
              alertResName: alertResourceName,
              assetResName: parsedData.data.assetResName,
              deviceResName: parsedData.messageAttributes.deviceResName.Value,
              metadata: parsedData.data.metadata,
              siteResName: parsedData.messageAttributes.siteResName.Value,
              timestamp: new Date().getTime(),
              // actionUrls
              active: alertIsActive,
              alertCategory: parsedData.data.alertCategory,
              alertName: parsedData.data.alertName,
              alertSeverity: parsedData.data.alertSeverity,
              attribute: parsedData.data.attribute,
            });
            setWsAlertAvailable(true);
          } else archiveAlert(alertResourceName);
        }
      } else {
        const arrivalTimestampResult = { arrivalTimestamp: Date.now() };

        const transformedResult =
          SystemViewNamespace.LocationsNamespace.transformSystemView(parsedData);

        setSocketMapData({ ...arrivalTimestampResult, ...transformedResult });
      }
    },
    onOpen: () => {
      console.info(SOCKET.EVENT.CLIENT_OPENED);
    },
    onReconnectStop: numberAttempts => {
      console.info(SOCKET.EVENT.ON_RECONNECT_STOP, numberAttempts);
    },
    reconnectAttempts: SOCKET.OPTIONS.RECONNECT_ATTEMPTS,
    reconnectInterval: SOCKET.OPTIONS.RECONNECT_INTERVAL,
    retryOnError: true,
    shouldReconnect: () => {
      console.info(SOCKET.EVENT.SHOULD_RECONNECT);

      return !didUnmount.current;
    },
  });

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  const connectionStatus = {
    [ReadyState.CLOSED]: 'CLOSED',
    [ReadyState.CLOSING]: 'CLOSING',
    [ReadyState.CONNECTING]: 'CONNECTING',
    [ReadyState.OPEN]: 'OPEN',
    [ReadyState.UNINSTANTIATED]: 'UNINSTANTIATED',
  }[readyState] as SocketConnectionStatusProps;

  useEffect(() => {
    setConnectionStatus(connectionStatus);
  }, [connectionStatus, readyState, setConnectionStatus]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const data = JSON.stringify({
        accountResName: user?.accountResourceName,
        action: SOCKET.MESSAGE.ACTION,
        messageTypes: SOCKET.MESSAGE.MESSAGE_TYPES,
        siteResName: site,
      });

      if (!data) return;

      sendMessage(data);
    }
  }, [readyState, sendMessage, site, user?.accountResourceName]);
};
