import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToTop = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
  }, [search, pathname]);
};
