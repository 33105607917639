import { ForwardedRef, forwardRef } from 'react';

import { Loader } from '@/shared/components/ui/Loader';
import { ProseContent } from '@/shared/components/ui/ProseContent';
import { useTermsOfUseContent } from '@/shared/hooks/prismic/useTermsOfUseContent.ts';

export const Terms = forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => {
  const { document, field, state } = useTermsOfUseContent();

  if (state === 'loading') return <Loader appearance="section" />;

  return (
    <div className="relative flex flex-col items-center md:p-6" ref={ref} {...props}>
      {document && <ProseContent className="max-w-7xl text-start" field={field} />}
    </div>
  );
});

Terms.displayName = 'Terms';
