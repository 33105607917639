import { FC } from 'react';

import { translate } from '@/i18n';
import { SiteSelectItem } from '@/shared/components/ui/UserMenu/SelectItem';
import { useUIStore } from '@/shared/store/ui';
import { classnames } from '@/shared/utils/classnames.ts';

import ChevronRight from '@/assets/icons/chevron-right.svg?react';
import { WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithClassName & {
  onClick: () => void;
};

export const LanguageMenuItem: FC<Props> = ({ className, onClick }) => {
  const currentLanguage = useUIStore(state => state.currentLanguage);

  return (
    <SiteSelectItem
      aria-label="language-menu-action"
      className={classnames(
        'inline-flex w-full items-center justify-between rounded-md text-sm font-medium',
        className,
      )}
      onClick={onClick}
    >
      {translate('global.language')} ({currentLanguage})
      <ChevronRight />
    </SiteSelectItem>
  );
};
