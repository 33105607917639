import { FC, useState } from 'react';

import { Menu } from '@headlessui/react';

import { MobileMenu } from '@/shared/components/navigation/MobileMenu';
import { Intercom } from '@/shared/components/service/Intercom/Intercom.tsx';
import { AlertsNotification } from '@/shared/components/ui/UserMenu/AlertsNotification';
import { LanguageSelect } from '@/shared/components/ui/UserMenu/LanguageSelect';
import { MainSelect } from '@/shared/components/ui/UserMenu/MainSelect';
import { SystemSelect } from '@/shared/components/ui/UserMenu/SystemSelect';
import { User } from '@/shared/components/ui/UserMenu/User';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useClearGlobalStore } from '@/shared/hooks/global/useClearGlobalStore.ts';
import { useClearStateData } from '@/shared/hooks/global/useClearStateData.ts';
import { mixpanelService } from '@/shared/services/mixpanel.service.ts';
import { newRelicService } from '@/shared/services/newrelic.service';
import { notifyService } from '@/shared/services/notify.service';
import { useUIStore } from '@/shared/store/ui';
import { classnames } from '@/shared/utils/classnames';
import { ERROR_MESSAGES } from '@/shared/utils/error-messages';

import SelectIcon from '@/assets/icons/select.svg?react';
import { DropdownType } from '@/shared/components/ui/UserMenu/UserMenu.types';
import { WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithClassName & {
  role?: 'menu';
};

export const UserMenu: FC<Props> = ({ className, role }) => {
  const [menuType, setMenuType] = useState(DropdownType.DEFAULT);

  const { user } = useFetchUserData();
  const { initiateLogout } = useClearGlobalStore();
  const { setSidebarFilterValue } = useClearStateData();

  const systemView = useUIStore(state => state.systemView);

  const handleSignOut = () => {
    setSidebarFilterValue('');
    initiateLogout();
    notifyService.success(ERROR_MESSAGES.SIGN_OUT);
    mixpanelService.logLogout();
    mixpanelService.reset();
    newRelicService.logoutUser();
  };

  const handleRenderBody = () => {
    if (menuType === DropdownType.DEFAULT) {
      return (
        <MainSelect
          onClearStateData={() => setSidebarFilterValue('')}
          onSignOut={handleSignOut}
          setLanguageMenu={() => setMenuType(DropdownType.LANGUAGE)}
          setSystemMenu={() => setMenuType(DropdownType.SYSTEM)}
          systemView={systemView}
        />
      );
    }

    if (menuType === DropdownType.LANGUAGE) return <LanguageSelect setMenuType={setMenuType} />;

    if (menuType === DropdownType.SYSTEM) return <SystemSelect setMenuType={setMenuType} />;
  };

  return (
    <Menu
      as="div"
      className={classnames('ml-auto flex items-center gap-x-4 md:gap-x-2', className)}
    >
      <Intercom />

      <div className="relative hidden md:block">
        <Menu.Button
          aria-label="user-menu-action"
          className="inline-flex items-center gap-x-2 transition-opacity duration-300 hover:opacity-80 focus:opacity-80"
          role={role}
        >
          <User user={user} />

          <SelectIcon className="h-5 w-5 text-white" />
        </Menu.Button>

        <Menu.Items className="absolute right-0 z-50 mt-3 w-full min-w-56 origin-top-right rounded-xl bg-white shadow-lg xl:left-0 xl:right-3">
          {handleRenderBody()}
        </Menu.Items>
      </div>

      <AlertsNotification />

      <MobileMenu onClearStateData={() => setSidebarFilterValue('')} onSignOut={handleSignOut} />
    </Menu>
  );
};
