import { FC, HTMLAttributes } from 'react';

import { translate } from '@/i18n';
import { Button } from '@/shared/components/buttons/Button';
import { useActionCardActions } from '@/shared/components/ui/ActionCard/hooks/useActionCardActions.ts';
import { useActionCardData } from '@/shared/components/ui/ActionCard/hooks/useActionCardData.ts';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames.ts';

import SafetyAlertIcon from '@/assets/icons/alert.svg?react';
import ZoneAlertIcon from '@/assets/icons/location.svg?react';
import SystemAlertIcon from '@/assets/icons/settings-sm.svg?react';
import {
  ActionCategoryEnum,
  ActionSeverityEnum,
  AlertProps,
  MetricProps,
} from '@/shared/types/actions/actions.types.ts';

interface ActionCardProps {
  action: AlertProps | MetricProps;
  buttonPlacement?: 'col' | 'row';
}

export const ActionCard = ({ action, buttonPlacement }: ActionCardProps) => {
  const {
    actionResName,
    assetResName,
    category,
    descriptionNode,
    severity,
    subCategoryNode,
    timestamp,
    titleNode,
  } = useActionCardData({ action });

  const { archiveAction, archiving, openDetailsPage } = useActionCardActions({
    actionResName: actionResName ?? '',
    assetResName: assetResName,
  });

  const { bgClass, dateClass, fgClass, iconClass, textClass } =
    actionCardThemes[severity] ?? actionCardThemes[ActionSeverityEnum.DEFAULT];
  const ActionCardIcon = actionCardIcons[category] ?? actionCardIcons[ActionCategoryEnum.SAFETY];

  return (
    <div className={`relative flex gap-2.5 rounded-md p-3 ${bgClass} overflow-hidden`}>
      <div className={`absolute left-0 top-0 z-10 h-full w-[4px] ${fgClass}`} />

      <ActionCardIcon className={`shrink-0 ${iconClass}`} />

      <div
        className={classnames(
          'flex-1 gap-2 md:flex',
          buttonPlacement === 'col' ? 'flex-col' : 'items-center',
        )}
      >
        <div className="flex flex-col gap-1.5 md:flex-1">
          <div className="flex items-center gap-2">
            <Typography
              aria-details="tag"
              as="span"
              className={`inline-block rounded px-2 py-0.5 text-xs text-white ${fgClass}`}
            >
              {subCategoryNode}
            </Typography>

            <Typography aria-details="timestamp" className={`inline-block text-sm ${dateClass}`}>
              {timestamp}
            </Typography>
          </div>

          <Typography aria-details="title" className={`font-[600] ${textClass}`}>
            {titleNode}
          </Typography>

          <Typography aria-details="description" className={`${textClass}`}>
            {descriptionNode}
          </Typography>
        </div>

        {buttonPlacement && (
          <div aria-details="alert controls" className="mt-3 flex h-fit gap-3 md:mt-0">
            <Button
              className="!hover:bg-inherit h-[38px] !bg-slate-800 !text-white"
              onClick={openDetailsPage}
              type="button"
              variant="solid"
              withIcon
            >
              {translate('actions.viewOnMap')}
            </Button>

            <Button
              className="!hover:bg-inherit h-[38px] !bg-white !text-slate-800"
              isLoading={archiving}
              onClick={() => archiveAction()}
              type="button"
              variant="solid"
              withIcon
            >
              {translate('actions.hide')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

type ActionCardTheme = {
  bgClass: string;
  fgClass: string;
  textClass: string;
  dateClass: string;
  iconClass: string;
};

export const actionCardThemes: { [k in ActionSeverityEnum]: ActionCardTheme } = {
  [ActionSeverityEnum.DEFAULT]: {
    bgClass: 'bg-slate-100',
    dateClass: 'text-gray-950',
    fgClass: 'bg-slate-800',
    iconClass: 'text-slate-800',
    textClass: 'text-slate-600',
  },
  [ActionSeverityEnum.HIGH]: {
    bgClass: 'bg-red-50',
    dateClass: 'text-red-950',
    fgClass: 'bg-red-600',
    iconClass: 'text-red-600',
    textClass: 'text-red-600',
  },
  [ActionSeverityEnum.LOW]: {
    bgClass: 'bg-slate-100',
    dateClass: 'text-gray-950',
    fgClass: 'bg-slate-800',
    iconClass: 'text-slate-800',
    textClass: 'text-slate-600',
  },
  [ActionSeverityEnum.MEDIUM]: {
    bgClass: 'bg-yellow-50',
    dateClass: 'text-yellow-950',
    fgClass: 'bg-amber-600',
    iconClass: 'text-amber-600',
    textClass: 'text-yellow-600',
  },
};

export const actionCardIcons: { [k in ActionCategoryEnum]: FC<HTMLAttributes<SVGElement>> } = {
  [ActionCategoryEnum.EQUIPMENT]: SafetyAlertIcon,
  [ActionCategoryEnum.RESERVATION]: SafetyAlertIcon,
  [ActionCategoryEnum.SAFETY]: SafetyAlertIcon,
  [ActionCategoryEnum.SYSTEM]: SystemAlertIcon,
  [ActionCategoryEnum.ZONE]: ZoneAlertIcon,
};
